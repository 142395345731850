@import '../../../common/styles/colors.scss';
@import '../../../theme.scss';
@import '../../../colors.scss';

.usersHolder {
    position: absolute;
    z-index: 3;
    width: 206px;
}

.userEntry {
    padding: 6px;
    cursor: pointer;
}

.overdueToggle {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;

    span {
        font-size: 13px;
        color: var(--primary-color);
        font-weight: 600;
        margin-right: 10px;
        transform: translateY(-1px);
    }
}

.dateDropdown {
    position: absolute;
    top: calc(100% + 10px);
    right: 0px;
    background-color: white;
    box-shadow: 0px 0px 10px -4px var(--primary-color);
    border-radius: 30px;
    padding: 10px;
    width: 400px;
    flex-wrap: nowrap;
    opacity: 0;
    z-index: 2;
    animation: dropin 0.2s forwards;
    overflow: visible;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;

        @extend %ThemeLightBackground;
    }

    @keyframes dropin {
        0% {
            transform: translateY(-10px);
            opacity: 0;
        }

        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    .triangle {
        position: absolute;
        right: 30px;
        top: -22px;
        z-index: 1;
        color: var(--primary-color);
        font-size: 24px;
        pointer-events: none;
        text-shadow: 0px -5px 5px black;
        filter: contrast(0.1)brightness(2)grayscale(0.5);
    }

    [class*="calendarUI"] {
        box-shadow: none;

        &::before {
            display: none;
        }
    }

    [class*="selectedOptionText"] {
        text-align: left;
    }

}

.dueDateMainElementHolder {
    position: relative;

    .due {
        button {
            color: #0066CC;
            border-color: #0066CC;
            min-height: 30px;
            height: auto;
        }
    }

    .overdue {
        button {
            min-height: 30px;
            height: auto;
            color: $danger;
            border-color: $danger;
        }
    }

    .completed {
        button {
            min-height: 30px;
            height: auto;
            color: green;
            border-color: green;
            opacity: 0.3;
        }
    }
}

.workflowButton {
    background-color: rgba(var(--primary-color-rgb), 0.6);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    border: 0px;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: white;
    position: relative;
    font-weight: bold;
    cursor: pointer;
    overflow: hidden;
    z-index: 0;

    span {
        position: relative;
        font-weight: 600;
    }

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        fill: white;
        left: 0%;
        top: 0%;
        transform: scale(0.9);
        z-index: 1;
    }
}

.highlightedWorkflowButton {
    composes: workflowButton;
    box-shadow: 0 0 7px 0 var(--contrast-color);
}

.completionLevel {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    background-color: var(--primary-color);
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

.wave {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 50%;
    box-shadow: inset 0 0 50px transparent;
    opacity: 0.5;
}

.wave:before,
.wave:after {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
    background: #000;
}

.wave:before {
    border-radius: 45%;
    background: white;
    animation: animate 10s linear infinite;
}

.wave:after {
    border-radius: 40%;
    background: white;
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        transform: translate(-50%, -70%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -75%) rotate(360deg);
    }
}

.fileLink {
    color: $lighter-blue;
    text-decoration: underline;
}

.workflowNameElement {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .workflowTypeName {
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 4px;
    }

    .outdatedTag {
        padding: 2px 5px;
        border-radius: 10px;
        background-color: darken($danger, 5%);
        font-size: 10px;
        color: white;
        filter: grayscale(20%);
    }
}