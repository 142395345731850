@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.errorMessageHolder {
    @extend %CommonErrorMessageHolder;
}

.locationsHolder {
    display: flex;
    height: 100%;
    overflow: auto;
    padding: 10px 0;
}

.allProjects {
    position: sticky;
    left: 0;
    z-index: 2;
    margin-left: 20px;
    display: block;
    min-width: 330px;
    height: 100%;
    background-color: white;
    border-radius: 30px;
    
    @extend %CommonCard;
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.newVerticalHolder {
    min-width: 290px;
}


.hiddenFile {
    display: none;
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    margin-top: 0px;
    padding-top: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}


.importExportButtonHolder {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;

    button, label {
        margin-top: 10px;
        margin-bottom: 10px;

        &.highlighted {
            box-shadow: 0 0 10px 0 var(--contrast-color);
        }
    }
}