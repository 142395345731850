@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;

    border-bottom: 30px solid #fff;
    border-radius: 40px;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.comparison {
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);

    color: var(--primary-color);
    font-weight: bold;
    line-height: 26px;
    text-align: center;
    border: 2px solid var(--primary-color);
    border-radius: 50%;

    background-color: white;
    cursor: pointer;
}

.nudgeActive {
    box-shadow: 0 0 10px 0 var(--contrast-color);
}

.Tab {
    display: flex;
    border-radius: 40px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
    min-width: 100px;
    width: 150px;
    padding: 0 10px;
    height: 30px;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s, box-shadow 0.3s, font-size 0.3s;
    font-weight: 600;

    &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:nth-child(2) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.active {
        color: white;
        pointer-events: none;
        position: relative;
        filter: grayscale(50%);
        transition: all 0.3s;
        position: relative;
        background-color: var(--primary-color);
    }
}

.flowchartHeading {
    font-size: 12px;
    color: var(--primary-color);
    height: 63px;
    position: sticky;
    top: 0px;
    margin-left: 20px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.flowchartHeadingExpanded {
    composes: flowchartHeading;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    position: fixed;
    background-color: white;
    height: 63px;
    margin-left: 0;
    padding: 0 1vw;
}


.flowchartContainer {
    position: relative;
    width: 100%;
    height: calc(100% - 63px);
    border-radius: 30px;

    &::before {
        content: '';
        position: absolute;
        pointer-events: none;
        box-shadow: 0px 0px 10px -5px inset #595959;
        border-radius: 30px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        border-radius: 30px;
    }
}

.flowchartOuterWindow {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 0;
    z-index: 3;

    @extend %CommonInnerFocusSpace;

    .buttonHolder {
        position: relative;
        display: inline-flex;
        width: 100%;
        padding: 12px;

        .backButton {
            width: 25%;
        }

        .tabButtons {
            width: 50%;
            display: flex;
            justify-content: center;

            div {
                display: flex;
                position: relative;
                border-radius: 30px;

                &::before {
                    content: "";
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    position: absolute;
                    border-radius: 30px;
                    background-color: var(--primary-color);
                    pointer-events: none;
                    filter: contrast(0.3) brightness(2) grayscale(0.5);
                }
            }
        }

        .actionButtons {
            width: 25%;
            justify-content: flex-end;
            display: flex;

            button {
                margin-left: 10px;
            }
        }
    }

    .flowchartInnerWindow {
        position: relative;
        height: calc(100% - 53px);
        width: 100%;
        padding: 0 10px 10px;
    }
}

;

.expandedFlowChart {
    position: fixed;
    top: 62px;
    left: 0;
    height: calc(100% - 72px);
    z-index: 4;
    margin: 0 15px;
    width: calc(100% - 30px);
}