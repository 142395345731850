// DO NOT USE IT FOR FURTHER CHANGES AS IT IS BEING MIGRATED TO THE NEW COLORS FILE IN COMMON STYLES

$blue: var(--primary-color);
$blue-bottom-gradient: var(--primary-gradient);
$blue-top-gradient: var(--primary-reverse-gradient);
$porcelain: #ebeced;

$lighter-blue: #727f88;
$dark-blue: #1a2430;
$light-blue: #283a48;
$blue-grey: #717f8a;
$grey: #949599;
$dark-grey: #595959;
$light-grey: #e7e7e7;
$lighter-grey: #f9fafc;
$pink: var(--contrast-color);
$danger: #ea7a7a;

$pastel-green: #8ed15f;
$sunglo: #e5646d;
$dirty-yellow: #a79405;
$royal-purple: hsl(266, 82%, 62%);

$chart-blue: #aae0ed;
$chart-red: #f68ebd;
$chart-yellow: #fdd89f;
$warning: #ffce00;

$flowchart-green: #b6da76;
$flowchart-aqua: #14b1ab;
$flowchart-orange: #efaa4b;
$flowchart-indigo: #8891c8;
$flowchart-pink: #d289c0;
$flowchart-camo: #889e46;
$flowchart-red: #ca3434;

$flowchart-green-dark: #99b262;
$flowchart-aqua-dark: #13998f;
$flowchart-orange-dark: #cc8d42;
$flowchart-indigo-dark: #6a7199;
$flowchart-pink-dark: #b879ac;
$flowchart-camo-dark: #59871e;
$flowchart-red-dark: #832323;

// Chartist Colors
$graph-color-one: #42ace5;
$graph-color-two: #be6767;
$graph-color-three: #9647e1;
$graph-color-four: #f5d47d;
$graph-color-five: #ffc3ff;
$graph-color-six: #626262;
$graph-color-seven: #00533a;