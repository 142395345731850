@import '../../common/styles/colors.scss';

.searchHolder {
    width: 35%;
    position: relative;
    display: flex;
    align-items: center;

    &.blur {
        filter: blur(5px);
        pointer-events: none;
    }

    &.active {
        background-color: #fff;
        box-shadow: 0 0 10px rgba(var(--primary-color-rgb), .3);
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        width: 36%;

        .searchContainer {
            margin: 10px auto;
            width: 95%;

            .searchBox {
                width: calc(100% - 20px);
            }
        }

    }

    .searchContainer {
        width: 100%;
        margin: 10px 0px;

        .searchBoxHolder {
            display: flex;
            align-items: center;

            .searchBox {
                width: 100%;

                svg[class="search-icon"] {
                    position: absolute;
                    top: 12px;
                    left: 23px;
                    width: 15px;
                    height: auto;
                    fill: var(--contrast-color);
                }
            }

            button {
                margin-left: 10px;

                svg {
                    width: 20px;
                    height: 18px;
                }
            }

            .closeButton {
                svg {
                    width: 12px;
                    height: 14px;
                }
            }

        }

        .recentSearchAndFilterHolder {
            position: absolute;
            left: 0;
            top: 55px;
            right: 0;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            background-color: #fff;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 0 5px;
            width: 100%;

        }

        .recentSearches {
            width: 100%;

            .heading {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 95%;
                margin: 0 auto;
                margin-top: 10px;

                h4 {
                    margin: 0;
                    font-size: 12px;
                    color: var(--primary-color);
                    padding-left: 5px;
                }

                .trashIcon {
                    cursor: pointer;

                    svg {
                        width: 16px;
                        height: 16px;
                        fill: red;
                    }
                }
            }

            .searchTerm {
                font-size: 12px;
                color: rgba(var(--primary-color-rgb), .7);
                font-weight: 600;
                padding: 5px 0;
                width: 100%;
                padding-left: 15px;
            }
        }

        .recentFilters {
            @extend .recentSearches;

            .heading {
                h4 {
                    color: var(--contrast-color);
                }
            }
        }
    }

    .tagsHolder {
        padding: 15px;
        padding-bottom: 0;
        position: relative;

        .filterTag {
            display: inline-block;
            padding: 10px;
            padding-left: 12px;
            background-color: white;
            margin-right: 15px;
            border-radius: 30px;
            margin-bottom: 10px;
            max-width: 100%;
            border: 1px solid var(--contrast-color);
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 95%;

            &.active {
                background-color: var(--contrast-color);
                width: 95%;

                .filterTerm {
                    margin-right: 8px;
                    color: white !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            .filterTerm {
                max-width: 90%;
                font-weight: 600;
                font-size: 11px;
                padding: 0;
                color: var(--contrast-color);
            }

            .closeIcon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 25px;

                svg {
                    width: 20px;
                    height: 8px;
                }
            }

            .plusIcon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 25px;

                svg {
                    width: 20px;
                    height: 12px;
                    fill: var(--contrast-color);
                }
            }

            button {
                margin: 0;
                margin-left: 5px;
                background-color: white;
                border-width: 0px;
                display: inline-flex;
                vertical-align: middle;
                transform: scale(0.7);
                display: none;

                svg {
                    fill: rgba(var(--primary-color-rgb), 1);
                }
            }
        }

        .searchTag {
            @extend .filterTag;
            padding: 5px 8px;
            width: auto;
            border: 1px solid var(--primary-color);

            &.active {
                background-color: var(--primary-color);
                width: auto;

                .searchTerm {
                    color: white;
                    margin-right: 8px;
                }
            }

            .searchTerm {
                max-width: 100%;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 11px;
                padding: 0;
                margin-right: 4px;
                color: var(--primary-color);
            }

            .searchIcon {
                @extend .plusIcon;

                svg {
                    fill: var(--primary-color);
                }
            }
        }
    }
}