@import '../../common/styles/colors.scss';
@import '../../theme.scss';

.marketPage {
    position: relative;
    overflow: auto;
    height: 100vh;
    width: 100vw;
}

.logo {
    width: 200px;
    margin: 20px;
}