@import '../../../colors';
@import './pieces';

.normalPiece {
    opacity: 1;
}

.selectedPiece {
    opacity: 1;
}

.draggedPiece {
    opacity: 0.7;
}