@import '../../../colors';
@import '../../../theme';

.configurationHolder {
    display: flex;
    height: calc(100% - 90px);
    margin-top: 25px;
}

.defaultCardHolder {
    padding: 15px 20px 20px;
    margin-bottom: -15px;
}

.allRoles {
    border-radius: 30px;
    position: sticky;
    left: 0;
    z-index: 2;
    margin-left: 20px;
    display: block;
    min-width: 330px;
    height: 100%;
    padding: 0px 0px 10px;
    background-color: white;
    
    @extend %CommonCard;
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    margin-top: 0px;
    padding-top: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}