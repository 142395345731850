@import "../../theme.scss";
@import "../../common/styles/colors.scss";

.cardsList {
	margin: 0 10px 20px 10px;
	min-width: 290px;
	max-height: 100%;
	background-color: white;
	overflow: auto;
	border-radius: 30px;
	max-width: 330px;

	@extend %CommonCard;

	&.collapsible {
		width: 300px;
	}

	&.collapse {
		overflow: hidden;
		height: 70px;

		.content {
			display: none;
		}
	}

	header {
		position: sticky;
		top: 0;
		z-index: 1;
		padding: 0px 20px 0px 30px;
		font-size: 14px;
		color: nth($grey-shades, 4);
		font-weight: 600;
		letter-spacing: 0.5px;
		display: flex;
		height: 70px;
		align-items: center;
		background-color: white;
		justify-content: space-between;
		width: 100%;
		overflow: hidden;
		border-radius: 30px;



		&>* {
			position: relative;
		}

		&::before {
			content: "";
			position: absolute;
			left: 10px;
			top: 10px;
			height: 50px;
			width: calc(100% - 20px);
			border-radius: 30px;

			@extend %ThemeLightBackground;
		}

		.moreOptionsHolder {
			position: absolute;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 3;
			left: 0;
			top: 0;
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.3s, transform 0.5s;
			transform: translateX(100%);
			background-color: transparent;

			&.active {
				opacity: 1;
				pointer-events: all;
				transform: translateX(0%);
			}
		}

		.moreOptions {
			position: relative;
			width: calc(100% - 10px);
			height: calc(100% - 20px);
			overflow: auto;
			list-style: none;
			border-radius: 7px;
			display: flex;
			background-color: transparent;
			align-items: center;
			justify-content: flex-end;
			transition: transform 0.3s;
		}

		.actionButton {
			.deleteButton {
				svg {
					fill: $danger;
				}
			}
		}



		.actionButtonsColumn {
			display: flex;
			margin: 0 15px 0 10px;
			opacity: 1;
			cursor: pointer;
			transition: opacity 0.3s;
		}

		.cardButton {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 30px;
			font-size: 24.5px;
			font-weight: 600;
			width: 30px;
			height: 30px;
			text-align: center;
			color: nth($grey-shades, 1);
			border: 2px solid var(--contrast-color);
			background-color: var(--contrast-color);
			cursor: pointer;
			margin-left: 7px;
			transition: background-color 0.3s, color 0.3s, transform 0.3s;

			&.hide {
				opacity: 0;
				margin-right: 40px;
				transition: opacity 0.2s ease;
			}

			&.disable {
				pointer-events: none;
				filter: grayscale(100%);
				opacity: 30%;
			}

			&>span {
				transition: transform 0.3s;
			}

			&:hover {
				transform: scale(1.15);
			}

			svg {
				width: 15px;
				height: 15px;
				transition: transform 0.3s;
			}

			&.dropdown {
				margin-left: -15px;
				margin-right: 2px;
				border-color: nth($grey-shades, 3);
				transform: scale(0.5) translateY(0px);

				svg {
					fill: nth($grey-shades, 1);
					stroke: nth($grey-shades, 1);
				}
			}

			&.activeDropdown {
				svg {
					transform: rotate(180deg);
				}
			}

			&.cancel {
				border-color: white;
				color: white;

				svg {
					width: 12px;
					height: 12px;
					fill: white;
					stroke: white;
					stroke-width: 5px;
				}
			}
		}

		.heading {
			flex-grow: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-right: auto;
			max-width: calc(100% - 100px);
			cursor: pointer;
			font-weight: 700;
			position: relative;

			&.width {
				max-width: calc(100% - 150px);
			}

			&.disable_width {
				max-width: 100%;
			}

			span {
				display: block;
				font-size: 12px;
				font-weight: 500;
				color: nth($grey-shades, 3);
				margin-top: 3px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.counter {
			display: inline-block;
			margin-right: 5px;
			color: darken(nth($grey-shades, 3), 10%);
		}

		button {
			margin-left: 10px;
		}
	}

	.searchBox {
		display: block;
		width: calc(100% - 40px);
		border: 2px solid transparent;
		padding: 0px 15px;
		margin: 5px auto;
		border-radius: 20px;
		font-size: 14px;
		height: 40px;
		box-shadow: 0px 0px 3px 0px nth($grey-shades, 4);
		transition: box-shadow 0.3s;
		background-color: white;

		&::placeholder {
			color: nth($grey-shades, 3);
		}

		&:focus,
		&:hover {
			box-shadow: 0px 0px 3px 1px var(--contrast-color);
		}
	}

	footer {
		position: sticky;
		bottom: 0;
		z-index: 1;
		background-color: white;
		padding-top: 20px;
		box-shadow: 0px 0px 2px nth($grey-shades, 3);
		width: 90%;
		left: 5%;
		border-radius: 20px;
		margin: 0px auto 15px;

		.modifyFormHolder {
			padding: 0 20px 20px 20px;
		}
	}
}

.undoDelete {
	width: 100%;
	color: $danger;
	font-size: 13px;
	text-align: center;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% + 10px);
	background-color: lighten($danger, 20%);
	cursor: pointer;

	svg {
		width: 15px;
		height: auto;
		margin: 0 7px;
		fill: $danger;
	}
}

.addCard {
	background-color: transparentize(nth($grey-shades, 3), 0.85);
	opacity: 0;
	pointer-events: none;
	color: nth($grey-shades, 3);
	font-size: 12px;
	padding: 7px 20px;
	text-align: center;
	border-radius: 5px;
	cursor: pointer;
	height: 0px;
	overflow: hidden;
	padding: 0px;
}

li[data-react-beautiful-dnd-placeholder] {
	opacity: 0;
}

.editingCardsList {
	composes: cardsList;
	overflow: visible;
}

.addMessage {
	color: var(--contrast-color);
	font-size: 12px;
	text-align: center;
	cursor: pointer;
}

.content {
	padding: 10px 20px;

	&.verticalForm {
		padding: 20px;
	}
}

.structureList {
	margin: 10px auto;
	list-style: none;
	margin-top: 0;
}

.triToggleHolder {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	width: 60px;
	height: 50px;
	background-color: transparent;
	padding-bottom: 10px;
	transition: opacity 0.1s ease;

	&:hover {
		&.greyOutToggle {
			opacity: 1;
		}
	}

	&.greyOutToggle {
		opacity: 0.3;
	}
}

.legend {
	margin: 5px 3px;
	font-size: 9px;
	color: nth($grey-shades, 4);
}

.hideButton {
	opacity: 0;
	margin-right: 40px;
	transition: opacity 0.2s ease;
}