@import '../../../colors';
@import './pieces';

.constantPiece {
    display: inline-block;
    padding: 8px 13px;
    border-radius: 20px;

    background-color: $flowchart-orange;
    border: 1px solid $flowchart-orange-dark;

    @include cursor-for-pieces();
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.booleanConstantPiece {
    composes: constantPiece;
    position: relative;
    border-radius: unset;
    box-shadow: none;
    padding: 5px 0;
    margin: 0 20px;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));

    .text {
        margin: 0 auto;
    }
}

.booleanLeftPiece {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    left: -20px;
}

.booleanRightPiece {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    right: -20px;
}

.booleanPieceImage {
    display: block;
    width: 100%;
    height: 100%;
}

.noHighlight {
    position: relative;
    z-index: 1;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 1;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 1;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 1;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 1;
}