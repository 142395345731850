@import '../../../colors';
@import '../../../theme';

.memberName {
    font-size: 15px;
    color: var(--primary-color);
    filter: grayscale(50%);
    margin-bottom: 5px;
    margin-top: 30px;
    text-align: center;
}

.memberSubTitle {
    font-size: 13px;
    color: var(--primary-color);
    filter: grayscale(50%);
    margin-bottom: 10px;
    text-align: center;
}

.sectionHeading {
    font-size: 17px;
    color: var(--primary-color);
    filter: grayscale(50%);
    text-align: center;
    margin: 20px auto;

    span {
        font-weight: 600;
    }
}