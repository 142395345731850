@import '../../../common/styles/colors.scss';

.radio {
    border: 2px solid var(--primary-color);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: inherit;
        background-color: inherit;
    }

    &.active {
        border-color: white;

        &::before {
            background-color: white;
        }
    }
}