@import '../colors';
@import '../theme';

.flowchartWithPiceContainer {
    .flowchartContainer {
        flex-grow: 1;
        border-radius: 0 30px 30px 0;
    }
}

$grid-line-color: #ddd;

.flowchartContainer {
    background-color: white;
    position: relative;
    height: 100%;
    flex-grow: 1;
    overflow: auto;
    padding: 100px;
    padding-right: 0;
    background-image: linear-gradient(to right, $grid-line-color 1px, transparent 1px), linear-gradient(to bottom, $grid-line-color 1px, transparent 1px);
    background-size: 10px 10px;
    z-index: 4;

    h4 {
        position: absolute;
        color: #949599;
        top: 0;
        left: 50%;
        margin: 20px 0;
        font-size: 30px;
        letter-spacing: 0.5px;
        transform: translateX(-50%);
    }
}

.flowchartComparison {
    position: fixed;
    top: 2.5vh;
    left: 2.5vw;
    z-index: 5;
    padding: 10px;

    width: 95vw;
    height: 95vh;

    background-color: white;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.4), inset 0 0 0 8px white, inset 0 0 12px rgba(0, 0, 0, 0.8);
    border-radius: 10px;

    display: flex;
}

.close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
    z-index: 6;

    color: var(--primary-color);
    font-weight: bold;
    line-height: 26px;
    text-align: center;
    border: 2px solid var(--primary-color);
    border-radius: 15px;

    background-color: white;
    cursor: pointer;
}

.comparisonInfo {
    position: relative;
    min-width: 220px;
    max-width: 220px;
    z-index: 5;

    display: flex;
    flex-direction: column;
    overflow: visible;
    border: 1px solid nth($grey-shades, 3);

    h3 {
        text-align: center;
        margin: 15px auto;
        color: var(--primary-color);
        flex-grow: 0;
    }

    .scrollInstructions {
        text-align: center;
        margin: 10px auto;
        color: #949599;
        font-size: 13px;
        flex-grow: 0;
    }
}

.collapseFlowchart {
    position: absolute;
    top: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

    svg {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 4px;
        left: 4px;
        fill: var(--primary-color);
    }
}

.collapseLive {
    composes: collapseFlowchart;
    left: -10px;
    transform: rotate(90deg);
    cursor: pointer;
}

.collapsedLive {
    composes: collapseLive;
    transform: rotate(-90deg);
}

.collapsePublished {
    composes: collapseFlowchart;
    right: -10px;
    transform: rotate(-90deg);
    cursor: pointer;
}

.collapsedPublished {
    composes: collapsePublished;
    transform: rotate(90deg);
}

.marker {
    display: block;
    width: 20px;
    height: 10px;
    margin-right: 10px;
    border-radius: 7px;
    border: 1px solid black;
}

.additionMarker {
    composes: marker;
    background-color: $pastel-green;
    border-color: darken($pastel-green, 30%);
}

.updateMarker {
    composes: marker;
    background-color: $warning;
    border-color: darken($warning, 30%);
}

.deleteMarker {
    composes: marker;
    background-color: $sunglo;
    border-color: darken($sunglo, 30%);
}

.moveMarker {
    composes: marker;
    background-color: $royal-purple;
    border-color: darken($royal-purple, 30%);
}

.modifications {
    flex-grow: 1;
    overflow: auto;
}

.modificationHeading {
    display: flex;
    align-items: center;
    background-color: nth($grey-shades, 2);
    padding: 8px 10px;
    border: 1px solid nth($grey-shades, 3);
    border-left: none;
    border-right: none;
    cursor: pointer;

    .modificationHeadingText {
        font-size: 13px;
        color: nth($grey-shades, 4);
        flex-grow: 1;
    }

    .toggle {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        color: var(--contrast-color);
    }
}

.disabledModificationHeading {
    composes: modificationHeading;
    opacity: 0.6;
    cursor: not-allowed;

    .toggle {
        cursor: not-allowed;
    }
}

.modificationEntry {
    display: flex;
    padding: 8px 10px;
    border: 1px solid nth($grey-shades, 2);
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: nth($grey-shades, 1);
    }

    .modificationText {
        color: nth($grey-shades, 4);
        font-size: 12px;
        flex-grow: 1;
    }

    .closeIconContainer {
        position: relative;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        cursor: pointer;

        svg {
            position: absolute;
            top: 3px;
            left: 3px;
            width: 8px;
            height: 8px;
            fill: white;
        }
    }
}

.selectedModificationEntryForAdd {
    composes: modificationEntry;
    border-color: $pastel-green;

    .closeIconContainer {
        background-color: $pastel-green;
    }
}

.selectedModificationEntryForUpdate {
    composes: modificationEntry;
    border-color: $warning;

    .closeIconContainer {
        background-color: $warning;
    }
}

.selectedModificationEntryForDelete {
    composes: modificationEntry;
    border-color: $sunglo;

    .closeIconContainer {
        background-color: $sunglo;
    }
}

.selectedModificationEntryForMove {
    composes: modificationEntry;
    border-color: $royal-purple;

    .closeIconContainer {
        background-color: $royal-purple;
    }
}