@import './common/styles/colors.scss';

%ThemeLightBackground {
    background-color: var(--primary-color);
    pointer-events: none;
    filter: contrast(0.1)brightness(2)grayscale(0.5);
}

@mixin variableThemeBackground($contrast, $brightness, $greyscale) {
    background-color: var(--primary-color);
    pointer-events: none;
    filter: contrast($contrast)brightness($brightness)grayscale($greyscale);
}

.innerFocusContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
}

%CommonInnerFocusSpace {
    background-color: transparent;
    height: calc(100% - 77px);
    border-radius: 30px;
    border-top-right-radius: 0px;
    overflow: auto;
    width: 100%;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 30px;
        position: absolute;
        left: 0px;
        top: 0;
        @extend %ThemeLightBackground;
    }

    &>section {
        display: flex;
        height: calc(100% - 10px);
        width: 100%;
        overflow: auto;
    }
}


@keyframes moveDots {
    0% {
        transform: translateY(3px);
    }

    50% {
        transform: translateY(4px);
    }

    100% {
        transform: translateY(2px);
    }
}

%CommonFocusSpace {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    background-color: transparent;

    .pageHeader {
        position: relative;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
        width: 100%;
        height: 62px;
        margin: 0 auto;

        .loadedCount {
            position: absolute;
            top: 50%;
            left: calc(50% - 6.5vw);
            transform: translate(-50%, -50%);
            text-align: center;
            width: 17vw;

            label {
                color: var(--primary-color);
                font-size: 13px;
            }

            .progressBar {
                background-color: rgba(var(--primary-color-rgb), 0.1);
                height: 10px;
                border-radius: 30px;
                overflow: hidden;
                position: relative;
                margin-top: 5px;
                width: 100%;

                span {
                    background-color: var(--contrast-color);
                    width: 0%;
                    border-radius: 30px;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                }
            }

            .dot {
                width: 5px;
                height: 5px;
                background-color: var(--contrast-color);
                border-radius: 50%;
                display: inline-block;
                margin-right: 3px;
                vertical-align: middle;

                &:nth-child(1) {
                    animation: moveDots 0.8s infinite;
                }

                &:nth-child(2) {
                    animation: moveDots 0.8s infinite;
                    animation-delay: 0.5s;
                }

                &:nth-child(3) {
                    animation: moveDots 0.8s infinite;
                    animation-delay: 1s;
                }
            }
        }
    }

    .pageHeaderExpanded {
        position: fixed;
        z-index: 3;
        width: 100%;
        background-color: white;
        left: 0;
        top: 0;
        padding: 0 15px;
    }

    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        font-weight: 800;
        font-size: 18px;
        flex-grow: 1;
        justify-content: flex-start;
        text-transform: capitalize;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            fill: var(--primary-color);
            display: none;
        }
    }
}

%CommonConstrainedFocusSpace {
    @extend %CommonFocusSpace;

    width: calc(100vw - 300px);
}

%CommonErrorMessageHolder {
    position: fixed;
    bottom: 10px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    padding: 8px 10px;
    padding-right: 100px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    color: white;
    background-color: rgb(31, 31, 31);
    cursor: pointer;

    .highlight {
        color: var(--contrast-color);
    }

    .dismissal {
        width: 80px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 13px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $chart-blue;

        svg[class*="cancel-icon"] {
            fill: $chart-blue;
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }
    }
}

%CommonCard {
    border-radius: 20px;
    box-shadow: 0px 0px 0px nth($grey-shades, 3);
    border: 1px solid nth($grey-shades, 2);
    transition: box-shadow 0.2s, transform 0.2s;
    transform: translateY(0px);

    &:hover {
        // transform: translateY(-1px);
        box-shadow: 0px 0px 5px darken(nth($grey-shades, 2), 10%);
    }
}

%CommonCardsTree {
    width: calc(100% - 20px);
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    ul {
        list-style: none;
    }
}

%CommonCheckbox {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid nth($grey-shades, 3);

    &:checked {
        position: relative;
        background-color: $success;
        border: none;

        &::before {
            display: block;
            content: "";

            position: absolute;
            top: calc(50% - 1px);
            left: 3px;
            transform-origin: top left;
            transform: rotate(45deg);
            width: 6px;
            height: 1px;
            background-color: white;
        }

        &::after {
            display: block;
            content: "";

            position: absolute;
            top: 4px;
            right: 4px;
            transform-origin: top right;
            transform: rotate(-50deg);
            width: 11px;
            height: 1px;
            background-color: white;
        }
    }
}

.modifyFormCard {
    border-radius: 30px;
    background-color: white;
    position: fixed;
    width: 350px;
    left: calc(50vw - 175px);
    top: 120px;
    max-height: calc(100vh - 180px);
    z-index: 3;
    overflow: auto;
    animation: fadeIn forwards 0.3s linear;
    box-shadow: 0px 0px 100vw 100vw rgba(black, 0.5);

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(5vh);
        }

        100% {
            opacity: 1;
            transform: translateY(0vh);
        }
    }

    header {
        position: sticky;
        top: 0;
        z-index: 1;
        border-radius: 10px;
        padding: 21px 20px 10px;
        font-size: 16px;
        color: nth($grey-shades, 4);
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;
        height: 60px;
        align-items: center;
        background-color: white;
        justify-content: space-between;

        &>* {
            position: relative;
        }

        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 10px;
            height: 50px;
            width: calc(100% - 20px);
            border-radius: 30px;

            @extend %ThemeLightBackground;
        }


        .heading {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 700;
            padding: 0 10px;

            span {
                display: block;
                font-size: 13px;
                font-weight: 500;
                color: nth($grey-shades, 3);
                margin-top: 3px;
            }
        }

        .cancelCustomFieldButton {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            background-color: var(--contrast-color);
            letter-spacing: 0.5px;
            font-weight: 600;
            width: 30px;
            height: 30px;
            text-align: center;
            border: 2px solid var(--contrast-color);
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
                transition: all 0.2s ease-in;

                svg {
                    transform: scale(1.1);
                }
            }

            svg {
                width: 12px;
                height: 12px;
                fill: white;
                stroke: white;
                stroke-width: 5px;
                transition: transform 0.3s;
            }
        }
    }

    .container {
        padding: 15px;
    }
}

.newVerticalHolder {
    position: relative;
    min-width: 290px;
    margin: 3px 10px 20px 10px;
}

.newCustomFieldPrompt {
    background-color: white;
    color: nth($grey-shades, 3);
    font-size: 14px;
    border-radius: 30px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    color: var(--contrast-color);
    box-shadow: 0px 0px 3px var(--contrast-color);
    width: 300px;
}

.importExportButtonHolder {
    position: absolute;
    display: none;
    cursor: pointer;
    top: 35px;
    left: 180px;
    overflow: hidden;

    button,
    label {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: var(--contrast-color);
        letter-spacing: 0.5px;
        font-weight: 600;
        height: 30px;
        padding: 0 10px;
        border-radius: 7px;
        border: 2px solid var(--contrast-color);
        margin-left: 10px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: var(--contrast-color);
            color: white;

            svg {
                fill: white;
                stroke: white;
            }
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 7px;
            fill: var(--contrast-color);
            stroke: var(--contrast-color);
            transition: fill 0.3s, stroke 0.3s;
        }
    }
}

.commonModalHolder {
    position: relative;
    top: 10px;
    margin: 0 10px 0 auto;
    position: relative;
    width: 350px;
    z-index: 1;
    max-height: calc(100vh - 120px);
    overflow: visible;

    [class*="optionsList"] {
        overscroll-behavior: auto;
    }

    &.filterHolder {
        right: 5px;
        overflow: auto;
        padding: 10px 5px;
        width: calc(100% - 30px);

        .filterCloseButton {
            left: calc(100% - 210px);
        }

        .addOrModifyListCard {
            overflow: auto;
            max-height: none;

            &>header {
                h2 svg {
                    fill: var(--primary-color);
                    width: 12px;
                    height: 12px;
                }

                h2 {
                    font-size: 14px;
                }

                button {
                    text-decoration: underline;
                }
            }

            .container {
                max-height: 50vh;
            }
        }

        .container {
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }
        }

        .allInputsHolder {
            margin: 0;
            padding-left: 10px;
            width: calc(100% - 25px);

            .groupInputHeading {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 10px 0;

                h4 {
                    font-weight: bold;
                    color: var(--primary-color);
                    letter-spacing: 0.5px;
                    filter: grayscale(70%);
                    font-size: 14px;
                    padding-right: 10px;
                }

                &::after {
                    content: '';
                    flex-grow: 1;
                    height: 1px;
                    background-color: var(--primary-color);
                    filter: grayscale(70%);
                    opacity: 0.2;
                }
            }

            .inputSegment {
                margin: 0;
            }

            .groupInputs {
                display: grid;
                grid-template-columns: 50% 50%;
                grid-gap: 20px;
                margin-bottom: 20px;
            }
        }

        .actionButtons {
            button {
                margin-right: 6px;
            }
        }
    }

    &.syncModal {
        right: 154px !important;
    }

    &.csvModal {
        position: absolute;
        right: 62.5px;
        top: 10px;
        transform: translate(0px, 10px);
        z-index: 5;
        display: block;
        width: 350px;
        height: auto;

        .filterCloseButton {
            position: absolute;
            left: unset;
            right: 152.5px;
        }

        .addOrModifyListCard {
            margin-top: 50px;
            padding-bottom: 0px;
            overflow: auto;

            h2 svg {
                fill: var(--primary-color);
                width: 12px;
                height: 12px;
            }

            h2 {
                font-size: 14px;
            }
        }

        .buttonsHolder {
            display: block;
            padding: 15px 15px 0;

            .actionButtons {
                display: grid;
                grid-template-columns: 0.7fr 1fr;
                grid-gap: 15px;
            }

            button {
                width: 100%;
                margin-bottom: 10px;
            }

            a button {
                text-decoration: underline;

                span {
                    margin-left: 0;
                }

                svg {
                    width: 20px;
                    height: 15px;
                    padding-right: 5px;
                }
            }
        }
    }

    .tagsHolder {
        padding: 15px;
        padding-bottom: 0;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: -100%;
            height: 100%;
            width: 100%;
            pointer-events: none;
            box-shadow: 0px -5px 8px -7px inset lighten(nth($grey-shades, 3), 10%);
            border-radius: 30px;
        }

        .filterTag {
            display: inline-block;
            padding: 7px 10px;
            font-size: 11px;
            background-color: rgba(var(--primary-color-rgb), 0.15);
            color: rgba(var(--primary-color-rgb), 0.8);
            margin-right: 15px;
            border-radius: 500px;
            margin-bottom: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            font-weight: 600;

            button {
                margin: 0;
                margin-left: 5px;
                background-color: white;
                border-width: 0px;
                display: inline-flex;
                vertical-align: middle;
                transform: scale(0.7);
                display: none;

                svg {
                    fill: rgba(var(--primary-color-rgb), 1);
                }
            }
        }
    }

    .smartAndAdvancedFilterContainer {
        display: grid;
        width: 100%;
        grid-template-columns: 30% 1fr;
        padding: 10px 20px 0px;
        grid-gap: 10px;

        .container {
            padding: 0;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .smartFilter {
        padding: 15px;
        padding-bottom: 0;
        position: relative;
        padding: 15px;
        border-radius: 20px;
        overflow: auto;
        background-color: #edf1f4;

        h4 {
            font-weight: bold;
            color: var(--primary-color);
            letter-spacing: 0.5px;
            filter: grayscale(70%);
            text-align: center;
            font-size: 14px;
            margin-bottom: 15px;

            svg {
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                height: 20px;
                stroke: var(--primary-color);
            }
        }

        .filterTag {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            padding-left: 12px;
            background-color: white;
            border-radius: 500px;
            margin-bottom: 10px;
            max-width: 100%;
            border: 1px solid var(--contrast-color);
            cursor: pointer;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                background-color: var(--contrast-color);
                width: 100%;

                .filterTerm {
                    margin-right: 8px;
                    color: white !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            .filterTerm {
                max-width: 90%;
                font-weight: 600;
                font-size: 11px;
                padding: 0;
                color: var(--contrast-color);
            }

            .closeIcon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 25px;

                svg {
                    width: 20px;
                    height: 8px;
                }
            }

            .plusIcon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 25px;

                svg {
                    width: 20px;
                    height: 12px;
                    fill: var(--contrast-color);
                }
            }
        }
    }

    .filterCloseButton {
        position: relative;
        display: inline-block;
        left: calc(50% - 15px);
        background-color: white;
        box-shadow: 0px -5px 10px -6px nth($grey-shades, 3);
        text-align: center;
        padding: 10px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: 0;

        &::before {
            content: '';
            position: absolute;
            left: -5px;
            top: calc(100% - 25px);
            background-color: transparent;
            box-shadow: 10px 0px 0px inset white;
            height: 30px;
            width: 15px;
            border-top-left-radius: 25px;
            transform: rotate(180deg);
        }

        &::after {
            content: '';
            position: absolute;
            right: -5px;
            top: calc(100% - 25px);
            background-color: transparent;
            box-shadow: 10px 0px 0px inset white;
            height: 30px;
            width: 15px;
            border-bottom-left-radius: 25px;
            transform: rotate(0deg);
        }

        button {
            margin: 0;
            display: inline-block;
            z-index: 1;

            svg {
                transform: translateY(1px);
            }
        }
    }
}

.addOrModifyListCard {
    width: 100%;
    max-height: 100%;
    height: auto;
    border-radius: 30px;
    padding-bottom: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px -5px nth($grey-shades, 3);

    .errorMessage {
        margin: 0px 0 10px 0;
        color: $danger;
        font-size: 18px;
        text-align: center;
        display: block;
        color: $danger;
        font-weight: 500;
    }

    .infoMessage {
        margin: 0px 0 10px 0;
        color: $danger;
        font-size: 18px;
        text-align: center;
        display: block;
        color: var(--primary-color);
        font-weight: 500;
    }

    .existingFlow {
        margin: 0px 0 10px 0;
        color: var(--contrast-color);
        font-size: 18px;
        text-align: center;
        display: block;
        color: $danger;
        font-weight: 500;
        cursor: pointer;
    }

    &>header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px 0;
        padding: 20px 20px 10px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            height: 100%;
            width: 100%;
            pointer-events: none;
            box-shadow: 0px 5px 8px -7px inset lighten(nth($grey-shades, 3), 25%);
            opacity: 0.6;
            border-radius: 30px;
        }

        h2 {
            font-size: 14px;
            font-weight: 500;
            color: var(--primary-color);
            letter-spacing: 0.5px;
            margin-left: 10px;
            filter: grayscale(70%);
        }

        button {
            margin: 0;
            height: 25px;
            filter: grayscale(50%);

            svg {
                width: 10px;
                height: 10px;
            }
        }
    }

    .container {
        background-color: white;
        max-height: calc(100vh - 260px);
        overflow: auto;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 0px 15px 10px 15px;
    }


    .allInputsHolder {
        margin: 10px 0;

        .inputSegment {
            margin: 10px 0;

            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .buttonsHolder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: -100%;
            height: 100%;
            width: 100%;
            pointer-events: none;
            box-shadow: 0px -5px 8px -7px inset lighten(nth($grey-shades, 3), 10%);
            border-radius: 30px;
        }

        button {
            margin: 0 auto;
            height: 35px;
            font-size: 13px;

            svg {
                width: 13px;
                height: 13px;
            }
        }
    }
}

.viewOnlyListCard {
    composes: addOrModifyListCard;

    .buttonsHolder {
        pointer-events: none;
    }

    .allInputsHolder {
        pointer-events: none;

        [class*="multiSelect"] {
            [class*="inputHolder"] {
                pointer-events: all;
            }

            [class*="optionsList"] {
                pointer-events: all;

                [class*="option"] {
                    pointer-events: none;
                }
            }
        }
    }

    header {
        pointer-events: all;
    }
}