// Global styles
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&display=swap');
@import './colors.scss';

* {
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline-color: var(--contrast-color);
    outline-width: 5px;
    font-weight: 500;

    // Custom scroll bar
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: nth($grey-shades, 2);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--primary-color-rgb), 0.3);
        border-radius: 20px;
        border: 3px solid transparent;
        background-clip: padding-box;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(var(--primary-color-rgb), 0.5);
        }
    }
}

body {
    overflow: hidden;
}

header,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

a {
    text-decoration: none;
    color: inherit;
}

.blur {
    filter: blur(5px);
    pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.my-tippy {
    span {
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 0.2px;
    }
}

div[class*="normalFlowchartHolder"] {

    .children-clickable {
        // The important tag here is needed since otherwise the below rule override it 
        // - e.g. a container element which is an immediate descendant of another container should still not have pointer events
        pointer-events: none;

        &>* {
            pointer-events: all;
        }

        &>.children-clickable {
            pointer-events: none;
        }

        // The below line cannot be used to go around the override since this class is used in module composition of other classes. 
        // & > .childrenClickable {
        //     pointer-events: none;
        // }
    }
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body>iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}