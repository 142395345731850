@import '../../colors';
@import '../../theme';

.pageHeader {
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    width: 100%;
    height: 62px;
    margin: 0 auto;

    .loadedCount {
        position: absolute;
        top: 50%;
        left: calc(50% - 6.5vw);
        transform: translate(-50%, -50%);
        text-align: center;
        width: 17vw;

        label {
            color: var(--primary-color);
            font-size: 13px;
        }

        .progressBar {
            background-color: rgba(var(--primary-color-rgb), 0.1);
            height: 10px;
            border-radius: 30px;
            overflow: hidden;
            position: relative;
            margin-top: 5px;
            width: 100%;

            span {
                background-color: var(--contrast-color);
                width: 0%;
                border-radius: 30px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
            }
        }

        .dot {
            width: 5px;
            height: 5px;
            background-color: var(--contrast-color);
            border-radius: 50%;
            display: inline-block;
            margin-right: 3px;
            vertical-align: middle;

            &:nth-child(1) {
                animation: moveDots 0.8s infinite;
            }

            &:nth-child(2) {
                animation: moveDots 0.8s infinite;
                animation-delay: 0.5s;
            }

            &:nth-child(3) {
                animation: moveDots 0.8s infinite;
                animation-delay: 1s;
            }
        }
    }
}

.heading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-weight: 800;
    font-size: 18px;
    flex-grow: 1;
    justify-content: flex-start;
    text-transform: capitalize;

    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        fill: var(--primary-color);
        display: none;
    }
}
