@import '../../common/styles/colors.scss';
@import '../../theme';

.booleanInputHolder {
    position: relative;
    border: none;
    border-radius: 30px;
    border: 1px solid rgba(var(--primary-color-rgb), 0.3);
    background-color: white;
    display: flex;
    align-items: center;
    height: 35px;
    transition: border-color 0.3s;
    padding: 0 10px;
    cursor: pointer;

    input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        cursor: pointer;
    }

    label {
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: darken(nth($grey-shades, 3), 15%);
        width: auto;
        margin-right: 5px;
        margin-left: 5px;
        pointer-events: none;
    }
}

.multiSelectToggleHolder {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 18px;
    border-radius: 5px;
    width: 100%;
    position: relative;

    input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        cursor: pointer;
    }

    label {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: rgba(var(--primary-color-rgb), 0.7);
        font-weight: 600;
        width: calc(100% - 70px);
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
    }
}

.selectedOption {
    background-color: rgba(var(--primary-color-rgb), 0.2);
    color: white;
}