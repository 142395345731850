@import '../../../colors';
@import '../../../theme';

.tableArea {
    width: calc(100% - 350px);
    min-height: calc(100vh - 150px);
}

.expandedTableArea {
    composes: tableArea;
    width: 100%;
}

.noDataMessage {
    background-color: #f9fafc;
    text-align: left;
    width: 100%;
    text-align: center;
    padding-top: 30px;
    color: $grey;
    font-weight: 400;
    font-size: 20px;
    position: relative;

    span {
        position: relative;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }
}

.workflowsSection {
     display: flex;
    height: calc(100% - 40px);
    width: 100%;
    overflow: auto;
    margin-top: 20px;
}

.fixedButton {
    position: fixed;

    display: block;
    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 5px 10px;
    min-width: 100px;
    flex-grow: 1;
    text-align: center;
    color: var(--contrast-color);
    border: 1px solid var(--contrast-color);
    cursor: pointer;

    svg {
        width: 15px;
        height: 15px;
        fill: $pink;
        stroke: $pink;
        transform: translateY(2px);
        margin-right: 3px;
    }
}

.importTemplate {
    composes: fixedButton;
    top: 20px;
    right: 130px;
}

.hiddenFile {
    display: none;
}

.importButton {
    composes: fixedButton;
    top: 90px;
    right: 20px;
}