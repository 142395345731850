@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
    overflow-x: auto;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.innerFocus {
    @extend %CommonInnerFocusSpace;
}

.userNameElement {
    .betaTag {
        display: inline-block;
        vertical-align: middle;
        padding: 2px 5px;
        border-radius: 10px;
        background-color: var(--contrast-color);
        font-size: 10px;
        color: white;
        filter: grayscale(20%);

        &.onlineTag {
            background-color: #75bb75;
        }
    }
}

.fileLink {
    color: $lighter-blue;
    text-decoration: underline;
}