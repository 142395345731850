@import '../../../../colors';
@import '../pieces';

.unaryOperator,
.binaryOperator {
    display: inline-flex;
    align-items: center;
    background-color: $flowchart-orange;
    padding: 5px 15px;
    border-radius: 500px;
    color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);

    @include cursor-for-pieces();
}

.booleanUnaryOperator,
.booleanBinaryOperator {
    composes: binaryOperator;
    position: relative;
    border-radius: unset;
    box-shadow: none;
    padding: 5px 0;
    margin: 0 20px;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
}

.booleanLeftPiece {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    left: -20px;
}

.booleanRightPiece {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    right: -20px;
}

.booleanPieceImage {
    display: block;
    width: 100%;
    height: 100%;
}

.booleanIndicator {
    position: relative;
    width: 30px;
    height: 25px;
    margin: 0 10px;
    background-color: darken($flowchart-orange-dark, 5%);

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0px;
        height: 0px;
        border-width: 12.5px 10px;
        border-color: transparent;
        border-style: solid;
    }

    &::before {
        left: -20px;
        border-right-color: darken($flowchart-orange-dark, 5%);
    }

    &::after {
        right: -20px;
        border-left-color: darken($flowchart-orange-dark, 5%);
    }
}

.booleanIndicatorHovering {
    composes: booleanIndicator;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

    &::before {
        border-right-color: rgba(0, 0, 0, 0.4);
    }

    &::after {
        border-left-color: rgba(0, 0, 0, 0.4);
    }
}

.operatorSymbol {
    min-width: 20px;
    margin: 0 5px;
    text-align: center;
    white-space: nowrap;
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.noHighlight {
    position: relative;
    z-index: 1;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 1;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 1;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 1;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 1;
}