@import '../../common/styles/colors.scss';

.SignatureWidget {
    background-color: white;
    border-radius: 20px;
    border: 1px solid nth($grey-shades, 2);
    padding: 15px 10px;
}

.SignatureCanvas {
    background-color: white;
    border-radius: 20px;
    border: 1px solid nth($grey-shades, 2);
}

.actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}