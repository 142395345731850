@import '../../colors';
@import '../../theme';

.showDataContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    margin: 0;
    background-color: white;
    position: relative;
    height: 100%;
    border-radius: 30px;

    @extend %CommonCard;

    .dataContainer {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
        z-index: 0;
    }
}

.showHeader {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: flex-end;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    padding: 0px 10px 0px 20px;
    margin: 10px 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @extend %ThemeLightBackground;
    }
    
    .showHeading {
        flex-grow: 1;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: darken($dark-grey, 10%);
        margin-right: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: opacity 0.5s;

        &.hide {
            opacity: 0;
        }
        
        .heading {            
            width: calc(100% - 30px);

            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                font-weight: 700;
            }

            span {
                display: block;
                font-size: 10px;
            }
        }        

        svg {
            width: 20px;
            height: 20px;
            fill: $blue;
            margin-right: 10px;
        }
    }

    .actionButtonsContainer {
        position: relative;
        transition: opacity 0.5s;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        &.hide {
            opacity: 0;
        }

        button {
            margin-left: 15px;
        }
    }

    .moreOptionsHolder {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s, transform 0.5s;
        transform: translateX(100%);

        &.active {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0%);
        }
    }

    .moreOptions {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        list-style: none;          
        border-radius: 7px;
        display: flex;
        background-color: transparent;
        align-items: center;
        justify-content: flex-end;
        transition: transform 0.3s;
        
        button {
            margin-right: 5px;
        }
    }
}
.submit {
    display: block;
    width: 160px;
    margin: 30px auto;

    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 11px 0;

    text-align: center;
    color: white;
    background-color: var(--contrast-color);
    cursor: pointer;
}

.downloadButtonsHolder {
    padding-top: 15px;
    text-align: right;
    margin-top: 20px;
    border-top: 2px solid $light-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
        background-color: transparent;
        color: var(--contrast-color);
        border: 2px solid var(--contrast-color);
        font-size: 14px;
        padding: 0 7px;
        height: 40px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background-color: $pink;
            color: white;

            svg {
                fill: white;
                stroke: white;
            }
        }

        &.share {
            background-color: $light-grey;
            border: 0px;
            color: $blue;
            padding: 0 15px;

            &:hover {
                background-color: $blue;
                color: white;

                svg {
                    fill: white;
                    stroke: white;
                }
            }

            svg {
                fill: $blue;
                stroke: $blue;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $pink;
            stroke: $pink;

            transition: stroke 0.3s, fill 0.3s;
        }

        span {
            margin-left: 7px;
        }
    }
}

.oneDownloadButtonHolder {
    composes: downloadButtonsHolder;
    justify-content: flex-end;
}

.showModifyButton {
    padding: 8px 15px;
    border-radius: 800px;
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    svg {
        margin-top: 6px;
        margin-right: 5px;
        width: auto;
        height: 12px;
        fill: white;
        stroke: white;
    }
}

.updateButton {
    composes: showModifyButton;
    background-color: var(--contrast-color);
    color: white;
}

.deleteButton {
    composes: showModifyButton;
    background-color: $grey;
    color: white;
}

.dataContent {
    width: 100%;
    height: 100%;
    padding: 10px 15px;
}

.noDataContainer {
    width: 100%;
    padding: 20px;
    text-align: center;
    color: silver;
    font-size: 22px;
    font-weight: bold;
}