@import '../../../common/styles/colors.scss';
@import '../../../theme.scss';

.features {
    list-style: none;

    li {
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 1.5;
        color: var(--primary-color);
        opacity: 0.7;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:last-child {
            margin: 0;
        }

        &::before {
            content:"·";
            font-size: 10px;
            position: relative;
            transform: scale(5);
            line-height: 0;
            margin-right: 20px;
        }
    }
}

.productCard {
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    background-color: lighten(nth($grey-shades, 2), 4%);

    @extend %CommonCard;



    .imageCarouselHolder {
        width: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        
        div[class*="react-multi-carousel-list"] {
            overflow-y: visible;

            button {
                background-color: transparent;

                &[class*="arrow--right"] {
                    margin-right: -10px;
                    &::before {
                        color: var(--primary-color);
                    }
                }
                &[class*="arrow--left"] {
                    margin-left: -10px;
                    &::before {
                        color: var(--primary-color);
                    }
                }
            }
        }
        
        &::before {
            z-index: 1;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            width: 100%;
            height: 100%;
            filter: grayscale(50%);
            box-shadow: 0px 0px 10px -3px inset var(--primary-color);
            border-radius: 20px;
        }

        .type {
            position: absolute;
            right: 20px;
            top: 20px;
            background-color: var(--primary-color);
            border-radius: 5px;
            padding: 7px 13px;
            color: white;
            font-size: 12px;
            filter: grayscale(50%);
            opacity: 0.8;
            z-index: 1;
            
            svg {
                fill: white;
                vertical-align: middle;
                width: 15px;
                height: 15px;
            }
        }        
    }

    .snapshotPreview {
        display: block;
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: center center;
    }

    .info {
        filter: grayscale(50%);
        padding: 20px;        
    }

    h4 {
        font-size: 16px;
        color: var(--primary-color);
        font-weight: 700;
        margin-bottom: 5px;
    }

    h5 {
        font-size: 13px;
        color: var(--primary-color);
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        font-size: 13px;
        line-height: 1.5;
        color: var(--primary-color);
        opacity: 0.7;
        margin: 12px 0;
    }

    .buttonsHolder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 15px 15px;

        button {
            margin: 0;
        }
    }
}