@import '../../../../common/styles/colors.scss';
@import '../../../../theme';

.timingChartHolder {
    position: relative;    
}

.tableData {
    display: flex;
    width: 100%;
    background-color: white;
    align-items: stretch;

    .date, .total {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 70px;
        font-size: 12px;
        color: nth($grey-shades, 4);
        padding: 5px;
        border-bottom: 1px solid nth($grey-shades, 2);
    }
}

.chart {
    position: relative;
    flex-grow: 1;
    border-left: 1px solid nth($grey-shades, 2);
    border-right: 1px solid nth($grey-shades, 2);
    border-bottom: 1px solid nth($grey-shades, 2);
    height: 50px;

    .windowContainer {
        position: relative;
        height: 20px;
        margin: 15px 5px;
        background-color: rgba(var(--primary-color-rgb), 0.1);
        border-radius: 30px;
    }

    .timestampWindow {
        position: absolute;
        height: 100%;
        background-color: var(--primary-color);
        border-radius: 10px;
    }
}