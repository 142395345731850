@import '../../common/styles/colors.scss';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;

    &.noScroll {
        overflow: hidden;
    }

    .pageHeader {
        position: relative;
    }
}

.onlineMessage {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 30px;
        color: nth($grey-shades, 3);
    }
}

.loadedCount {
    position: absolute;
    top: 50%;
    left: calc(50% - 6.5vw);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 17vw;

    label {
        color: var(--primary-color);
        font-size: 13px;
    }

    .progressBar {
        background-color: rgba(var(--primary-color-rgb), 0.1);
        height: 10px;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
        margin-top: 5px;
        width: 100%;

        span {
            background-color: var(--contrast-color);
            width: 0%;
            border-radius: 30px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
        }
    }
}

.pageButtons {
    text-align: right;
    margin: 0px 20px;
    padding: 10px 0;

    button {
        margin: 0;
        margin-left: 15px;
    }

    .reloadButtonHolder {
        position: fixed;
        right: 40px;
        bottom: 45px;
        background-color: white;
        border-radius: 50%;
        z-index: 10;
        box-shadow: 0px 0px 5px nth($grey-shades, 4);
        transition: transform 0.3s;

        &.moveRight {
            transform: translateX(-240px);
        }

        button {
            margin: 0;
        }
    }
}

.widgetsDataContainer {
    height: calc(100% - 40px);
    width: 100%;
    position: relative;
    overflow: auto;
    padding-bottom: 40px;

    .actionButtons {
        position: absolute;
        top: 30px;
        left: 20px;
        width: calc(100% - 48px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: none;

        button {
            opacity: 0.7;
            transition: opacity 0.3s;
            pointer-events: all;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: transparent;
            border: none;
            overflow: hidden;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 50%;
                background-color: var(--primary-color);
                opacity: 0.1;
                border-radius: 0px;
            }

            svg {
                fill: var(--primary-color);
                width: 20px;
                height: 20px;
            }

            &:hover {
                opacity: 1;
            }

            &:first-child svg {
                transform: rotate(90deg)translateY(12px);
            }

            &:last-child {
                &::before {
                    left: unset;
                    right: 0;
                }

                svg {
                    transform: rotate(-90deg)translateY(12px);
                }
            }
        }
    }
}

.widgets {
    @extend %CommonInnerFocusSpace;

    .widgetsContainer {
        padding-left: 20px;
        display: grid;
        grid-template-columns: calc(50% - 20px) calc(50% - 20px);
        grid-gap: 10px;
        grid-auto-rows: minmax(45vh, auto);
    }
}

.resizableDataHolder {
    min-width: 400px;
}

.showDataHolder {
    height: 100%;
}

.loadingText {
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: nth($grey-shades, 4);
    font-weight: 500;
}

.carouselContainer {
    position: relative;
    width: calc(100% - 100px);
    margin: 0px auto;

    div[class*="react-multi-carousel-list"] {
        overflow-y: visible;
    }

    .reactCarousel {
        background-color: transparent;
        padding: 10px 0 10px 0 !important;
    }
}

.blurrify {
    overflow: hidden;
    pointer-events: none;

    &>* {
        filter: blur(5px);
    }
}