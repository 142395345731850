@import '../../../common/styles/colors.scss';
@import '../../../theme';

.viewOnlyUser {
    composes: viewOnlyListCard;

    .addRole {
        display: none;
    }

    [class*="meta"] {
        button {
            display: none;
        }
    }
}

.profileHolder {
    margin-top: 30px;
    
    .meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 5px;

        button {
            margin: 0;
        }
    }

    .index {
        font-size: 12px;
        color: var(--primary-color);
        font-weight: 500;
        margin-left: 10px;        
    }
}

.undoDeleteButton {
    padding: 10px 20px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    background-color: $danger;
    border-radius: 15px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);

    &:active, &:focus {
        outline: none;
    }
}

.inactiveProfile {
    opacity: 0.5;
    pointer-events: none;
}

.addRole {
    display: block;
    width: calc(100% - 15px);
    margin: 0 7.5px;
    border: 2px dashed var(--contrast-color);
    color: var(--contrast-color);
    margin-top: 15px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    background-color: white;
    transition: color 0.5s, background-color 0.5s;

    span {
        font-size: 30px;
        vertical-align: middle;
    }

    &:hover {
        background-color: var(--contrast-color);
        color: white;
    }
}