@import '../../common/styles/colors.scss';

.optionsList {
    position: relative;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(var(--primary-color-rgb), 0.3);
    border-radius: 20px;
    max-height: 200px;
    overflow: auto;
    transition: visibility 0s linear 0s;
    overscroll-behavior: contain;
}

.hiddenOptionsList {
    composes: optionsList;
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    
    
    /******************************************************************************************
     * Delay the options hiding by a couple of seconds.                                       *
     *                                                                                        *
     * The blur event runs before the click event is registered on the <Option> component, so *
     * the element is removed from the DOM before it even runs. Since that happens, the       *
     * onSelect function doesn't even get a chance to run. We need to delay the hiding of the *
     * options on blur to avoid that.                                                         *
     ******************************************************************************************/
    transition: visibility 0s linear 1s;
}

.searchInputHolder {
    position: sticky;
    top: 0px;
    padding: 2px 0px 0px;
    background-color: white;
    z-index: 1;

    & > svg[class*="search-icon"] {
        position: absolute;
        top: calc(50% + 4px);
        right: 5px;
        width: 17px;
        margin: 0 10px;
        height: 15px;
        transform: translateY(-60%);
        fill: gray;
    }

    input {
        position: relative;
        border: none;
        border-radius: 30px;
        border: 2px solid rgba(var(--primary-color-rgb), 0.1);
        background-color: white;
        display: block;
        height: 35px;
        width: calc(100% - 10px);
        padding: 0 10px;
        margin: 5px auto;
        font-size: 12px;
        color: nth($grey-shades, 3);
        transition: border-color 0.3s;
    
        &::placeholder {
            color: silver;
            font-size: 12px;
        }
    
        &:focus {
            border-color: rgba(var(--primary-color-rgb), 0.5);
        }
    }
}

.checkboxHolder {
    width: 95%;
    margin: 10px auto 0;
}