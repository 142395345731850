@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.innerFocus {
    padding: 20px;
    @extend %CommonInnerFocusSpace;

    & > section {
        display: block;
    }
}

.tokenContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 10px;

    .tokenText {
        max-width: 50%;
        word-wrap: break-word;
    }
}

.apiCallContainer {
    position: relative;
    margin-bottom: 40px;
}

.apiHeading {
    font-size: 22px;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 10px;
    padding-top: 1px;
}

.apiSubHeading {
    font-size: 16px;
    font-style: italic;
    font-weight: normal;
    color: var(--primary-color);
    margin-bottom: 5px;
}

.url {
    padding: 5px 15px;
    background-color: $light-grey;
    font-family: monospace;
    color: $dark-blue;
    margin-bottom: 15px;
}

.tableHolder {
    margin-bottom: 15px;
}

.dataHolder {
    margin-bottom: 15px;

    button {
        position: absolute;
        right: 0;
        z-index: 1;
        margin-top: 10px;
        margin-right: 10px;
    }
}

.apiHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}