@import '../../common/styles/colors.scss';
@import '../../theme.scss';

.noDataMessage {
    background-color: #f9fafc;
    text-align: left;
    width: 100%;
    text-align: center;
    padding: 30px 0;
    color: nth($grey-shades, 3);
    font-weight: 400;
    font-size: 20px;
    position: relative;

    span {
        position: relative;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        @extend %ThemeLightBackground;
    }
}

.addEntryButton {
    margin-left: 15px;

    button {
        width: 150px;
    }
}

.meta {
    z-index: 5;
    width: 100%;
    padding: 10px 25px 5px 25px;
    display: flex;
    align-items: center;
    position: relative;
    position: sticky;
    top: 0;
    background-color: transparent;

    &>* {
        position: relative;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-top-right-radius: 30px;

        @extend %ThemeLightBackground;
    }

    &>button {
        margin-left: 15px;
    }

    .refreshButton {
        margin-right: auto;
        margin-left: 15px;
    }

}

.csvButton {
    button svg {
        stroke: var(--primary-color);
    }
}

.pageActions {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    position: sticky;
    bottom: 0;
    padding: 15px 25px;
    background-color: white;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0;
        @extend %ThemeLightBackground;
    }

    .pageSize {
        position: relative;

        div {
            display: inline-block;
            vertical-align: middle;
            color: var(--primary-color);
            font-size: 14px;
            font-weight: 600;
            opacity: 0.8;
        }

        input {
            width: 50px;
            padding: 0 10px;
            font-size: 14px;
            color: nth($grey-shades, 4);
            height: 30px;
            text-align: center;
            border: none;
            background-color: white;
            box-shadow: 0px 0px 5px inset var(--primary-color);
            font-weight: 600;
            border-radius: 5px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            border-radius: 30px;
        }

        .nav {
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &.decreaseSize {
                transform: rotate(90deg);
            }

            &.increaseSize {
                transform: rotate(-90deg);
                margin-left: 7px;
            }

            &.prevPage,
            &.nextPage {
                width: 25px;
                height: 25px;
                border: 1px solid var(--primary-color);
                border-radius: 50%;

                svg {
                    width: 50%;
                }
            }

            &.prevPage {
                transform: rotate(-180deg);
                margin-right: 7px;
            }

            &.disableButton {
                opacity: 0.3;
                cursor: not-allowed;
            }

            &.highlight {
                background-color: var(--contrast-color);
                border-color: var(--contrast-color);

                svg {
                    stroke: white;
                }
            }

            &.nextPage {
                transform: rotate(0deg);
                margin-left: 7px;
            }

            svg {
                stroke: var(--primary-color);
                width: 80%;
                height: 100%;
            }
        }

        [class*="CommonButton"] {
            margin: 0;
            margin-left: 5px;
        }
    }

}

.expandedMeta {
    composes: meta;
}

.filters {
    width: 100%;
    padding: 0 25px;
    align-items: center;
    display: flex;
    position: relative;
}

.expandedFilters {
    composes: filters;
}

.newEntryFormHolder {
    position: absolute;
    top: 0%;
    right: 0px;
    width: 100%;
}

.limitReachedMessage {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 14px;
    margin: 0 10px;
}

.entireTableHolder {
    width: 100%;

    &.blur {
        .extraHeaderElements {
            filter: blur(5px);
            pointer-events: none;
        }

        .pageActions {
            filter: blur(5px);
            pointer-events: none;
        }

        .refreshButton {
            filter: blur(5px);
            pointer-events: none;
        }

        .refreshButton {
            filter: blur(5px);
            pointer-events: none;
        }

        .meta {

            &>button,
            .searchBox,
            .pageSize {
                filter: blur(5px);
                pointer-events: none;
            }
        }

        .tableContainer,
        .addEntryButton {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    &.blurOnlyTop {
        .meta {

            &>button,
            .searchBox,
            .pageSize {
                filter: blur(5px);
                pointer-events: none;
            }
        }
    }
}

.filterTagsHolder {
    position: sticky;
    top: 70px;
    padding: 0 25px;
    z-index: 3;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-top-right-radius: 30px;

        @extend %ThemeLightBackground;
    }

    .filterTag {
        display: inline-flex;
        padding: 7px 10px;
        font-size: 11px;
        background-color: rgba(var(--primary-color-rgb), 0.15);
        color: rgba(var(--primary-color-rgb), 0.8);
        margin-right: 15px;
        border-radius: 500px;
        margin-bottom: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        font-weight: 600;
        position: relative;
        align-items: center;

        .cancelIcon {
            background: white;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            cursor: pointer;

            svg {
                width: 10px;
                height: 10px;
            }

        }
    }
}

.editEntryFormHolder {
    position: fixed;
    top: 70px;
    right: 36px;
    width: calc(83vw - 350px - 80px - 50px);
    min-height: 30px;
    text-align: left;
    z-index: 5;
}

.expandedEditEntryFormHolder {
    composes: editEntryFormHolder;
    width: calc(83vw - 80px - 50px);
}

.undoDeleteButton {
    padding: 10px 20px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    background-color: $danger;
    border-radius: 15px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);

    &:active,
    &:focus {
        outline: none;
    }
}

.selectedAction {
    z-index: 1;
}

.table {
    position: relative;
    width: calc(100% - 50px);
    margin: 0px auto;
    border-radius: 10px;
    overflow: visible;
    background-color: transparent;
    text-align: center;

    &.blur {

        &>header,
        .row {
            filter: blur(5px);
            pointer-events: none;
        }

        .selectedRow {
            filter: blur(0px);
            pointer-events: all;
        }
    }

    .tableBody {
        margin-top: 3px;

        &.actionTable {
            .cell {
                &:nth-last-child(2) {
                    width: 70px;
                    flex-grow: 0;
                    justify-content: center;
                }
            }
        }

        &.hasMore {
            .cell {
                flex-grow: 0;

                &:nth-last-child(2) {
                    width: 70px;
                    flex-grow: 1;
                    justify-content: flex-end;
                }

                &:nth-last-child(1) {
                    width: 70px;
                    flex-grow: 0;
                    justify-content: center;
                }
            }
        }

        .row {
            &:not(:first-child) {
                .cell {
                    word-break: break-word;
                }
            }
        }

        .cell {
            font-size: 12px;
            font-weight: 500;
            color: nth($grey-shades, 4);
            font-weight: 600;
            background-color: transparent;
            align-self: stretch;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px;
            text-align: left;
            width: 10vw;
            flex-grow: 1;


            &:not(.equidistant):nth-child(1) {
                width: 70px;
                flex-grow: 0;
                text-align: center;
                justify-content: center;
            }

            &.actions {
                display: flex;
                position: relative;
                background-color: rgba(var(--primary-color-rgb), 0.02);
                text-align: center;
                justify-content: center;

                width: 70px;
                flex-grow: 0;
                justify-content: center;
            }

            &.equidistant {
                flex-grow: 1;
            }
        }


        .inactiveRow {

            .cell {
                color: lighten(nth($grey-shades, 3), 15%);
                background-color: lighten(nth($grey-shades, 3), 35%);
            }

        }

        .actions {
            padding: 0;
        }

        .counter {
            display: inline-block;
            margin-right: 5px;
            color: darken(nth($grey-shades, 3), 10%);
        }
    }

    &.styleOverriden {
        .tableBody {
            .cell:nth-child(2n - 1) {
                background-color: transparent;
            }
        }
    }
}

.actionImage {
    display: inline-block;
    width: 15px;
    height: auto;
    margin: 0 15px;
}

.row {
    display: flex;
    border-bottom: 1px solid rgba(var(--primary-color-rgb), 0.1);
    min-height: 50px;
    background-color: white;
    flex-wrap: nowrap;
    border-radius: 0px;

    [class*="workflowTypeName"],
    [class*="locationHierarchy"] {
        text-decoration: underline;
        cursor: pointer;
    }

    &:hover {
        position: relative;
        border-radius: 10px;
        border-bottom: 0px;
        transition: all 0.1s ease-in;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            border-radius: 10px;
            box-shadow: 0px 0px 7px 2px rgba(var(--primary-color-rgb), 0.2);
            filter: grayscale(50%);
        }
    }

    .table:not(.styleOverriden) &:first-child {
        position: sticky;
        top: 0px;
        z-index: 4;
        min-height: 35px;
        background-color: white;

        &::before {
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            @extend %ThemeLightBackground;
        }

        .cell {
            background-color: rgba(var(--primary-color-rgb), 0.1);
            color: var(--primary-color);
            filter: grayscale(20%);
            position: relative;

            &:first-child {
                color: nth($grey-shades, 3);
                border-top-left-radius: 20px;
            }

            &:last-child {
                border-top-right-radius: 20px;
            }

            &:nth-child(2n - 1) {
                background-color: rgba(var(--primary-color-rgb), 0.1);
            }
        }

        &.pushHeaderDown {
            top: 70px;
        }
    }

    &:last-child {
        border-bottom: 0px;
    }

    &.dashboardWidgetCell {
        height: 50px;

        .cell {
            padding: 8px 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.6;
            white-space: normal;
            text-align: center;
            justify-content: center;
        }
    }
}

.selectedRow {
    composes: row;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    border-bottom: 0px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border-radius: 10px;
        box-shadow: 0px 0px 7px 2px rgba(var(--primary-color-rgb), 0.2);
        filter: grayscale(50%);
    }
}

.inactiveRow {
    composes: row;
}

.actions {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .actionIcon {
        width: 16px;
        height: 16px;

        svg {
            width: 100%;
            height: 100%;
            fill: var(--primary-color);
        }
    }

    &>[class*="CommonButton"] {
        transform: scale(0.8);
    }

    .triangle {
        position: absolute;
        left: calc(50% - 12px);
        top: calc(100% - 20px);
        z-index: 1;
        color: white;
        font-size: 24px;
        text-shadow: 0 -9px 8px var(--primary-color);
        pointer-events: none;
        opacity: 0;
        animation: dropinTriangle 0.2s forwards;

        @keyframes dropinTriangle {
            0% {
                transform: translateY(-10px)scaleX(1.5);
                opacity: 0;
            }

            100% {
                transform: translateY(0px)scaleX(1.5);
                opacity: 1;
            }
        }
    }

    .moreOptions {
        position: absolute;
        top: calc(100% + 2px);
        right: 0px;
        background-color: white;
        box-shadow: 0px 0px 5px var(--primary-color);
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 10px;
        min-width: 100%;
        flex-wrap: nowrap;
        opacity: 0;
        animation: dropin 0.2s forwards;

        @keyframes dropin {
            0% {
                transform: translateY(-10px);
                opacity: 0;
            }

            100% {
                transform: translateY(0px);
                opacity: 1;
            }
        }

        button {
            margin: 0px 5px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .clipboardText {
            width: 250px;
            margin: 7px;

            .container {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                svg {
                    width: 30px;
                    height: 30px;
                    fill: var(--primary-color);
                    margin-right: 10px;
                }

                .text {
                    padding: 7px;
                    background-color: rgba(var(--primary-color-rgb), 0.1);
                    border-radius: 15px;
                    font-size: 13px;
                    color: var(--primary-color);
                    flex-grow: 1;
                }
            }

            p {
                margin: 5px 0 0;
                font-size: 12px;
                color: var(--primary-color);
            }
        }
    }
}

.usersHolder {
    position: absolute;
    top: 60px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    overflow: visible;
    transform: translateX(-60%);

    &::before {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-top: 15px solid white;
        border-right: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid transparent;
        top: -30px;
        right: 45px;
        z-index: 3;
        transform: rotate(180deg);
    }

    &.upwardModal {
        transform: translateX(-60%)translateY(calc(-110% - 20px));

        &::before {
            transform: rotate(0deg);
            top: calc(100% - 2px);
        }
    }
}

.userEntry {
    padding: 6px;
    cursor: pointer;
}


.pageLink {
    width: 30px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    border: 1px solid lighten(nth($grey-shades, 3), 5%);
    color: darken(nth($grey-shades, 3), 20%);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &.activePageLink {
        color: white;
        background-color: var(--primary-color);
        border: none;
        pointer-events: none;
    }

    &:hover {
        background-color: nth(nth($grey-shades, 3)-shades, 1);
    }

    &:last-child {
        margin-right: 0;
    }
}