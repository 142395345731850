@import '../../common/styles/colors.scss';

.tooltipHolder {
    width: 300px;
    height: auto;
    padding: 10px 10px 10px 10px;
    z-index: 2;
    position: relative;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(var(--primary-color-rgb), 0.3);
    border-radius: 30px;
    overflow: auto;
    transition: visibility 0s linear 0s;
    overscroll-behavior: contain;

    header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        position: relative;
        padding: 5px 10px 5px;

        svg {
            filter: grayscale(70%);
            width: 12px;
            height: 12px;
            fill: var(--primary-color);
        }

        h5 {
            font-size: 12px;
            font-weight: 600;
            color: var(--primary-color);
            letter-spacing: 0.5px;
            margin-left: 7px;
            filter: grayscale(70%);
        }
    }
}

.searchInputHolder {
    position: sticky;
    top: 0px;
    padding: 2px 0px 0px;
    background-color: white;
    z-index: 1;

    & > svg {
        position: absolute;
        top: calc(50% + 4px);
        right: 5px;
        width: 17px;
        margin: 0 10px;
        height: 15px;
        transform: translateY(-60%);
        fill: gray;
    }
}

.searchInput {
    position: relative;
    border: none;
    border-radius: 30px;
    border: 2px solid rgba(var(--primary-color-rgb), 0.1);
    background-color: white;
    display: block;
    height: 35px;
    width: calc(100% - 10px);
    padding: 0 10px;
    margin: 5px auto;
    font-size: 12px;
    color: nth($grey-shades, 3);
    transition: border-color 0.3s;

    &::placeholder {
        color: silver;
        font-size: 12px;
    }

    &:focus {
        border-color: rgba(var(--primary-color-rgb), 0.5);
    }
}

.searchBox {
    position: relative;
    border: none;
    border-radius: 30px;
    border: 2px solid rgba(var(--primary-color-rgb), 0.1);
    background-color: white;
    display: block;
    height: 35px;
    width: calc(100% - 10px);
    padding: 0 10px;
    margin: 5px auto;
    font-size: 12px;
    color: nth($grey-shades, 3);
    transition: border-color 0.3s;

    &::placeholder {
        color: silver;
        font-size: 12px;
    }

    &:focus {
        border-color: rgba(var(--primary-color-rgb), 0.5);
    }

}

.listItems {
    position: relative;
    background-color: white;
    border-radius: 20px;
    max-height: 200px;
    overflow: auto;
    transition: visibility 0s linear 0s;
    overscroll-behavior: contain;

    &:hover {
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
    }
}

.listItem {
    font-size: 12px;
    color: nth($grey-shades, 4);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 98%;
    height: 40px;
    margin: 5px auto;
    border-radius: 30px;

    & > * {
        font-style: normal;
        color: rgba(var(--primary-color-rgb), 0.7);
        font-weight: 600;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        text-align: left;
    }
    
    &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.2);
        cursor: pointer;
    }
}