@import '../../../common/styles/colors.scss';
@import '../../../theme';

.buttonHolder {
    position: relative;

    button {
        margin: 0;
        
        svg {
            stroke: var(--primary-color);
        }
    }
}