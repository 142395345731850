@import '../../common/styles/colors.scss';

.option {
    font-size: 12px;
    color: nth($grey-shades, 4);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 98%;
    height: 40px;
    margin: 5px auto;
    border-radius: 30px;
    
    &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.2);
        cursor: pointer;
    }
    
    span {
        font-style: normal;
        color: rgba(var(--primary-color-rgb), 0.7);
        font-weight: 600;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    em {
        background-color: rgba(var(--primary-color-rgb), 0.2);
        border-radius: 30px;
        padding: 0px 5px;
        font-style: normal;
    }
}

.selectedOption {
    composes: option;
    background-color: rgba(var(--primary-color-rgb), 0.2);
    color: white;
}