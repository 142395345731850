@import '../../../common/styles/colors.scss';
@import '../../../theme.scss';

.marketPage {
    position: relative;
    overflow: auto;
    height: 100vh;
    width: 100vw;
}

.logo {
    cursor: pointer;
    width: 200px;
    margin: 20px;
}

.pageHeading {
    width: 50%;
    margin: 20px auto 50px;

    h1 {
        font-size: 20px;
        font-weight: bold;
        filter: grayscale(50%);
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }
    
    p {
        line-height: 2;
        font-size: 13px;
        text-align: center;
        color: var(--primary-color);
        opacity: 0.7;
        filter: grayscale(50%);
    }

    .searchInputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        margin-top: 30px;
        
        button {
            margin: 0;
        }
    }

    .searchInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;        
        box-shadow: 0px 0px 15px -4px nth($grey-shades, 3);
        padding: 0px 10px;
        border-radius: 30px;
        height: 45px;
        margin: 0px 20px;

        .icon {
            margin-left: 7px;

            svg {
                width: 15px;
                height: 15px;
                fill: var(--primary-color);
            }
        }

        input {
            border: none;
            flex-grow: 1;
            font-size: 13px;
            font-weight: 600;
            padding-left: 15px;
            color: nth($grey-shades, 4);
            height: 100%;

            &::placeholder {
                color: nth($grey-shades, 3);
                font-weight: 500;
                opacity: 0.7;
            }
        }

        button {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: none;
            background-color: nth($grey-shades, 4);
            opacity: 0.7;
            cursor: pointer;

            svg {
                fill: white;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px;
}

.segmentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    margin: -50px auto 50px;
    position: sticky;
    z-index: 10;
    top: 0;
    background-color: white;

    .segmentHolder {
        flex-grow: 1;
        border-radius: 30px;
        
        button, a {
            height: 40px;
            font-size: 12px;
            font-weight: 700;
        }
    }

    button {
        margin: 0;
    }
}

.segmentHolder {
    display: flex;
    margin: 15px;
    background-color: rgba(var(--primary-color-rgb), 0.2);
    border-radius: 20px;
    justify-content: center;
    align-items: stretch;
    padding: 2px;

    button, a {
        cursor: pointer;
        flex-grow: 1;
        height: 30px;
        background-color: transparent;
        color: var(--primary-color);
        border-radius: 20px;
        border: none;
        filter: grayscale(50%);
        font-size: 11px;
        letter-spacing: 0.5px;
        padding: 0 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.active {
            color: white;
            background-color: var(--primary-color);
        }
    }
}

.filter {
    width: 300px;
    background-color: white;
    position: sticky;
    top: 10px;
    max-height: calc(100vh - 40px);
    overflow: auto;
    border-radius: 20px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    &>* {
        position: relative;
    }

    ul {
        list-style: none;        
    }

    header {
        background-color: rgba(var(--primary-color-rgb), 0.15);
        height: 50px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;            

        h5 {
            filter: grayscale(70%);
            font-size: 12px;
            font-weight: 600;
            color: var(--primary-color);                
        }

        .count {
            filter: grayscale(70%);
            font-size: 11px;
            background-color: rgba(var(--primary-color-rgb), 0.3);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            color: var(--primary-color);
            font-weight: 600;
        }

        .clearButton {
            border: 1px solid var(--primary-color);
            border-radius: 20px;
            height: 20px;
            padding: 0 10px;
            font-size: 10px;
            color: var(--primary-color);
            font-weight: 700;
            background-color: transparent;
            cursor: pointer;
        }
    }
    
    li {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: rgba(var(--primary-color-rgb), 0.05);
        }

        label {
            font-size: 11px;
            color: var(--primary-color);
        }

        .checkbox {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 5px inset nth($grey-shades, 3);
            background-color: white;
            border-radius: 5px;

            svg {
                width: 10px;
                height: 10px;
                fill: white;
                display: none;
            }

            &.active {
                background-color: var(--contrast-color);

                svg {
                    display: block;
                }
            }            
        }
    }    

    .tagsHolder {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        .tag {
            background-color: var(--primary-color);
            filter: grayscale(50%);
            height: 26px;
            padding: 0 3px 0px 10px;
            font-size: 11px;
            color: white;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            opacity: 0.8;
            margin-bottom: 10px;
            margin-right: 10px;

            button {
                width: 20px;
                height: 20px;
                background-color: white;
                border: none;
                border-radius: 50%;
                margin-left: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 8px;
                    height: 8px;
                    fill: nth($grey-shades, 4);
                    border-radius: 50%;
                }
            }
        }
    }
}

.resultHolder {
    width: calc(100% - 300px - 20px);
    padding-bottom: 20px;

    .searchResultText {
        color: var(--primary-color);
        font-weight: 500;
        filter: grayscale(50%);
        margin-bottom: 20px;

        span {
            font-weight: 700;
        }
    }
}

.features {
    list-style: none;

    li {
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 1.5;
        color: var(--primary-color);
        opacity: 0.7;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:last-child {
            margin: 0;
        }

        &::before {
            content:"·";
            font-size: 10px;
            position: relative;
            transform: scale(5);
            line-height: 0;
            margin-right: 20px;
        }
    }
}

.productList {    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    list-style: none;
    padding-bottom: 20px;

    @media screen and (min-width: 1500px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 1023px) and (max-width: 1499px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .productCard {
        width: 100%;
        overflow: hidden;
        border-radius: 20px;
        position: relative;
        background-color: lighten(nth($grey-shades, 2), 4%);

        @extend %CommonCard;
    }

    .imageCarouselHolder {
        width: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        
        div[class*="react-multi-carousel-list"] {
            overflow-y: visible;

            button {
                background-color: transparent;

                &[class*="arrow--right"] {
                    margin-right: -10px;
                    &::before {
                        color: var(--primary-color);
                    }
                }
                &[class*="arrow--left"] {
                    margin-left: -10px;
                    &::before {
                        color: var(--primary-color);
                    }
                }
            }
        }
        
        &::before {
            z-index: 1;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            width: 100%;
            height: 100%;
            filter: grayscale(50%);
            box-shadow: 0px 0px 10px -3px inset var(--primary-color);
            border-radius: 20px;
        }

        .type {
            position: absolute;
            right: 20px;
            top: 20px;
            background-color: var(--primary-color);
            border-radius: 5px;
            padding: 7px 13px;
            color: white;
            font-size: 12px;
            filter: grayscale(50%);
            opacity: 0.8;
            z-index: 1;
            
            svg {
                fill: white;
                vertical-align: middle;
                width: 15px;
                height: 15px;
            }
        }        
    }

    .info {
        filter: grayscale(50%);
        padding: 20px;        
    }

    h4 {
        font-size: 16px;
        color: var(--primary-color);
        font-weight: 700;
        margin-bottom: 5px;
    }

    h5 {
        font-size: 13px;
        color: var(--primary-color);
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        font-size: 13px;
        line-height: 1.5;
        color: var(--primary-color);
        opacity: 0.7;
        margin: 12px 0;
    }

    .buttonsHolder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 15px 15px;

        button {
            margin: 0;
        }
    }
}

.addPackageForm {
    width: 50%;
    margin: 0 auto;

    h4 {
        text-align: left;
        font-size: 18px;
        color: var(--primary-color);
        margin-bottom: 20px;
        filter: grayscale(70%);
    }

    .formSegment {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 15px;
        margin-bottom: 50px;
    }

    .formButtonsHolder {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin: 0 10px;
        }
    }
}

.detailsHolder {
    margin: 0 auto;

    h4 {
        padding: 0 25%;
        text-align: left;
        font-size: 18px;
        color: var(--primary-color);
        margin-bottom: 20px;
        filter: grayscale(70%);
    }

    .sector {
        padding: 0 25%;
        margin-bottom: 50px;
    }

    .features {
        filter: grayscale(50%);
        margin-bottom: 20px;
    }

    .description {
        p {
            text-align: left;
            font-size: 14px;
            line-height: 1.5;
            color: var(--primary-color);
            margin-bottom: 10px;
            filter: grayscale(50%);
            opacity: 0.7;
        }
    }

    .tableContainer {
        [class*="table"] {
            width: 100%;

            [class*="row"] {
                &::before {
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }
            }
        }
    }

    .slideShowHolder {
        .selectedImage {
            width: 100%;
            height: auto;
            border-radius: 20px;
            box-shadow: 0px 0px 20px -5px nth($grey-shades, 3);
        }

        .allImages {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
            width: 90%;
            margin: 20px auto;

            img {
                cursor: pointer;
                width: 100%;
                height: 100px;
                object-fit: contain;
                object-position: center;
                box-shadow: 0px 0px 5px 0px nth($grey-shades, 3);
                border-radius: 5px;
                transition: transform 0.3s;

                &.selected {
                    box-shadow: 0px 0px 5px 1px var(--primary-color);
                }

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}