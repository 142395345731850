.bugReportContainer {
    position: absolute;
    bottom: 50px;
    left: 0;
    padding: 0 17px;
    z-index: 2;
    width: 100%;
    background-color: white;
}

.showingFormContainer {
    composes: bugReportContainer;
    box-shadow: 0 -7px 7px rgb(0 0 0 / 20%);
}

.buttonHolder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.reportBugText {
    color: var(--primary-color);
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
}

.reportForm {

    .inputHolder {
        margin: 15px 0;

        label {
            margin-left: 10px;
            margin-bottom: 7px;
            display: block;
            font-size: 11px;
            color: var(--primary-color);
        }

        [class*="inputHolder"] {
            border-width: 3px;
        }

        input[type="file"] {
            display: block;
            width: 1px;
            height: 1px;
            visibility: none;
            opacity: 0;
        }

        button {
            margin: 0;
        }
    }

}

.submitButton {

    button {
        width: 100%;
    }

}

.isDisabled {

    button,
    p {
        opacity: 0.4;
        pointer-events: none;
        filter: grayscale(1.1);
    }
}