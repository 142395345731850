@import '../../../common/styles/colors.scss';
@import '../../../theme';

.fileLink {
    color: var(--primary-color);
}

.submit {
    display: block;
    width: 160px;
    margin: 30px auto;

    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 11px 0;

    text-align: center;
    color: white;
    background-color: var(--contrast-color);
    cursor: pointer;
}

.reportTimeline {
    position: relative;
    width: 30%;
    margin-right: 10px;
    background-color: white;
    overflow: hidden;
    border-radius: 20px;
    height: calc(100% - 20px);
    padding: 10px;
    transition: width 0.2s;

    &.deflate {
        width: 70px;
        padding: 10px 0;
        border-radius: 40px;
        
        .dataContainer {
            height: calc(100% - 110px);
        }

        .timelineHeader {
            padding: 10px 0;
            width: 50px;
            margin: 0 auto;
            flex-direction: column;
            text-align: center;
            border-radius: 30px;

            .shrinkButton {
                margin-left: 0px;
                margin-top: 5px;
            }

            .headingIcon {
                margin-right: 2px;
                margin-bottom: 10px;
            }

            button {
                transform: scale(0.9);
            }

            .heading {
                display: none;
            }            
        }

        li {
            width: 100%;
            
            .count {
                position: static;
                margin: 0px auto 10px;
            }
        }

        .fieldList {
            display: none;
        }

        .userHeader {
            padding: 0;
            text-align: center;

            h5 {
                font-size: 10px;
            }

            p {
                font-size: 10px;
            }
        }

        .transferDataHolder {
            padding: 0 5px;

            .iconHolder {
                margin: 10px auto;
            }

            p {
                font-size: 11px;
                text-align: center;
            }
        }

        .expandButtonHolder {
            width: 70px;
            height: 70px;
            padding: 0;
            border-radius: 50%;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    
    .dataContainer {
        height: calc(100% - 50px);
        padding-bottom: 20px;
        overflow: auto;
    }

    .timelineHeader {
        background-color: white;
        position: relative;
        border-radius: 30px;
        padding: 10px 10px 10px 15px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .shrinkButton {
            margin-left: 5px;
        }

        .headingIcon {
            width: 25px;
            fill: var(--primary-color);
            opacity: 0.5;
            height: 25px;
            margin-right: 10px;
        }

        button {
            margin: 0;
        }

        .heading {
            flex-grow: 1;
        }

        &>* {
            position: relative;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            @extend %ThemeLightBackground;
        }

        h2 {
            font-size: 14px;
            color: var(--primary-color);
            font-weight: 600;
            margin-bottom: 2px;
        }

        p {
            font-size: 10px;
            color: var(--primary-color);
            font-weight: 500;
        }
    }

    li {
        background-color: white;
        margin: 20px 10px 20px auto;
        width: calc(100% - 70px);
        list-style: none;
        position: relative;
        overflow: visible;

        .count {
            position: absolute;
            left: -50px;
            top: 10px;
            border: 1px solid rgba(var(--primary-color-rgb), 0.2);
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            color: var(--primary-color);
            filter: grayscale(50%);
            font-weight: 500;
        }
        
        &.transfered {
            background-color: transparent;
            box-shadow: none;
            margin: 30px 10px 30px auto;

            .count {
                top: calc(50% - 17.5px);
            }

            .transferDataHolder {
                display: block;
            }
        }

        &.hide {
            display: none;
        }
    }

    .transferDataHolder {
        display: none;
        padding: 10px;
        text-align: center;
        box-shadow: 0px 0px 5px -2px rgba(var(--primary-color-rgb), 0.5);
        border-radius: 15px;

        .iconHolder {
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 50%;
            margin: 10px auto;
            border: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
    
                @extend %ThemeLightBackground;
            }
    
            svg {
                stroke: var(--primary-color);
                filter: grayscale(50%);
                width: 18px;
                height: 18px;
                transform: rotate(90deg);
            }
        }

        p {
            font-size: 12px;
            color: var(--primary-color);
            line-height: 1.5;
        }
    }    
    
    .userHeader {
        padding: 0px 15px;
        margin-top: 10px;
        position: relative;
        background-color: white;
        filter: grayscale(50%);
        opacity: 0.7;

        h5 {
            font-size: 11px;
            font-weight: 600;
            margin: 5px 0;
            color: var(--primary-color);
            position: relative;
        }

        p {
            margin: 5px 0;
            font-size: 11px;
            font-weight: 500;
            color: var(--primary-color);
            position: relative;
        }
    }


    .fieldList {
        background-color: white;
        box-shadow: 0px 0px 5px -2px rgba(var(--primary-color-rgb), 0.5);
        border-radius: 15px;
        padding: 10px;
    }

    .fieldList .memberHeader {
        width: 100%;
        border-radius: 7px;
        background-color: white;
        color: white;
        position: relative;
        overflow: hidden;
        padding: 10px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
    
            @extend %ThemeLightBackground;
        }

        & > * {
            position: relative;
        }

        h5 {
            text-align: left;
            font-size: 12px;
            font-weight: 600;
            color: var(--primary-color);
        }
    }

    .fieldList .entry {
        width: 100%;
        padding: 5px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        &:last-child {
            border-bottom: 0px;
        }

        label {
            font-size: 10px;
            font-weight: 600;
            color: var(--primary-color);
            display: block;
            filter: grayscale(50%);
            opacity: 0.7;
            margin-bottom: 5px;
        }

        .selectedOptions {
            display: block;
            font-size: 12px;
            color: var(--primary-color);
            letter-spacing: 0.5px;
            filter: grayscale(40%);
        }
    }

    .fieldList .fieldDetailsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        background-color: white;
        position: relative;
        overflow: hidden;
        height: 56px;

        .fieldDetailsHolder {
            padding: 10px;
            display: flex;
            align-items: center;
            width: calc(100% - 33px);

            .fileTypeIconHolder {
                position: relative;
                padding-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
    
            .fieldDetails {
                position: relative;
                text-align: left;
                padding-left: 10px;
                border-left: 1px solid #D0C9D3;
                width: 84%;

                .fileName {
                    font-size: 11px;
                    padding-bottom: 6px;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline-block;
                    color: #5D4C63;
                    font-weight: 600;
                }
    
                .fieldSubDetail {
                    display: flex;
                    align-items: center;
                    gap: 0px 6px;
    
                    .fileExtensionName {
                        font-size: 9px;
                        color: #707070;
                    }
    
                    .dot {
                        width: 5px;
                        height: 5px;
                        background-color: #707070;
                        border: 1px solid #707070;
                        border-radius: 20px;
                    }
    
                    .fileSize {
                        font-size: 9px;
                        color: #908096;
                    }
                }
            }

        }

        .downloadIconHolder {
            position: relative;
            background-color: var(--primary-color);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33px;
            cursor: pointer;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            @extend %ThemeLightBackground;
        }
    }
}