@import '../../common/styles/colors.scss';
@import '../../theme.scss';

@keyframes dropfromBottom {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.helpLink {
    text-align: center;
    display: block;

    button {
        text-decoration: underline;
    }
}

.help {
    height: 100%;
    overflow: auto;
    position: relative;
    z-index: 0;

    ul {
        list-style: none;
        animation: dropfromBottom 0.3s forwards;
        opacity: 0;
        height: calc(100% - 50px);
        overflow: auto;
    }

    .helpType {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        width: 100%;
        height: 40px;
        padding: 0 20px;
        margin: 5px 0;
        filter: grayscale(70%);

        h4 {
            font-size: 14px;
            flex-grow: 1;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 15px;
                height: 15px;
                vertical-align: middle;
                fill: var(--primary-color);
                margin-right: 5px;
            }
        }

        .count {
            font-size: 14px;
            font-weight: 500;
        }
    }

    li {
        margin: 5px 0;        

        &.active {
            .Content {
                display: block;
            }

            header {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }

        header {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 40px;
            padding: 0 20px;
            position: relative;
            cursor: pointer;

            &>* {
                position: relative;
            }

            h5 {
                font-size: 12px;
                flex-grow: 1;
                font-weight: 500;
                color: var(--primary-color);
                filter: grayscale(70%);
            }

            svg {
                width: 12px;
                height: 12px;
                stroke: var(--primary-color);
                position: relative;
                transform: translateY(2px);
                transition: transform 0.3s;
                filter: grayscale(70%);
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                @include variableThemeBackground(0.2, 2, 0.5);
            }
        }

        .Content {
            display: none;
            padding: 15px 20px;
            user-select: none;
            max-height: 100%;
            overflow: auto;

            p {
                line-height: 1.8;
                font-size: 12px;
                color: var(--primary-color);
                filter: grayscale(70%);
                margin-bottom: 15px;
                font-weight: 400;
            }

            a {
                color: var(--contrast-color);
                text-decoration: underline;
            }

            .helpLink {
                text-decoration: none;
            }
        }
    }
}