@import '../../../../colors';
@import '../pieces';

.containerPiece {
    position: relative;
}

.nextPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 20px;
    pointer-events: all;
}

.nextPieceHovering {
    composes: nextPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.actionButtonContainer {
    display: flex;
    align-items: center;
}

.expandButton {
    background: $flowchart-aqua-dark;
    padding: 3px 8px;
    color: white;
    font-size: 9px;
    cursor: pointer;
    pointer-events: all !important;

    margin: 5px 10px;
    width: fit-content;
    border-radius: 5px;
}

.innerPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    height: 20px;
    pointer-events: all;
}

.innerPieceHovering {
    composes: innerPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.upperArmUpperPieceLock,
.upperArmLowerPieceLock,
.lowerArmLowerPieceCollapsedLock,
.lowerArmUpperPieceLock,
.lowerArmLowerPieceLock {
    background-image: url(../../../../assets/flowchart/for.svg);
}

.upperArm {
    position: relative;
    z-index: 3;
    min-width: 137px;
    width: fit-content;
    min-width: 60px;
    min-height: 65px;
    padding: 11px 10px 17px;
    margin-bottom: -15px;

    @include cursor-for-pieces();
}

.upperArmContent {
    display: inline-flex;
    align-items: center;
}

.upperArmUpperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: top left;
}

.spaceBetweenLocks,
.spaceBetweenCollapsedLocks {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: -1;
    bottom: 15px;
    width: 60px;

    background-color: $flowchart-aqua;
    border-left: 1px solid $flowchart-aqua-dark;
}

;

.spaceBetweenCollapsedLocks {
    border-bottom-left-radius: 4px;
}

.upperArmLowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: 0 -48px;
}

.lowerArmLowerPieceCollapsedLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: bottom left;
}

.extendableUpperArm {
    position: absolute;
    top: 0;
    bottom: 15px;
    left: 58px;
    right: 0;
    z-index: -1;
    border-radius: 4px;
    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;
    border-left-color: transparent;
}

.innards {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: stretch;
    min-height: 45px;
    pointer-events: none;

    &>* {
        pointer-events: all;
    }
}

.leftArm {
    position: relative;
    width: 20px;
    min-width: 20px;

    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;
    border-top-color: transparent;
    border-bottom-color: transparent;

    .overflowBoundary {
        position: absolute;
        top: 0;
        left: -6px;
        bottom: 0;
        right: -6px;
        overflow: hidden;
    }

    .overflow {
        position: absolute;
        top: 0;
        left: 5px;
        bottom: 0;
        right: 5px;
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
}

.upperArmOverflowBoundary {
    position: relative;
    top: 15px;
    left: -6px;
    width: 32px;
    height: 8px;
    overflow: hidden;
    margin-bottom: -8px;
}

.upperArmOverflow {
    position: relative;
    top: 0;
    left: 6px;
    width: 20px;
    height: 8px;
    z-index: 5;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.innerContent {
    padding-left: 0px;
    pointer-events: none;
    position: relative;
    z-index: 4;

    &>* {
        pointer-events: all;
    }
}

.lowerArm {
    position: relative;
    z-index: 1;
    min-width: 137px;
    width: fit-content;
    min-width: 138px;
    min-height: 45px;
    padding: 11px 10px 17px;
    margin-top: -1px;
    margin-bottom: -10px;

    @include cursor-for-pieces();
}

;

.lowerCollapsedArm {
    position: relative;
    z-index: 1;
    min-width: 137px;
    width: fit-content;
    min-width: 138px;
    padding: 0px 10px 0px;
    margin-top: 0px;
    margin-bottom: -10px;
    height: 10px;

    @include cursor-for-pieces();

}

;




;

.lowerCollapsedArm {
    position: relative;
    z-index: 1;
    min-width: 137px;
    width: fit-content;
    min-width: 138px;
    padding: 0px 10px 0px;
    margin-top: 0px;
    margin-bottom: -10px;
    height: 10px;

    @include cursor-for-pieces();

}

;




.lowerArmUpperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: 0 -98px;
}

.extendableLowerArm {
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 58px;
    right: 0;
    min-width: 80px;
    z-index: -1;
    border-radius: 4px;
    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;
    border-left-color: transparent;
}

.lowerArmLowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 310px 157px;
    background-position: bottom left;
}

.lowerArmContent {
    display: inline-flex;
    align-items: center;


}

.aquaContainerPiece {
    composes: containerPiece;

    &>*>.upperArmUpperPieceLock,
    &>*>.upperArmLowerPieceLock,
    &>*>.lowerArmLowerPieceCollapsedLock,
    &>*>.lowerArmUpperPieceLock,
    &>*>.lowerArmLowerPieceLock {
        background-image: url(../../../../assets/flowchart/for.svg);
    }

    &>*>.spaceBetweenLocks {
        background-color: $flowchart-aqua;
        border-left: 1px solid $flowchart-aqua-dark;
    }

    &>*>.spaceBetweenCollapsedLocks {
        background-color: $flowchart-aqua;
        border-left: 1px solid $flowchart-aqua-dark;
        border-bottom-left-radius: 4px;
    }

    &>*>.extendableUpperArm,
    &>*>.extendableLowerArm {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-left-color: transparent;
    }

    &>*>.leftArm {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

    &>*>.upperArmOverflow {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

    &>*>.leftArm .overflow {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
}

.camoContainerPiece {
    composes: containerPiece;

    &>*>.upperArmUpperPieceLock,
    &>*>.upperArmLowerPieceLock,
    &>*>.lowerArmLowerPieceCollapsedLock,
    &>*>.lowerArmUpperPieceLock,
    &>*>.lowerArmLowerPieceLock {
        background-image: url(../../../../assets/flowchart/question.svg);
    }

    &>*>.spaceBetweenLocks {
        background-color: $flowchart-camo;
        border-left: 1px solid $flowchart-camo-dark;
    }

    &>*>.spaceBetweenCollapsedLocks {
        background-color: $flowchart-camo;
        border-left: 1px solid $flowchart-camo-dark;
        border-bottom-left-radius: 4px;
    }

    &>*>.extendableUpperArm,
    &>*>.extendableLowerArm {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-left-color: transparent;
    }

    &>*>.leftArm {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

    &>*>.upperArmOverflow {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

    &>*>.leftArm .overflow {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
}

.redContainerPiece {
    composes: containerPiece;

    &>*>.upperArmUpperPieceLock,
    &>*>.upperArmLowerPieceLock,
    &>*>.lowerArmLowerPieceCollapsedLock,
    &>*>.lowerArmUpperPieceLock,
    &>*>.lowerArmLowerPieceLock {
        background-image: url(../../../../assets/flowchart/question-error.svg);
    }

    &>*>.spaceBetweenLocks {
        background-color: $flowchart-red;
        border-left: 1px solid $flowchart-red-dark;
    }

    ;

    &>*>.spaceBetweenCollapsedLocks {
        background-color: $flowchart-red;
        border-left: 1px solid $flowchart-red-dark;
        border-bottom-left-radius: 4px;
    }



    &>*>.extendableUpperArm,
    &>*>.extendableLowerArm {
        background-color: $flowchart-red;
        border: 1px solid $flowchart-red-dark;
        border-left-color: transparent;
    }

    &>*>.leftArm {
        background-color: $flowchart-red;
        border: 1px solid $flowchart-red-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

    &>*>.upperArmOverflow {
        background-color: $flowchart-red;
        border: 1px solid $flowchart-red-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

    &>*>.leftArm .overflow {
        background-color: $flowchart-red;
        border: 1px solid $flowchart-red-dark;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.nameInput {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 7px;
}

.nextPiece {
    position: relative;
}

.shadowedNextPiece {
    z-index: 0;
    opacity: 0.5;
}

.noHighlight {
    position: relative;
    z-index: 4;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 4;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 4;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 4;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 4;
}

.lowerNoHighlight {
    position: relative;
    z-index: 2;
}

.lowerAddedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 2;
}

.lowerUpdatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 2;
}

.lowerMovedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 2;
}

.lowerDeletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 2;
}