@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.hiddenFile {
    display: none;
}


.staticInfoHolder {
    display: flex;
    height: 100%;
    overflow: auto;
    padding: 10px 0;
}

.allStaticDataHolders {
    position: sticky;
    left: 0;
    z-index: 2;
    margin-left: 20px;
    display: block;
    min-width: 330px;
    height: 100%;
    padding: 0px 0px 10px;
    background-color: white;
    border-radius: 30px;
    
    @extend %CommonCard;
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    margin-top: 0px;
    padding-top: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}


.importExportButtonHolder {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;
    margin-top: 10px;

    button, label {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.newVerticalHolder {
    min-width: 290px;
}

.newCustomFieldPrompt {
    background-color: transparentize($grey, 0.85);
    color: $grey;
    font-size: 14px;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
}
