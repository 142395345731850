@import '../../../common/styles/colors.scss';
@import '../../../theme';

.fieldList {
    background-color: white;
    box-shadow: 0px 0px 5px -2px rgba(var(--primary-color-rgb), 0.5);
    border-radius: 15px;
    padding: 10px;
}

.fieldList .entry {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &:last-child {
        border-bottom: 0px;
    }

    label {
        font-size: 10px;
        font-weight: 600;
        color: var(--primary-color);
        display: block;
        filter: grayscale(50%);
        opacity: 0.7;
        margin-bottom: 5px;
    }

    .selectedOptions {
        display: block;
        font-size: 12px;
        color: var(--primary-color);
        letter-spacing: 0.5px;
        filter: grayscale(40%);
    }
}


.fieldList .fieldDetailsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: white;
    position: relative;
    overflow: hidden;
    height: 56px;

    .fieldDetailsHolder {
        padding: 10px;
        display: flex;
        align-items: center;
        width: calc(100% - 33px);

        .fileTypeIconHolder {
            position: relative;
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .fieldDetails {
            position: relative;
            text-align: left;
            padding-left: 10px;
            border-left: 1px solid #D0C9D3;
            width: 84%;

            .fileName {
                font-size: 11px;
                padding-bottom: 6px;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: inline-block;
                color: #5D4C63;
                font-weight: 600;
            }

            .fieldSubDetail {
                display: flex;
                align-items: center;
                gap: 0px 6px;

                .fileExtensionName {
                    font-size: 9px;
                    color: #707070;
                }

                .dot {
                    width: 5px;
                    height: 5px;
                    background-color: #707070;
                    border: 1px solid #707070;
                    border-radius: 20px;
                }

                .fileSize {
                    font-size: 9px;
                    color: #908096;
                }
            }
        }

    }

    .downloadIconHolder {
        position: relative;
        background-color: var(--primary-color);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        cursor: pointer;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }
}