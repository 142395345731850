@import '../../../../common/styles/colors.scss';
@import '../../../../theme';

.commonModalHolder.filterHolder {
    position: absolute;
    right: 75px;
    top: 0;
    transform: translate(0px, 10px);
    z-index: 5;
    display: block;
    width: 450px;
    margin: 0;

    .filterCloseButton {
        position: absolute;
        left: unset;
        right: 200px;
    }

    .addOrModifyListCard {
        margin-top: 50px;

        .container {
            max-height: 60vh;
            box-shadow: none;
            padding: 0px;
            width: calc(100% - 20px);
            margin: 0 auto;
            padding-top: 10px;
        }
    }    
}

.timingTable {
    border-radius: 10px;
    overflow: hidden;

    .tableHeader {
        display: flex;
        width: 100%;
        background-color: rgba(var(--primary-color-rgb), 0.2);
        align-items: center;
        justify-content: flex-start;
        height: 30px;
        
        label {
            font-size: 12px;
            color: nth($grey-shades, 4);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:first-child, &:last-child {
                width: 70px;
                padding: 0 10px;
            }

            &:nth-child(2) {
                flex-grow: 1;
                display: flex;
                align-items: space-between;

                span {
                    width: calc(100% / 5);
                    text-align: center;
                }
            }
        }
    }
}

.userCarouselActionButtons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    button {
        margin: 0;
    }
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.performanceHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;

    background-color: var(--primary-color);
    color: white;
    padding: 22px 20px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        background-color: transparent;
        border: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(white, 0.3);
        }

        svg {
            fill: white;
            width: 16px;
            height: 16px;
        }
    }
}

.dataContainer {
    height: calc(100vh - 120px);
    overflow: auto;
}

.moreUsersIndicator {
    color: var(--contrast-color);
    width: calc(100% - 20px);
    margin: 20px auto;
    text-align: right;
    font-size: 14px;
}

.tabs {
    position: sticky;
    top: -10px;
    z-index: 1;
    background-color: white;

    width: calc(100% - 20px);
    height: 35px;
    margin: 0 auto;
    cursor: pointer;

    display: flex;
    align-items: flex-end;
}

.tab {
    padding: 10px 15px;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 12px;
    color: var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.1);
    transition: background-color 0.3s, padding-bottom 0.3s;
    padding-bottom: 5px;
    margin: 0 1px;

    &:hover {
        background-color: lighten(nth($grey-shades, 2), 5%);
    }

    &:not(:last-child) {
        border-right: 1px solid nth($grey-shades, 2);
    }
}

.highlight {
    box-shadow: 0 0 10px 0 var(--contrast-color);
}

.tabDetails {
    background-color: rgba(var(--primary-color-rgb), 0.1);
    border-radius: 15px;
    padding: 10px;
    width: calc(100% - 20px);
    margin: 0 auto;
    border-top-left-radius: 0px;
}

.activeTab {
    composes: tab;
    color: white;
    background-color: var(--primary-color);
    padding-bottom: 10px;

    &:hover {
        background-color: var(--primary-color);
    }

    &:not(:last-child) {
        border-right: none;
    }
}

.filterHolder {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10px;
    margin: 20px auto;
    
    .input {
        &:first-child {
            width: 130px;
        }
        &:last-child {
            width: calc(100% - 140px);
        }
    }
}