@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.membersHolder {
    display: flex;
    height: calc(100% - 90px);
    margin-top: 20px;
}

.allMemberTypes {
    position: sticky;
    left: 0;
    z-index: 2;
    margin-left: 20px;
    display: block;
    min-width: 330px;
    height: 100%;
    padding: 0px 0px 10px;
    background-color: white;
    border-radius: 30px;
    
    @extend %CommonCard;
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    margin-top: 0px;
    padding-top: 0px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    display: block;

    .cardsTreeHolder {
        flex-wrap: wrap;
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        align-content: flex-start;
        margin-bottom: 20px;        
    }

    .treeHeader {
        display: block;
        margin: 0 auto 20px auto;
        padding: 0 10px;
        font-weight: 500;
        color: $grey;
    }
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}

.importExportButtonHolder {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;

    button, label {
        margin-top: 20px;
    }
}