@import '../../../../colors';
@import '../pieces';

.stepPiece {
    position: relative;
    min-width: 137px;
    width: fit-content;
    min-width: 60px;
    min-height: 59px;
    padding: 10px;
    margin-bottom: -10px;

    @include cursor-for-pieces();
}

.searchBoxHolder {
    text-align: center;
    color: white;
    cursor: pointer;
}

.searchBox {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 7px;
}

.nextPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 20px;
    pointer-events: all;
}

.nextPieceHovering {
    composes: nextPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.visibleItems {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    padding-bottom: 5px;
}

.upperPieceLock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 145px 59px;
    background-position: top left;
}

.filledUpperPieceLock {
    composes: upperPieceLock;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top-left-radius: 5px;
}

.spaceBetweenLocks {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: -1;
    bottom: 15px;
    width: 60px;
}

.lowerPieceLock {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60px;
    height: 15px;
    background-size: 145px 59px;
    background-position: bottom left;
}

.filledLowerPieceLock {
    composes: lowerPieceLock;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-bottom-left-radius: 5px;
    bottom: 10px;
}

.extendableUpperArm {
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 55px;
    right: 0;
    z-index: -1;
    border-radius: 4px;

    &.isCollapsed {
        left: 0;
    }
}

.editorWrapper {
    position: relative;
    width: 600px;
    background-color: white;
    padding: 3px 5px;
}

.editorWrapperSmall {
    position: relative;
    width: 300px;
    background-color: white;
    padding: 3px 5px;
}

.editButton {
    position: absolute;
    bottom: 3px;
    right: 3px;

    width: 10px;
    height: 15px;
    cursor: pointer;

    svg {
        width: 10px;
        height: 10px;
        fill: gray;
    }
}

.indigoStepPiece {
    composes: stepPiece;

    .upperPieceLock,
    .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/indigo-step.svg);
    }

    .filledUpperPieceLock,
    .filledLowerPieceLock {
        background: $flowchart-indigo;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-indigo;
        border-left: 1px solid $flowchart-indigo-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-indigo;
        border: 1px solid $flowchart-indigo-dark;
        border-left-color: transparent;
    }
}

.pinkStepPiece {
    composes: stepPiece;

    .upperPieceLock,
    .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/pink-step.svg);
    }

    .filledUpperPieceLock,
    .filledLowerPieceLock {
        background: $flowchart-pink;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-pink;
        border-left: 1px solid $flowchart-pink-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-pink;
        border: 1px solid $flowchart-pink-dark;
        border-left-color: transparent;
    }
}

.aquaStepPiece {
    composes: stepPiece;

    .upperPieceLock,
    .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/aqua-step.svg);
    }

    .filledUpperPieceLock,
    .filledLowerPieceLock {
        background: $flowchart-aqua;
        border-color: $flowchart-aqua-dark;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-aqua;
        border-left: 1px solid $flowchart-aqua-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-left-color: transparent;
    }
}

.camoStepPiece {
    composes: stepPiece;

    .upperPieceLock,
    .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/camo-step.svg);
    }

    .filledUpperPieceLock,
    .filledLowerPieceLock {
        background: $flowchart-camo;
        border-color: $flowchart-camo-dark;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-camo;
        border-left: 1px solid $flowchart-camo-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-camo;
        border: 1px solid $flowchart-camo-dark;
        border-left-color: transparent;
    }
}

.redStepPiece {
    composes: stepPiece;

    .upperPieceLock,
    .lowerPieceLock {
        background-image: url(../../../../assets/flowchart/red-step.svg);
    }

    .filledUpperPieceLock,
    .filledLowerPieceLock {
        background: $flowchart-red;
        border-color: $flowchart-red-dark;
    }

    .spaceBetweenLocks {
        background-color: $flowchart-red;
        border-left: 1px solid $flowchart-red-dark;
    }

    .extendableUpperArm {
        background-color: $flowchart-red;
        border: 1px solid $flowchart-red-dark;
        border-left-color: transparent;
    }
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.addPrompt {
    padding: 7px;
    text-align: center;
    color: white;
    cursor: pointer;
}

.nameInput {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 7px;
}

.noHighlight {
    position: relative;
    z-index: 1;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 1;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 1;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 1;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 1;
}

.booleanIndicator {
    position: relative;
    width: 30px;
    height: 25px;
    margin: 0 10px;
    background-color: darken($flowchart-aqua-dark, 5%);

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0px;
        height: 0px;
        border-width: 12.5px 10px;
        border-color: transparent;
        border-style: solid;
    }

    &::before {
        left: -20px;
        border-right-color: darken($flowchart-aqua-dark, 5%);
    }

    &::after {
        right: -20px;
        border-left-color: darken($flowchart-aqua-dark, 5%);
    }
}

.booleanIndicatorHovering {
    composes: booleanIndicator;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

    &::before {
        border-right-color: rgba(0, 0, 0, 0.4);
    }

    &::after {
        border-left-color: rgba(0, 0, 0, 0.4);
    }
}

