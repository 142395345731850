@import '../../../../common/styles/colors.scss';
@import '../../../../theme';

.basicUserDetails {
    width: calc(100% - 20px);
    margin: 0 auto 20px;
    border-radius: 15px;
    border: 2px solid rgba(var(--primary-color-rgb), 0.2);

    display: grid;
    grid-template-columns: 0.35fr 0.65fr;
    background-color: white;
    padding: 2px;
    
    :nth-child(1) {
        border-top-left-radius: 10px;
    }

    :nth-child(2) {
        border-top-right-radius: 10px;
    }

    :nth-last-child(2) {
        border-bottom-left-radius: 10px;
    }

    :nth-last-child(1) {
        border-bottom-right-radius: 10px;
    }

    .label, .value {
        padding: 10px 20px;
        line-height: 17px;
        margin: 2px;

        &:last-child, &:nth-last-child(2) {
            border-bottom: none;
        }
    }

    .label {
        color: nth($grey-shades, 4);
        font-weight: 700;
        text-align: center;
        overflow: hidden;        
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(var(--primary-color-rgb), 0.2);
        font-size: 12px;
    }

    .value {
        color: nth($grey-shades, 4);        
        position: relative;
        overflow: hidden;        
        font-size: 13px;
        background-color: rgba(var(--primary-color-rgb), 0.1);
    }
}