@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.searchButton {
    margin-right: 15px;
    button {
        svg {
            stroke: var(--contrast-color);
        }
    }
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.innerFocus {    
    @extend %CommonInnerFocusSpace;
}

.fetchCompletedMessage {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.modalHolder {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 6;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    animation: fadeIn 0.3s forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.richTextModal {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    background-color: white;
    width: 60vw;
    border-radius: 20px;
    padding: 10px;
    position: relative;
    animation: popUp 0.3s forwards;

    @keyframes popUp {
        from {
            transform: translateY(10vh);
        }
        to {
            transform: translateY(0vh);
        }
    }

    .submitModalButtons {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;

        button {
            flex-grow: 1;
            margin: 0 10px;
        }
    }
}