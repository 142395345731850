@import '../../../common/styles/colors.scss';
@import '../../../theme';

.question {
    padding: 15px 20px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin: 20px auto;
    width: calc(100% - 20px);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    & > * {
        position: relative;
    }

    &.errorQuestion {
        box-shadow: 0px 0px 10px $danger;
    }
}

.iconHolder {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: white;
    border-radius: 50%;

    svg {
        width: 12px;
        height: 12px;
        fill: var(--primary-color);
    }
}

.questionWords {
    flex-grow: 1;
}

.questionDetails {
    display: flex;
    align-items: center;
}

.disabledQuestion {
    composes: question;
    pointer-events: none;
    opacity: 0.4;
}

.questionText {
    font-size: 15px;
    font-weight: 500;
    color: var(--primary-color);
    filter: grayscale(50%);
    margin-bottom: 10px;
}

.optionalIndicator {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    margin-left: 10px;
}

.errorText {
    font-size: 13px;
    color: $danger;
    text-align: left;
    margin-top: 10px;
    display: block;
}

.input {
    display: block;
    flex-basis: 75%;
    flex-grow: 1;
    background-color: white;
    color: nth($grey-shades, 4);
    border-radius: 2px;
    border: 2px solid transparent;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    font-size: 16px;

    &:focus, &:active {
        outline: none;
    }
}

.locationHolder {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    & > section:first-child, & > section:nth-child(2) {
        flex-basis: 45%;
    }
}

.locationInput {
    composes: input;
    flex-basis: 45%;
    flex-grow: unset;
}

.locationFetch {
    width: 20px;
    height: auto;
}

.choicesList {
    flex-grow: 1;
}


.searchInput {
    width: 100%;
    margin-bottom: 10px;
}

.options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(var(--primary-color-rgb), 0.05);
    padding: 10px 10px 1px;
    border-radius: 30px;
}

.choice {
    display: flex;
    align-items: center;
    background-color: transparent;
    min-height: 30px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    color: var(--primary-color);
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;

    &:focus {
        box-shadow: 0 0 2px var(--primary-color);
    }

    .choiceMainText {
        font-size: 13px;
        font-weight: 600;
        margin-left: 7px;
    }
}

.choiceTextHolder {
    width: fit-content;
}

.choiceSubText {
    font-size: 12px;
    margin-left: 10px;
}

.textInputHolder {
    width: 100%;

    &.dateInput {
        width: 300px;
    }

    &.numberInput {
        width: 300px;
    }

    &.phoneInput {
        display: flex;
        width: 300px;

        & > section {
            &:first-child {
                width: 100px;
            }
            &:last-child {
                flex-grow: 1;
                margin-left: 10px;
            }
        }
    }
}

.activeChoice {
    position: relative;
    composes: choice;
    background-color: var(--primary-color);
    color: white;
}

.moreOptions {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 10px 0;
    color: var(--primary-color);
}

.inputFileType{

    display: flex;
    align-content: center;
    width: 100%;

    input{
        display: none;
    }

    label{
        background-color: var(--primary-color);
        color: white;
        height: 40px;
        width: 89px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        border-radius: 30px;
        font-size: 14px;
        cursor: pointer;

        svg{
            margin: auto 2px;
            fill: white;
            width: 13px;
            height: 13px;
        }
    }

    .defaultText{
        padding: 0 10px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 1;
        margin: auto;

        &.isLink{
            text-decoration: underline;
            cursor: pointer;
        }
    }
    
    .uploadedFile {
        text-decoration: underline;
    }

    .fileName{
        display: flex;
        align-items: center;
        color: var(--primary-color);
        height: 40px;
        justify-content: space-between;
        position: relative;
        margin: 0 10px;
        width: 86%;
        background-color: white;
        border-radius: 20px;
        padding-left: 10px;
        margin-right: 0;

        
        .defaultText {
            margin: 0;
            width: 78%;
            font-weight: 600;
        }

        .fileDetails {
            display: flex;
            align-items: center;

            .fileExtensionName {
                font-size: 11px;
                margin-right: 8px;
                color: #5D4C63;
            }

            .dot {
                width: 5px;
                height: 5px;
                background-color: #707070;
                border: 1px solid #707070;
                border-radius: 20px;
                margin-right: 8px;
            }

            .fileSize {
                font-size: 11px;
                margin-right: 8px;
                color: #5D4C63;
            }

            .closeButton {
                background-color: #9A8CA0;
                width: 26px;
                height: 26px;
                border-radius: 20px; 
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                position: relative;
    
                .bar {
                    width: 13px;
                    height: 13px;
                    position: relative;
                    cursor: pointer;
    
                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2.4px;
                        background-color: white;
                        border-radius: 24px;
                        transform: rotate(45deg);
                        top: 5px;
                    }
    
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2.4px;
                        background-color: white;
                        border-radius: 24px;
                        transform: rotate(-45deg);
                        top: 5px;
                    }
                }
            }
        }

        
    }
}