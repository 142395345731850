@import '../../../colors';

.addPrompt {
    padding: 7px;
    text-align: center;
    color: white;
    cursor: pointer;
}

.nameInput {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 7px;
}

.noHighlight {
    position: relative;
    z-index: 1;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 1;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 1;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 1;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 1;
}