@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.innerFocus {
    padding: 20px;
    @extend %CommonInnerFocusSpace;
}

.notesContainer {
    height: 90%;
    width: 100%;
    position: relative;
    overflow: auto;
    padding-bottom: 40px;
}

.notes {
    position: relative;
    margin-left: 20px;
    line-height: 1.5;
    margin-bottom: 20px;

    h3 {
        font-size: 18px;
        color: $blue;
    }

    ol {
        margin-left: 40px;

        li {
            margin: 10px 0;
            color: $dark-grey;
            font-weight: 500;
        }

        p {
            font-size: 15px;
            padding-left: 15px;
        }
    }
}