@import '../../common/styles/colors.scss';
@import '../../theme.scss';

.PageHeader {
    position: fixed;
    height: calc(100% - 30px);
    top: 0;
    right: 15px;
    z-index: 0;
    overflow: hidden;
    padding-left: 15px;

    &.expanded {
        z-index: 3;
    }

    &.active {
        z-index: 10;

        .segments {
            padding-bottom: 0;
            transform: translateY(0);
        }
    }

    &.disabled {
        filter: grayscale(0.7);
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;

        .searchButton {
            pointer-events: none;
        }
    }

}

.ProfileUtilitiesHolder {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 62px;
    pointer-events: all;
}

.searchBarModal,
.userProfileModal {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1;
    padding-top: 0vh;
    width: 100vw;
    height: 100vh;
}

.modalBackdrop {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}

@keyframes fadeIn {
    0% {
        transform: translateY(10vh);
        opacity: 0;
    }

    100% {
        transform: translateY(0vh);
        opacity: 1;
    }
}

.SearchBar {
    width: 50%;
    margin: 0 auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 30px;
    margin: 20px auto;
    border: 1px solid var(--primary-color);
    box-shadow: 0px 0px 5px inset var(--primary-color);
    background-color: white;
    position: relative;
    animation: fadeIn 0.3s forwards;
    opacity: 0;
    z-index: 1;

    .searchIconPlaceholder {
        width: 20px;
        height: 20px;
        fill: nth($grey-shades, 3);
        fill: nth($grey-shades, 3);
        margin-left: 20px;
    }

    input {
        height: 40px;
        width: calc(100% - 25px);
        background-color: transparent;
        border: 0px;
        color: nth($grey-shades, 3);
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
        padding: 0 15px;
        transition: width 0.3s, padding 0.3s;

        &::placeholder {
            color: nth($grey-shades, 3);
        }
    }
}

.pushDataButton {
    background-color: var(--contrast-color);
    color: white;
    align-self: center;
    padding: 12px 20px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.results {
    position: absolute;
    z-index: 1;
    background: white;
    width: 90%;
    right: 7%;
    top: calc(100% + 10px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    max-height: calc(100vh - 200px);
    overflow: auto;
    border-radius: 30px;
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
    transition: transform 0.3s, opacity 0.3s;
    padding: 10px;

    &.active {
        transform: translateY(0vh);
        opacity: 1;
        pointer-events: all;
    }

    header {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        color: var(--primary-color);
        filter: grayscale(50%);
        font-size: 15px;
        position: sticky;
        background-color: white;
        top: -10px;
        z-index: 1;
        font-weight: 600;

        &::after {
            content: '';
            width: auto;
            flex-grow: 1;
            margin-left: 10px;
            height: 1px;
            background-color: var(--primary-color);
            display: block;
            margin-top: 3px;
        }
    }
}

.namedResult {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--primary-color);
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    height: 50px;
    padding: 0 20px;
    font-size: 15px;
    border-radius: 30px;
    letter-spacing: 0.5px;

    &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.2);
    }

    &:last-child {
        border-bottom: none;
    }

    em {
        font-style: normal;
        background-color: rgba(var(--primary-color-rgb), 0.2);
        padding: 0 7px;
        border-radius: 30px;
    }
}

.personResult {
    display: flex;
    align-items: center;
    background-color: lighten(nth($grey-shades, 3), 40%);
    padding: 7px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;

    .personName {
        color: darken(nth($grey-shades, 3), 30%);
        margin-right: 10px;
    }

    .personId {
        color: darken(nth($grey-shades, 3), 25%);
        font-size: 14px;
    }

    .personNumber {
        flex-grow: 1;
        color: darken(nth($grey-shades, 3), 15%);
        font-size: 13px;
        text-align: right;
    }

    &:hover {
        background-color: var(--primary-color);

        .personName {
            color: white;
        }

        .personId {
            color: darken(white, 5%);
        }

        .personNumber {
            color: darken(white, 15%);
        }
    }

    &:last-child {
        border-bottom: none;
    }

    em {
        font-style: normal;
        color: var(--contrast-color);
        font-weight: bold;
    }
}

.segments {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    display: flex;
    margin-left: 15px;
    position: relative;
    overflow: hidden;
    justify-content: center;
    width: 220px;
    height: 82px;
    align-self: flex-end;
    align-items: stretch;
    transform: translateY(30px);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @include variableThemeBackground(0.1, 1.8, 0.5);
    }

    .buttonHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        background-color: transparent;
        width: calc(220px / 3);
        position: relative;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;

        &:nth-child(1) {
            &::before {
                border-top-left-radius: 0px;
                top: 100%;
                left: 0;
                transform: none;
                box-shadow: none;
                width: 30px;
                background-color: var(--primary-color);
            }
        }

        &:nth-child(3) {
            &::after {
                border-top-left-radius: 0px;
                top: 100%;
                right: 0;
                transform: none;
                box-shadow: none;
                width: 30px;
                background-color: var(--primary-color);
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: -5px;
            top: calc(100% - 25px);
            background-color: transparent;
            box-shadow: 10px 0px 0px inset var(--primary-color);
            height: 30px;
            width: 15px;
            border-top-left-radius: 25px;
            transform: rotate(180deg);
            z-index: 1;
            opacity: 0;

            @extend %ThemeLightBackground;
        }

        &::after {
            content: '';
            position: absolute;
            right: -5px;
            top: calc(100% - 25px);
            background-color: transparent;
            box-shadow: 10px 0px 0px inset var(--primary-color);
            height: 30px;
            width: 15px;
            border-bottom-left-radius: 25px;
            transform: rotate(0deg);
            opacity: 0;

            @extend %ThemeLightBackground;
        }

        button {
            transform: scale(0.8);
            margin: 0;
            z-index: 1;
        }

        .background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            opacity: 0;

            @extend %ThemeLightBackground;
        }

        &.active {

            &::before,
            &::after {
                opacity: 1;
            }

            .background {
                opacity: 1;
            }
        }
    }
}

.betaMarker {
    display: block;
    position: absolute;
    top: 65%;
    padding: 0px 8px;
    border: 1px solid var(--contrast-color);
    border-radius: 500px;
    font-size: 10px;
    color: white;
    font-weight: bold;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.7;
        background-color: var(--contrast-color);
    }

    span {
        position: relative;
    }
}


.container {
    height: calc(100% - 62px);
    width: 220px;
    margin-left: auto;
    position: relative;
    overflow: auto;
    border-radius: 30px;
    transform: translateX(100%);
    animation: dropfromLeft 0.3s forwards;
    pointer-events: all;

    @keyframes dropfromLeft {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    &::before {
        border-radius: 30px;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    &>* {
        position: relative;
    }
}