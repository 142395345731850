@import '../../common/styles/colors.scss';
@import '../../theme';

@keyframes dropfromBottom {
    0% {
        opacity: 0;
        transform: translateY(20px);
    } 100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.ProfilePage {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 60px;
    animation: dropfromBottom 0.3s forwards;
    opacity: 0;
}

.segmentHeader {
    text-align: center;
    padding: 20px 10px;

    h2 {
        margin: 0 auto 15px;
        font-size: 12px;
        font-weight: 600;
        color: var(--primary-color);
        filter: grayscale(70%);
        
        svg {
            fill: var(--primary-color);
            width: 16px;
            height: 16px;
            margin-right: 3px;
            vertical-align: bottom;
        }
    }

    button {
        vertical-align: middle;
        margin: 0 5px;
    }
}

.profileContainer {
    .plan {
        height: 40px;
        color: var(--primary-color);        
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            
            @include variableThemeBackground(0.2, 2, 0.5);
        }

        label, span {
            filter: grayscale(70%);
            position: relative;
        }

        span {
            font-weight: 600;
            margin-left: 5px;
        }
    }
}

.orgDetails {
    padding: 20px 0;
    
    img {
        display: block;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        margin: 0px auto 20px;
        object-fit: fill;
    }

    label {
        display: block;
        margin: 10px auto 0px;
        text-align: center;
        font-size: 12px;
        color: var(--primary-color);
        filter: grayscale(70%);

        span {
            font-weight: 600;
        }
    }
}

.secondaryDetails {
    li {
        position: relative;
        display: flex;
        padding: 10px;
        min-height: 40px;
        align-items: center;
        justify-content: space-between;
        margin: 5px 0;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            
            @include variableThemeBackground(0.2, 2, 0.5);
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;

            &.pallete {
                &::before {
                    display: none;
                }

                svg {
                    fill: white;
                }
            }

            &.primary {
                background-color: var(--primary-color);
            }

            &.secondary {
                background-color: var(--contrast-color);
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                
                @include variableThemeBackground(0.2, 1.8, 0.5);
            }

            svg {
                width: 50%;
                height: 50%;
                position: relative;
                fill: var(--primary-color);
                filter: grayscale(70%);
            }
        }

        .data {
            position: relative;
            width: calc(100% - 50px);
            color: var(--primary-color);
            filter: grayscale(70%);

            label {
                font-size: 11px;
                display: block;
            }

            span {
                display: block;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
}

.locationInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin: 20px 0 -15px;

    &>div {
        width: 0;
        flex-grow: 1;

        p {
            font-size: 12px;
            color: nth($grey-shades, 4);
            font-style: italic;
            padding-left: 20px;
        }
    }

    .inputHolder {
        position: relative;
    }

    label {
        margin-left: 10px;
        margin-bottom: 7px;
        display: block;
        font-size: 11px;
        color: var(--primary-color);
        position: absolute;
        left: 0;
        top: -22px;
    }
}

.phoneInput {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px;
    padding-top: 20px;

    .inputHolder {
        margin: 0;
        position: relative;
        
        &:first-child {
            width: 100px;
        }

        &:last-child {
            width: calc(100% - 110px);
        }
    }

    label {
        margin-left: 10px;
        margin-bottom: 7px;
        display: block;
        font-size: 11px;
        color: var(--primary-color);
        position: absolute;
        left: 0;
        top: -22px;
    }
}

.inputHolder {
    margin: 15px;

    label {
        margin-left: 10px;
        margin-bottom: 7px;
        display: block;
        font-size: 11px;
        color: var(--primary-color);
    }

    [class*="inputHolder"] {
        border-width: 3px;
    }

    &.colorPalette {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: 40px;

        &>section {
            width: calc(100% - 45px);
        }

        [class*="inputHolder"] {
            border-width: 3px;            
        }

        label {
            position: absolute;
            left: 0;
            top: -22px;
        }

        .colorSample {
            width: 35px;
            height: 35px;
            background-color: transparent;
            border-radius: 50%;

            &.primary {
                background-color: var(--primary-color);
            }

            &.secondary {
                background-color: var(--contrast-color);
            }
        }
    }
}

.themeSet {
    display: grid;
    justify-content: space-between;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, 25px);
    padding: 5px;
    border: 3px solid rgba(var(--primary-color-rgb), 0.3);
    background-color: white;
    border-radius: 20px;
    opacity: 0.8;
    filter: grayscale(30%);
    
    li {
        display: flex;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;

        &.active {

        }

        span {
            display: block;
            width: 50%;
        }
    }
}

.profileImageInput {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    input[type=file] {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
    }

    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: contain;
        background-color: white;
        border: 2px solid rgba(var(--primary-color-rgb), 0.5);
        overflow: hidden;
    }

    label {
        button {
            pointer-events: none;
        }

        &:hover {
            button {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}

.logout {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    padding: 10px 0;
    overflow: hidden;
    background-color: transparent;    
    
    button {
        position: relative;
    }
}

.apiLink {
    text-align: center;
    font-size: 12px;
    margin: 25px 0;
    text-decoration: underline;
    color: var(--primary-color);
    cursor: pointer;
}