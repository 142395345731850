@import '../../../colors';
@import './pieces.scss';

.expandedPiece {
    z-index: 2;
}

.nextPieceHoverable {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 20px;
    pointer-events: all;
}

.nextPieceHovering {
    composes: nextPieceHoverable;
    background-color: rgba(0, 0, 0, 0.4);
}

.splitPieceComponentsHolder {
    position: relative;
}

.splitPieceHolder {
    position: relative;
}

.splitPieceLines {
    position: relative;
    z-index: 3;
}

.lowerArmUpperPieceLock {
    background: $flowchart-aqua;
    border-top: 1px solid $flowchart-aqua-dark;
    border-left: 1px solid $flowchart-aqua-dark;
}

;

.ifExpandButton {
    display: inline-block;
    background: $flowchart-aqua-dark;
    padding: 3px 8px;
    color: white;
    font-size: 9px;
    cursor: pointer;
    pointer-events: all !important;
}

;

.ifExpandButton {
    display: inline-block;
    background: $flowchart-aqua-dark;
    padding: 3px 8px;
    color: white;
    font-size: 9px;
    cursor: pointer;
    pointer-events: all !important;
}

.splitLineDrop {
    position: absolute;
    left: 60px;
    width: 2px;
    height: 30px;
    background-color: silver;
}

.splitPieceCircle {
    position: absolute;
    top: -4px;
    left: -3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: darken($flowchart-aqua-dark, 5%);
}

.splitLineHorizontal {
    position: absolute;
    top: 30px;
    height: 2px;
    background-color: silver;
}

.ifLineDrop {
    position: absolute;
    left: 45px;
    width: 2px;
    background-color: silver;
}

.ifPieceCircle {
    position: absolute;
    bottom: -4px;
    left: -3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: darken($flowchart-aqua-dark, 5%);
}

.splitPiece {
    display: inline-flex;
    align-items: center;

    position: relative;
    min-width: 137px;
    width: fit-content;
    height: 56px;
    min-width: 120px;
    height: 49px;
    background: url(../../../assets/flowchart/split.svg);
    background-size: 120px 49px;
    background-repeat: no-repeat;
    padding: 17px 10px;

    @include cursor-for-pieces();

    &::before {
        content: "";
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 60px;
        right: 0;
        z-index: -1;
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-left-color: transparent;
        border-radius: 4px;
    }
}

.splitText {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 10px;
}

.ifPiecesHolder {
    position: relative;
}

.ifPiece {
    position: absolute;
}

.shadowedIfPiece {
    composes: ifPiece;
    opacity: 0.5;
}

.selectedIfPiece {
    composes: ifPiece;
    z-index: 1;
}

.highlightedIfPiece {
    composes: selectedIfPiece;
    filter: drop-shadow(4px -4px 0px #fff4a1) drop-shadow(-4px 4px 0px #fff4a1) drop-shadow(-4px -4px 0px #fff4a1) drop-shadow(4px 4px 0px #fff4a1);
}

.booleanIndicator {
    position: relative;
    width: 40px;
    height: 30px;
    margin: 0 10px;
    background-color: darken($flowchart-aqua-dark, 5%);

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0px;
        height: 0px;
        border-width: 15px 10px;
        border-color: transparent;
        border-style: solid;
    }

    &::before {
        left: -20px;
        border-right-color: darken($flowchart-aqua-dark, 5%);
    }

    &::after {
        right: -20px;
        border-left-color: darken($flowchart-aqua-dark, 5%);
    }
}

.booleanIndicatorHovering {
    composes: booleanIndicator;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

    &::before {
        border-right-color: rgba(0, 0, 0, 0.4);
    }

    &::after {
        border-left-color: rgba(0, 0, 0, 0.4);
    }
}

.noHighlight {
    position: relative;
    z-index: 1;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 1;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 1;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 1;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 1;
}