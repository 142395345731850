@import '../../../colors';

@mixin cursor-for-pieces() {
    cursor: grab;

    div[class*="react-drag-disabled"] & {
        cursor: initial;
    }

    div[class*="react-draggable-dragging"] &,
    &[class*="react-draggable-dragging"] {
        cursor: grabbing;
    }
}

.highlightSelected {
    position: relative;
    filter: drop-shadow(0 2px 5px var(--contrast-color));
    z-index: 10;
}

;

.removeHighlight {
    z-index: 1;
}

;

.actionButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .expandButton {
        margin: 7px 10px 7px 0;
        border-radius: 7px;
        display: inline-block;
        background: $flowchart-aqua-dark;
        padding: 3px 8px;
        color: white;
        font-size: 9px;
        cursor: pointer;
        pointer-events: all !important;

        &:nth-child(2) {
            min-width: 95px;
        }

        span {
            display: flex;
            align-items: center;
            font-size: 10px;
        }

        img {
            width: 12px;
            margin-right: 5px
        }
    }

    ;
}