@import '../../common/styles/colors.scss';
@import '../../theme';

.inputHolder {
    position: relative;
    border: none;
    border-radius: 30px;
    border: 1px solid rgba(var(--primary-color-rgb), 0.3);
    background-color: white;
    display: flex;
    align-items: center;
    height: 35px;
    transition: border-color 0.3s;
    justify-content: space-between;
    padding: 0 1px 0px 5px;


    &.active {
        border-color: rgba(var(--primary-color-rgb), 0.5);
        border-width: 3px;

        button[class*="CommonButton"] {
            transform: scale(0.7) rotate(180deg);
            opacity: 1;
        }
    }

    &.booleanInput {
        cursor: pointer;
    }

    &.calendarActive {
        border-color: rgba(var(--primary-color-rgb), 0.5);
        z-index: 1;
        border-width: 3px;

        &>button[class*="CommonButton"] {
            transform: scale(0.7) rotate(180deg);
            opacity: 1;
        }


        .selectedOptionText {
            font-size: 12px;
        }

        .placeholder {
            font-size: 12px;
        }
    }

    &.erroneous {
        box-shadow: 0px 0px 1px 1px $danger;
    }

    &.isHighlighted {
        box-shadow: 0 0 7px 0 var(--contrast-color);
    }

    &.disableInput {
        cursor: not-allowed;

        input,
        textarea {
            pointer-events: none;
        }
    }

    &.showDoneButton {
        width: calc(100% - 70px);
        border-width: 0px;
        padding-top: 2px;

        .doneButton {
            position: absolute;
            right: -62px;
            top: 5px;

            button {
                background-color: white;
            }
        }
    }

    &>button[class*="CommonButton"] {
        transition: transform 0.3s, opacity 0.3s;
        transform: scale(0.7);
        background-color: #95a0ab;
        border-color: #95a0ab;
        top: -1px;

        &:focus {
            background-color: var(--contrast-color);
        }
    }

    .placeholder  {
        font-size: 13px;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: rgba(var(--primary-color-rgb), 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        width: auto;
        max-width: 50%;
        filter: grayscale(70%);
        top: -1px;
        position: relative;
        cursor: pointer;

        &.shrink {
            font-size: 11px;
        }
    }

    &.isRequired {
        .placeholder {
            color: rgba(var(--primary-color-rgb), 0.7);
            font-weight: bold;
        }
    }

    &>input {
        display: block;
        width: 0px;
        flex-grow: 1;
        padding: 0 10px;
        border: none;
        box-shadow: none;
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: var(--primary-color);
        background-color: white;
        border-radius: 30px;
        align-self: stretch;
        filter: grayscale(70%);
        height: 100%;
        margin: 0;

        &[type=number] {
            margin-right: 5px;
        }
        &[type=file] {
            display: none;
        }

        &[readonly] {
            cursor: pointer;
        }

        &[disabled] {
            cursor: not-allowed;
        }

        &:active,
        &:focus {
            outline: none;
        }
    }

    &>textarea {
        display: block;
        width: 0px;
        flex-grow: 1;
        padding: 0 10px;
        border: none;
        box-shadow: none;
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: var(--primary-color);
        background-color: white;
        border-radius: 30px;
        align-self: stretch;
        filter: grayscale(70%);
        height: 100%;
        margin: 0;
        resize: none;

        &[readonly] {
            cursor: pointer;
        }

        &[disabled] {
            cursor: not-allowed;
        }

        &:active,
        &:focus {
            outline: none;
        }
    }
}

.textAreaHolder {
    composes: inputHolder;
    height: 100px;
    padding: 10px;
    resize: none;
}

.hiddenFile {
    display: none;
}

.selectedOptionText {
    width: 0px;
    padding: 0 10px;
    border: none;
    flex-grow: 1;
    box-shadow: none;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: var(--primary-color);
    background-color: white;
    border-radius: 30px;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    filter: grayscale(70%);
    height: 100%;
    padding-top: 8px;
    cursor: pointer;

    user-select: none;
}

.fileLabel {
    composes: selectedOptionText;
    text-decoration: underline;
}

.disabledSelectedOptionText {
    composes: selectedOptionText;
    cursor: not-allowed;
}

.icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    pointer-events: none;
}

.rotated {
    transform: rotate(180deg);
}

.toggleableIcon {
    composes: icon;
    pointer-events: all;
}

.booleanInputHolder {
    margin-left: 10px;
    margin-right: 5px;
}

.calendarInputHolder {
    .inputHolder {
        position: relative;
        z-index: 1;
    }

    &:hover {
        .modifyDates {
            height: 40px;
            opacity: 1;
        }
    }
}

.calenderWrapper {
    width: 100%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    z-index: 0;
    padding: 30px 10px 0px 10px;
    margin: -32px auto 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        pointer-events: none;
        filter: contrast(0.05) brightness(2) grayscale(0.5);
        box-shadow: 0px 0px 10px -2px rgba(var(--primary-color-rgb), 0.3);
    }

}

.modifyDates {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    // height: 40px;  
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: height 0.3s, opacity 0.3s;

    li {
        list-style: none;
        font-size: 11px;
        display: flex;
        align-items: center;
        font-weight: 600;
        color: var(--primary-color);

        .currentDay {
            width: 60px;
            border-radius: 30px;
            margin: 0;
            transform: scale(100%);

            &:hover {
                transform: scale(110%);
                transition: all 0.1s ease-in;
            }
        }
    }

    button {
        margin: 0 5px;
        width: 23px;
        height: 23px;
        border: 1px solid transparent;
        border-radius: 50%;
        background-color: rgba(var(--primary-color-rgb), 0.2);
        cursor: pointer;
        transform: scale(100%);
        transition: transform 0.1s ease-in;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: var(--primary-color);
        font-size: 11px;


        svg {
            width: 80%;
            height: 80%;
            margin: 0 -6px;
            fill: var(--primary-color);
        }

        &:hover {
            transform: scale(120%);
            transition: all 0.1s ease-in;
        }
    }
}

.calendarUI {
    position: relative;
    display: flex;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;


        @include variableThemeBackground(0.05, 2, 0.5);
    }

    &>* {
        position: relative;
        user-select: none;
    }

    .segments {
        width: 30%;

        button {
            height: 35px;
            border: 1px solid var(--primary-color);
            border-radius: 20px;
            width: 100%;
            font-size: 12px;
            font-weight: 600;
            color: var(--primary-color);
            margin: 10px auto;
            display: block;
            cursor: pointer;
            background-color: transparent;
            position: relative;
            overflow: visible;

            &.active {
                background-color: rgba(var(--primary-color-rgb), 0.2);
                border-color: transparent;
            }
        }
    }

    .valueSet {
        width: 70%;
        padding: 10px 0px 10px 15px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
        grid-gap: 5px;
        max-height: 180px;
        overflow: auto;

        &.dates {
            grid-template-columns: repeat(auto-fit, 30px);
        }

        .date {
            cursor: pointer;
            color: var(--primary-color);
            font-size: 12px;
            font-weight: 600;
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            &.active {
                background-color: var(--primary-color);
                color: white;
            }
        }

        .year,
        .month {
            cursor: pointer;
            color: var(--primary-color);
            font-size: 12px;
            font-weight: 600;
            padding: 0 10px;
            margin: 0;
            height: 30px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
                background-color: var(--primary-color);
                color: white;
            }
        }

    }
}

.multiSelect {
    display: block;
}

.weekchange {
    display: none;
}