@import '../../../common/styles/colors.scss';
@import '../../../theme.scss';

.marketPage {
    position: relative;
    overflow: auto;
    height: 100vh;
    width: 100vw;
}

.logo {
    cursor: pointer;
    width: 200px;
    margin: 20px;
}

.pageHeading {
    width: 50%;
    margin: 20px auto 50px;

    h1 {
        font-size: 20px;
        font-weight: bold;
        filter: grayscale(50%);
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }
    
    p {
        line-height: 2;
        font-size: 13px;
        text-align: center;
        color: var(--primary-color);
        opacity: 0.7;
        filter: grayscale(50%);
    }

    .searchInputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        margin-top: 30px;
        
        button {
            margin: 0;
        }
    }

    .searchInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;        
        box-shadow: 0px 0px 15px -4px nth($grey-shades, 3);
        padding: 0px 10px;
        border-radius: 30px;
        height: 45px;
        margin: 0px 20px;

        .icon {
            margin-left: 7px;

            svg {
                width: 15px;
                height: 15px;
                fill: var(--primary-color);
            }
        }

        input {
            border: none;
            flex-grow: 1;
            font-size: 13px;
            font-weight: 600;
            padding-left: 15px;
            color: nth($grey-shades, 4);
            height: 100%;

            &::placeholder {
                color: nth($grey-shades, 3);
                font-weight: 500;
                opacity: 0.7;
            }
        }

        button {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: none;
            background-color: nth($grey-shades, 4);
            opacity: 0.7;
            cursor: pointer;

            svg {
                fill: white;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px;
}

.addPackageForm {
    width: 50%;
    margin: 0 auto;

    h4 {
        text-align: left;
        font-size: 18px;
        color: var(--primary-color);
        margin-bottom: 20px;
        filter: grayscale(70%);
    }

    .input {
        display: block;
        flex-basis: 75%;
        flex-grow: 1;
        background-color: white;
        color: nth($grey-shades, 4);
        border-radius: 2px;
        border: 2px solid transparent;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        padding: 5px 20px;
        font-size: 16px;

        &:focus, &:active {
            outline: none;
        }
    }

    .formSegment {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 15px;
        margin-bottom: 50px;
    }

    .formButtonsHolder {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin: 0 10px;
        }
    }
}