@import '../../colors';

.addForm, .modifyForm {
    div[class*="inputHolder"] {
        margin-bottom: 15px;
    }
}

.errorMessage {
    color: $sunglo;
    font-size: 12px;
    text-align: center;
}

.buttonsHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    button {
        flex-basis: calc(50% - 5px);
    }
}

.formButton {
    display: block;
    width: 45%;
    padding: 7px;
    border: none;
    margin-top: -8px;
    box-shadow: none;
    
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    height: 30px;
    line-height: 16px;
    
    &:focus, &:active {
        outline: none;
    }
}

.submitFormButton {
    composes: formButton;
    background-color: $pink;
}

.cancelFormButton {
    composes: formButton;
    background-color: white;
    border: 2px solid $danger;
    color: $danger;

    &.hide {
        display: none;
    }
}

.confirmFormButton {
    composes: formButton;
    background-color: $pastel-green;
    border-radius: 30px;
    margin: 0;
    width: 100%;
}