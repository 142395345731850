@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.innerFocus {
    @extend %CommonInnerFocusSpace;

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.timelineButtonHolder {
    button {
        svg {
            stroke: white;
            fill: white;
        }
    }
}

.groupHeading {
    font-size: 18px;
    color: var(--primary-color);
    padding: 10px 15px;
    text-align: center;
    filter: grayscale(50%);

    span {
        font-weight: 700;
    }
}


.navButtonsHolder {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 60px;
    background-color: white;
    box-shadow: 0px -5px 10px -3px nth($grey-shades, 2);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;

    button {
        margin: 0;
    }
}

.displayWindow {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
}

.progressHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: calc(100% - 20px);
    margin: 10px auto;

    .progressBar {
        flex-grow: 1;
        height: 10px;
        background-color: white;
        position: relative;
        z-index: 0;
        border-radius: 10px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            @extend %ThemeLightBackground;
        }

        .progress {
            z-index: 1;
            position: absolute;
            width: 0%;
            height: 100%;
            transition: width 0.3s;
            border-radius: inherit;
            background-color: var(--primary-color);
        }
    }

    .text {
        margin-left: 10px;
        font-size: 14px;
        color: var(--primary-color);
        filter: grayscale(50%);
    }

}

.sectionHeading {
    font-size: 17px;
    color: var(--primary-color);
    filter: grayscale(50%);
    text-align: center;
    margin: 20px auto;

    span {
        font-weight: 600;
    }
}

.showDataContainer {
    font-size: 14px;
    color: var(--primary-color);
    line-height: 1.5;
    padding: 15px 20px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin: 20px auto;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &>div,
    &>span {
        width: calc(100% - 40px);

    }

    .iconHolder {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        align-self: flex-start;
        justify-content: center;
        background-color: white;
        border-radius: 50%;

        svg {
            width: 16px;
            height: 16px;
            fill: var(--primary-color);
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    &>* {
        position: relative;
        font-weight: 600;
        // filter: grayscale(50%);
    }

    &>*:last-child {
        flex-grow: 1;
    }
}

.nextWorkflowContainer {
    position: relative;
    font-size: 14px;
    color: var(--primary-color);
    line-height: 1.5;
    padding: 15px 20px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin: 20px auto;
    width: calc(100% - 20px);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    &>* {
        position: relative;
    }

    .nextWorkflowTypeName {
        font-size: 18px;
        font-weight: bold;
    }

    .nextWorkflowDetails {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .separator {
        margin: 0 7px;
        font-size: 20px;
    }

    .metaValue {
        font-size: 13px;
    }
}

.displayContents {
    height: calc(100% - 20px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    max-width: calc(100% - 100px);
    flex-grow: 1;
    background-color: white;
    border-radius: 25px;
    margin: 10px;
    width: 70%;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
}

.goToButton {
    text-align: center;
}

.flowHeader {
    background-color: white;
    width: calc(100% - 20px);
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    padding: 20px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        margin: 0;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    &>* {
        position: relative;
    }

    .heading {
        display: block;
        font-size: 18px;

        svg[class*="beta-icon"] {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            overflow: hidden;
            display: inline;
            fill: white;
            background: var(--primary-color);
            border-radius: 3px;
            padding: 2px;
        }

        .status {
            font-size: 13px;
            color: var(--primary-color);
            position: relative;
            text-align: left;
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .separator {
                margin: 0 10px;
                font-size: 20px;
            }
        }
    }
}

.showImage {
    display: block;
    max-width: 100%;
    max-height: 70vh;
    margin: 0 auto;
}

.showVideo {
    display: block;
    max-width: 100%;
    max-height: 70vh;
    margin: 0 auto;
}

.downloadLink {
    text-decoration: underline;
    color: var(--contrast-color);
}

.promptText {
    display: flex;
    width: 70%;
    margin: 15px auto;
    font-size: 15px;
    color: var(--primary-color);
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.finsalDataContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 30px;
}

.finsalHolder {
    width: 50%;
    margin: 20px auto;

    iframe {
        display: block;
        width: 100%;
        height: 70vh;
        margin: 0 auto;
    }
}

.promptButton {
    display: block;
    width: 250px;
    text-align: center;
    margin: 15px auto;
    background-color: transparent;
    border: 2px solid var(--contrast-color);
    border-radius: 20px;
    padding: 10px 18px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.showTableHolder {
    width: 550px;
    margin: 0 auto;
}