@import '../../../colors';
@import '../../../theme';

.tableHolder {
    margin: 0 20px;
    height: 100%;
    overflow: auto;
    color: $grey;
    font-size: 13px;
    box-shadow: 0px 0px 0px $grey;
    border: 1px solid $light-grey;
    transition: box-shadow 0.2s, transform 0.2s;
    transform: translateY(0px);
    border-radius: 10px;

    table {
        table-layout: fixed;
        border-collapse: collapse;
    }

    tr {
        &:nth-child(2n) {
            background-color: lighten($light-grey, 5%);
        }

        td {
            &:nth-child(1) {
                width: 19%; 
            }

            &:nth-child(2), &:nth-child(3), &:nth-child(4) {
               width: 27%; 
            }
        }
    }

    th {
        color: lighten($grey, 10%);
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        padding: 20px 10px;
    }


    td {
        color: darken($grey, 5%);
        font-weight: 500;
        font-size: 13px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        padding: 10px;
    }
}

.table {
    background-color: white;
}