@import '../../colors';

.inputHolder {
    position: relative;
}

.input {
    display: block;
    background-color: white;
    border-radius: 12px;
    min-width: 35px;
    height: 24px;
    border: 2px solid transparent;
    box-shadow: none;
    padding: 3px 5px;
    text-align: center;
    pointer-events: all;

    [class*="disabledFlowchartHolder"] & {
        pointer-events: none;
    }

    &:focus, &:active {
        outline: none;
    }
}

.erroneousInput {
    composes: input;
    box-shadow: 0px 0px 0px 1px red;
    background-color: rgb(255, 230, 230);
}

.dragReceivingInput {
    composes: input;
    box-shadow: inset 0 0px 4px 1px black;
    border-color: rgba(0, 0, 0, 0.7);
}

.errorMessage {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
    color: black;
    white-space: nowrap;
    font-size: 9px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -10px;
        left: calc(50% - 5px);
        border: 5px solid transparent;
        border-top-color: white;
    }
}
