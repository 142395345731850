@import '../../../common/styles/colors.scss';
@import '../../../theme';

.showDataContainer {
    padding: 0px 10px;
    margin: 0;
    background-color: white;
    position: relative;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;

    @extend %CommonCard;

    .dataContainer {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
        z-index: 0;

        &.blur {
            filter: blur(8px);
            -webkit-filter: blur(8px);
        }
    }

    &.restrictedWidth {
        width: calc((100vw - 300px) / 5);
        max-width: none;
        margin: 0 20px;

        &:hover {
            .aggregationHeader {
                -webkit-line-clamp: unset;
                overflow-y: auto;
            }
        }

        .dataContainer {
            border-top: 0px solid var(--primary-color);
        }
    }

    .tableHolder {
        width: 100%;
        overflow: auto;
    }

    section[class*="table"] {
        min-width: 100%;
        margin: 0;
        flex-grow: 1;


        section[class*="tableBody"] {
            border: 1px solid lighten(nth($grey-shades, 2), 2%);
            border-radius: 20px;
            overflow: auto;
            width: 100%;
        }
    }
}


.showHeader {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: flex-end;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    padding: 0px 10px 0px 20px;
    margin: 10px 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @extend %ThemeLightBackground;
    }

    .showHeading {
        flex-grow: 1;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: darken(nth($grey-shades, 4), 10%);
        margin-right: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: opacity 0.5s;

        &.hide {
            opacity: 0;
        }

        .heading {
            width: calc(100% - 30px);

            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                font-weight: 700;
            }

            span {
                display: block;
                font-size: 10px;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: var(--primary-color);
            margin-right: 10px;
        }
    }

    .actionButtonsContainer {
        position: relative;
        transition: opacity 0.5s;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        &.hide {
            opacity: 0;
        }

        button {
            margin-left: 15px;
        }
    }

    .moreOptionsHolder {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s, transform 0.5s;
        transform: translateX(100%);
        overflow: hidden;

        &.active {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0%);
        }
    }

    .moreOptions {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        list-style: none;
        border-radius: 7px;
        display: flex;
        background-color: transparent;
        align-items: center;
        justify-content: flex-end;
        transition: transform 0.3s;

        button {
            margin-right: 5px;
        }
    }
}

.widgetHeaderSegment {
    width: 100%;
    margin-bottom: 10px;

    .searchBar {
        flex-grow: 1;
        display: flex;
        box-shadow: 0px 0px 15px -8px var(--primary-color) inset;
        border-radius: 30px;
        padding: 10px;

        &>section {
            width: 100%;

            div[class*="inputHolder"] {
                margin: 0;
                width: 100%;
                border-radius: 30px;
                height: 35px;

                input {
                    border-radius: 20px;
                    font-size: 12px;
                }
            }
        }
    }

    .dataFormats {
        display: flex;
        box-shadow: 0px 0px 15px -8px var(--primary-color) inset;
        border-radius: 24px;
        height: 45px;
        padding: 5px;
    }

    .highlightedDataFormats {
        display: flex;
        box-shadow: 0px 0px 15px -8px var(--primary-color) inset,
            0 0 7px 0 var(--contrast-color);
        border-radius: 24px;
        height: 45px;
        padding: 5px;
    }


    .format {
        width: calc(100% / 4);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 20px;
        background-color: transparent;
        flex-grow: 1;

        svg {
            fill: var(--primary-color);
            width: 15px;
            height: 15px;
            margin-right: 10px;
            transition: fill 0.3s;
        }

        span {
            color: var(--primary-color);
            font-weight: 600;
            font-size: 12px;
            transition: color 0.3s;
        }

        &.activeFormat {
            background-color: var(--primary-color);
            box-shadow: 0px 3px 8px var(--primary-color);

            svg {
                fill: white;
            }

            span {
                color: white;
            }
        }
    }
}


.pageActions {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    position: sticky;
    bottom: 0;
    padding: 15px 25px;
    background-color: white;

    .pageSize {
        position: relative;
        margin-left: auto;

        div {
            display: inline-block;
            vertical-align: middle;
            color: var(--primary-color);
            font-size: 14px;
            font-weight: 600;
            opacity: 0.8;
            margin-right: 10px;
        }

        input {
            width: 30px;
            font-size: 14px;
            color: nth($grey-shades, 4);
            height: 30px;
            text-align: center;
            border: none;
            background-color: white;
            box-shadow: 0px 0px 5px inset var(--primary-color);
            font-weight: 600;
            border-radius: 5px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            border-radius: 30px;
        }

        button {
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &.decreaseSize {
                transform: rotate(90deg);
            }

            &.increaseSize {
                transform: rotate(-90deg);
                margin-left: 7px;
            }

            &.prevPage,
            &.nextPage {
                width: 25px;
                height: 25px;
                border: 1px solid var(--primary-color);
                border-radius: 50%;

                svg {
                    width: 50%;
                }
            }

            &.prevPage {
                transform: rotate(-180deg);
                margin-right: 7px;
            }

            &.nextPage {
                transform: rotate(0deg);
            }

            svg {
                stroke: var(--primary-color);
                width: 80%;
                height: 100%;
            }
        }
    }

}

.pageLink {
    width: 30px;
    height: 30px;
    line-height: 30px;

    font-size: 14px;
    text-align: center;
    border: 1px solid lighten(nth($grey-shades, 3), 5%);
    color: darken(nth($grey-shades, 3), 20%);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: border-color 0.3s, color 0.3s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        color: var(--primary-color);
        border-color: var(--primary-color);
    }

    &:last-child {
        margin-right: 0;
    }
}

.pageNavButton {
    width: 30px;
    height: 30px;
    padding: 8px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 14px;
        height: 14px;
        fill: nth($grey-shades, 4);
        transition: fill 0.3s;
    }

    &:hover {
        background-color: var(--contrast-color);

        svg {
            fill: white;
        }
    }

}

.activePageLink {
    composes: pageLink;
    color: white;
    background-color: var(--primary-color);
    border: none;

    &:hover {
        color: white;
    }
}

.submit {
    display: block;
    width: 160px;
    margin: 30px auto;

    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 11px 0;

    text-align: center;
    color: white;
    background-color: var(--contrast-color);
    cursor: pointer;
}

.downloadButtonsHolder {
    padding-top: 15px;
    text-align: right;
    margin-top: 20px;
    border-top: 2px solid nth($grey-shades, 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
        background-color: transparent;
        color: var(--contrast-color);
        border: 2px solid var(--contrast-color);
        font-size: 14px;
        padding: 0 7px;
        height: 40px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background-color: var(--contrast-color);
            color: white;

            svg {
                fill: white;
                stroke: white;
            }
        }

        &.share {
            background-color: nth($grey-shades, 2);
            border: 0px;
            color: var(--primary-color);
            padding: 0 15px;

            &:hover {
                background-color: var(--primary-color);
                color: white;

                svg {
                    fill: white;
                    stroke: white;
                }
            }

            svg {
                fill: var(--primary-color);
                stroke: var(--primary-color);
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: var(--contrast-color);
            stroke: var(--contrast-color);

            transition: stroke 0.3s, fill 0.3s;
        }

        span {
            margin-left: 7px;
        }
    }
}

.oneDownloadButtonHolder {
    composes: downloadButtonsHolder;
    justify-content: flex-end;
}

.showModifyButton {
    padding: 8px 15px;
    border-radius: 800px;
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    svg {
        margin-top: 6px;
        margin-right: 5px;
        width: auto;
        height: 12px;
        fill: white;
        stroke: white;
    }
}

.updateButton {
    composes: showModifyButton;
    background-color: var(--contrast-color);
    color: white;
}

.deleteButton {
    composes: showModifyButton;
    background-color: nth($grey-shades, 3);
    color: white;
}

.numberHolder {
    margin-top: -35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .content {
        padding-left: 10px;
        width: calc(100% - 55px);
    }

    .icon {
        width: 55px;
        height: 55px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: var(--primary-color);
            opacity: 0.2;
        }

        svg {
            width: 30px;
            height: 30px;
            fill: var(--primary-color);
        }
    }

    p {
        font-size: 24px;
        font-weight: bold;
        color: nth($grey-shades, 4);
    }

    .aggregationHeader {
        font-size: 14px;
        color: nth($grey-shades, 3);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 36px;
        transition: height 1s;
    }
}

.warningHolder {
    text-align: center;
    font-size: 12px;
    color: nth($grey-shades, 3);
    font-size: 13px;
    padding-bottom: 10px;
}

.dataContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
}

.normalTable {
    width: 100%;
    height: calc(100% - 90px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.chartContainer {
    display: flex;
    justify-content: stretch;
    width: 100%;
    margin: 15px auto;
    height: 100%;
    min-height: 282px;
    border-radius: 30px;
    // overflow: unset !important;

    &.donut {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &.draggable {
        cursor: grab;
    }

    &.showShadow {
        box-shadow: -10px 0px 10px -8px inset nth($grey-shades, 2);
    }

    div[class*="ct-chart"] {
        width: 60%;
    }

    .chartLegends {
        list-style: none;
        width: 40%;
        display: block;
        height: 280px;
        overflow: auto;

        &:hover {
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }
        }

        li {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
        }

        label {
            font-size: 11px;
            color: var(--primary-color);
            font-weight: 700;
            margin-left: 10px;
            display: block;
            width: calc(100% - 50px);

            span {
                display: block;
                font-size: 11px;
                font-weight: 500;
            }
        }

        .progress {
            display: block;
            width: 35px;
            height: 35px;
            overflow: hidden;
            color: #e6e6e6;
        }
    }
}

.chartLegendsForBar {
    list-style: none;
    display: flex;
    overflow: auto;
    padding: 3px;
    justify-content: space-around;
    flex-wrap: wrap;

    li {
        flex-basis: 33.33%;
        max-width: 33.33%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }

    label {
        font-size: 11px;
        color: var(--primary-color);
        font-weight: 700;
        margin-left: 10px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
            display: block;
            font-size: 11px;
            font-weight: 500;
        }
    }

    .indicator {
        width: 20px;
        min-width: 20px;
        height: 17px;
        border: 2px solid white;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    }
}

.noDataContainer {
    width: 100%;
    padding: 20px;
    text-align: center;
    color: silver;
    font-size: 22px;
    font-weight: bold;
}

.youtubeVideoHolder {
    margin: 20px auto;
}

.filterAndTagsHolder {
    position: absolute;
    top: 68px;
    left: 8px;
    right: 8px;
    bottom: 10px;
    border-radius: 30px;
    overflow: auto;
    z-index: 2;

    &.active {
        top: 108px;

        .filterHolder {
            .inputSegmentContainer {
                max-height: 31vh;
            }
        }
    }
}

.filterHolder {
    z-index: 1;
    position: relative;
    overflow: visible;
    padding: 20px 10px;

    .inputSegmentContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        position: relative;
        padding: 0 10px;
        overflow: auto;

        // max-height: 35vh;

        &:hover {
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;


        @extend %ThemeLightBackground;
    }


    .inputSegment {
        width: calc(50% - 2.5px);
        margin-bottom: 15px;
        position: relative;

        [class*="inputHolder"] {
            border-width: 3px;
            z-index: 1;
        }
    }

    .dateInputHolder {
        display: block;
        position: relative;
        width: 100%;
        margin-bottom: 15px;
        border-radius: 30px;
        padding: 5px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0px;
            height: 100%;
            background-color: rgba(var(--primary-color-rgb), 0.2);
            border-radius: 20px;
            width: 100%;
            pointer-events: none;
        }

        .inputSegment {
            width: 100%;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.tagsHolder {

    position: relative;
    width: 100%;

    &:hover {
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
    }


    .tag {
        border: none;
        display: inline-block;
        padding-left: 8px;
        padding-right: 4px;
        background-color: rgba(var(--primary-color-rgb), 0.6);
        filter: grayscale(30%);
        margin-right: 10px;
        border-radius: 30px;
        position: relative;
        font-weight: 600;
        color: white;
        font-size: 11px;
        margin-bottom: 10px;

        &.countTag {
            padding: 8px;
        }

        span {
            font-weight: 600;
            color: white;
            font-size: 11px;
        }

        button {
            margin: 0;
            // margin-left: 5px;
            background-color: white;
            border-width: 0px;
            display: inline-flex;
            vertical-align: middle;
            transform: scale(0.6);
            // display: none;

            svg {
                fill: rgba(var(--primary-color-rgb), 1);
            }
        }
    }
}