@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.innerSpace {
    padding: 10px 0;
    @extend %CommonInnerFocusSpace;
}