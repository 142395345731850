@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.hiddenFile {
    display: none;
}

.workflowsHolder {
    display: flex;
    height: calc(100% - 90px);
    margin-top: 25px;
}

.goToFlowchart {
    position: absolute;
    bottom: 5px;
    left: 50%;
    z-index: 2;
    display: block;
    cursor: pointer;

    button {
        background-color: var(--contrast-color);
        color: #fff;
    }
}

.exportFields {
    position: absolute;
    top: 20px;
    right: 30px;

    display: block;
    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 5px 0;
    min-width: 100px;
    flex-grow: 1;
    text-align: center;
    color: var(--contrast-color);
    border: 1px solid var(--contrast-color);
    cursor: pointer;

    svg {
        width: 15px;
        height: 15px;
        fill: $pink;
        stroke: $pink;
        transform: translateY(2px);
        margin-right: 3px;
    }
}

.allWorkflowTypes {
    position: sticky;
    left: 0;
    z-index: 2;
    margin-left: 20px;
    display: block;
    width: 330px;
    height: 100%;
    padding: 0px 0px 10px;
    background-color: white;
    border-radius: 30px;
    
    @extend %CommonCard;
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    margin-top: 0px;
    padding-top: 5px;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    display: block;

    .cardsTreeHolder {
        flex-wrap: wrap;
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        align-content: flex-start;
        margin-bottom: 20px;        
    }

    .treeHeader {
        display: block;
        margin: 0 auto 20px auto;
        padding: 0 10px;
        font-weight: 500;
        color: $grey;
    }
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}

.richTextDataHolder {
    position: relative;
    width: 620px;
    padding: 10px;
    margin: 0 auto 20px 10px;
    background-color: white;
    overflow: auto;
    max-height: 300px;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: space-between;

    @extend %CommonCard;

    .container {
        width: calc(100% - 50px);
    }

    &:hover {
        .editButton {
            opacity: 1;
        }
    }

    p, a, span {
        color: $dark-grey !important;
    }

    .placeholder {
        font-size: 16px;
        color: $grey;
        font-weight: 500;
        letter-spacing: 0.5px;
        opacity: 0.7;
    }
}

.importExportButtonHolder {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;

    button, label {
        margin-top: 20px;
    }
}