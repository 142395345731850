@import '../../../common/styles/colors.scss';
@import '../../../theme';

.sectionHeading {
    font-size: 17px;
    color: var(--primary-color);
    filter: grayscale(50%);
    margin: 20px 0;
    text-align: center;

    span {
        font-weight: 600;
    }
}

.transferBox {
    text-align: center;
    position: absolute;
    bottom: 10px;
    z-index: 2;
    left: calc(50% - 50px);

    button {
        margin: 0;
    }
}

.question {
    padding: 15px 20px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin: 20px auto;
    width: calc(100% - 20px);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    & > * {
        position: relative;
    }

    &.errorQuestion {
        box-shadow: 0px 0px 10px $danger;
    }
}

.errorText {
    font-size: 13px;
    color: $danger;
    text-align: left;
    margin-top: 10px;
    display: block;
}

.iconHolder {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: white;
    border-radius: 50%;

    svg {
        width: 12px;
        height: 12px;
        fill: var(--primary-color);
    }
}

.questionWords {
    flex-grow: 1;
}

.questionDetails {
    display: flex;
    align-items: center;
}

.questionText {
    font-size: 15px;
    font-weight: 500;
    color: var(--primary-color);
    filter: grayscale(50%);
    margin-bottom: 10px;

    .error {
        color: $danger;
        float: right;
    }
}

.choice {
    background-color: transparent;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    color: var(--primary-color);
    padding: 15px 20px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;

    &:focus {
        box-shadow: 0 0 2px var(--primary-color);
    }

    .choiceMainText {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        
        span {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 600;
        }
    }

    .choiceSubText {
        font-size: 12px;
        color: var(--primary-color);
        font-weight: 600;

        svg {
            width: 15px;
            height: 15px;
            fill: var(--primary-color);
            vertical-align: middle;
            margin-right: 5px;
        }

        .separator {
            margin: 0 10px;
        }
    }
}

.activeChoice {
    composes: choice;
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;

    .choiceMainText {
        color: white;
    }

    .choiceSubText {
        color: white;

        svg {
            fill: white;
        }
    }
}

.choicesList {
    flex-grow: 1;
}

.options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(var(--primary-color-rgb), 0.05);
    padding: 10px 10px 1px;
    border-radius: 30px;
}