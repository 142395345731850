@import '../../common/styles/colors.scss';
@import '../../theme.scss';

.listItem {
    margin-bottom: 15px;
    list-style: none;

    &:last-child {
        margin-bottom: 0;
    }
}

.normal {
    position: relative;
    border-radius: 30px;
    display: flex;
    width: 100%;
    overflow: hidden;
    height: 50px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.3s;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px lighten(nth($grey-shades, 3), 20%);
    padding: 5px;

    [data-react-beautiful-dnd-draggable] & {
        margin-bottom: 0;
    }

    &:hover {
        box-shadow: 0px 0px 6px 1px lighten(nth($grey-shades, 3), 5%);

        .actionButtonsColumn {
            opacity: 1;
            filter: grayscale(0%);
        }
    }

    .index {
        font-weight: 700;
        min-width: 40px;
        text-align: center;
        color: nth($grey-shades, 3);
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: nth($grey-shades, 2);
    }

    .details {
        flex-grow: 1;
        font-weight: 500;
        border-radius: 10px;
        letter-spacing: 0.5px;
        overflow: hidden;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        transition: opacity 0.2s, blur 0.2s;

        &.hide {
            opacity: 0.5;
            filter: blur(3px);
        }

        .title {
            width: 100%;
            padding-bottom: 3px;
            padding-left: 20px;
            padding-right: 20px;
            color: nth($grey-shades, 3);
            font-size: 11px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                font-weight: 800;
            }
        }

        .subTitle {
            width: 100%;
            padding-top: 3px;
            padding-left: 20px;
            padding-right: 20px;
            color: nth($grey-shades, 3);
            font-size: 11px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .moreOptionBtn {
        margin-right: 5px;

        &.hide {
            visibility: hidden;
        }
    }

    .moreOptionsHolder {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
        transform: translateX(100%);
        background-color: transparent;

        &.active {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0%);
        }
    }

    .moreOptions {
        position: relative;
        width: calc(100% - 10px);
        height: 100%;
        overflow: auto;
        list-style: none;
        border-radius: 7px;
        display: flex;
        background-color: transparent;
        align-items: center;
        justify-content: flex-end;
        transition: transform 0.3s;

        button {
            margin-right: 5px;
        }
    }

    .modify {
        width: 30px;
        height: 100%;
        display: none;
        background-color: white;
        font-weight: 500;
        border-radius: 7px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            background-color: var(--primary-color);
            opacity: 0.2;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
        }

        .more {
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 15px;
                height: 15px;
                fill: var(--primary-color);
            }
        }
    }

    .toggleHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 50px;
        background-color: transparent;
    }

    .triToggleHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 60px;
        height: 50px;
        background-color: transparent;
        padding-bottom: 10px;
    }

    .legend {
        margin: 5px 3px;
        font-size: 9px;
        color: nth($grey-shades, 4);
    }

    .iconHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 50px;
        background-color: transparent;
        color: darken(nth($grey-shades, 3), 10%);

        svg {
            width: auto;
            height: 20px;
            fill: nth($grey-shades, 3);
        }
    }

    .actionButton {
        margin-right: 5px;

        .duplicateButton {
            button {
                svg {
                    stroke: var(--primary-color);
                    fill: var(--primary-color);
                }
            }
        }

        .exportButton {
            @extend .duplicateButton;
        }

        .deleteButton {
            button {
                svg {
                    fill: $danger;
                    stroke: $danger;
                }
            }
        }
    }
}

.active {
    composes: normal;
    box-shadow: 0px 2px 10px -2px var(--primary-color);

    .index {
        background-color: var(--primary-color);
        color: white;
    }

    .details {

        .title {
            color: var(--primary-color);
        }

        .subTitle {
            color: var(--primary-color);
            opacity: 0.8;
        }
    }

    .moreOptionsHolder {
        box-shadow: none;
    }

    .modify {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .edit path {
            fill: var(--primary-color);
            stroke: var(--primary-color);
        }

        .delete path {
            fill: $danger;
            stroke: $danger;
        }

        .duplicate path {
            fill: var(--primary-color);
            stroke: var(--primary-color);
        }
    }
}

.deleting {
    composes: normal;
    background-color: lighten($danger, 20%);
    display: flex;

    .index {
        display: none;
    }

    .actionButtonsColumn {
        display: none;
    }

    .deletetitle {
        width: 100%;
        padding-bottom: 3px;
        color: $danger;
        font-size: 13px;
        text-align: center;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 50px;

        svg {
            width: 15px;
            height: auto;
            margin: 0 7px;
            fill: $danger;
        }
    }
}

.isHighlighted {
    box-shadow: 0 0 10px 0 var(--contrast-color);
}