@import '../../common/styles/colors.scss';
@import '../../theme.scss';

.backDrop {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: transparent;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s;
    transition-delay: 0.2s;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 40px;

    &.active {
        pointer-events: all;
        opacity: 1;
        transform: translateY(0vh);

        .modal {
            transform: translateY(0vh);
        }
    }

    &.outsideClickable {
        pointer-events: none;

        .modal {
            pointer-events: all;
        }
    }
}

.modal {
    min-width: 30vw;
    background-color: white;
    border-radius: 40px;
    box-shadow: 0px 0px 15px var(--primary-color);
    overflow: hidden;
    transform: translateY(-50vh);
    transition: transform 0.5s;
    transition-delay: 0.2s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px 0 10px;
    height: 80px;

    .progress {
        filter: grayscale(50%);
    }

    &.errorMessage {
        box-shadow: 0px 0px 15px $danger;

        header {
            .iconContainer svg {
                fill: $danger;
            }

            &::after {
                background-color: $danger;
                animation: none;
            }

            &::before {
                animation: none;
                width: 100%;
                background-color: $danger;
            }
        }
    }

    &.successMessage {
        box-shadow: 0px 0px 15px $success;

        header {
            .iconContainer svg {
                fill: $success;
            }

            &::after {
                background-color: $success;
                animation: none;
            }

            &::before {
                animation: none;
                width: 100%;
                background-color: $success;
            }
        }
    }

    header {
        position: relative;
        top: 0;
        width: 100%;
        display: flex;
        text-align: center;
        overflow: hidden;
        align-items: center;
        flex-grow: 1;

        .progressHolder {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            filter: grayscale(50%);
            margin-right: 10px;

            .progressCount {
                font-size: 12px;
                font-weight: 600;
                color: var(--primary-color);
            }

            .progress {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

        }

        .iconContainer {
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: stretch;
            z-index: 1;
            margin-right: 10px;

            svg {
                width: 60px;
                height: 60px;
                fill: var(--contrast-color);
            }
        }

        &.complete {
            .iconContainer {
                svg {
                    fill: $success;
                }
            }
        }

        h3 {
            filter: grayscale(30%);
            flex-grow: 1;
            font-size: 16px;
            font-weight: 400;
            color: var(--primary-color);
            font-weight: 600;
            margin: 0;
            position: relative;
            z-index: 1;
            text-align: left;
        }
    }

    .confirmationBox {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-left: 7px;
            border-radius: 50%;
            background-color: rgba(nth($grey-shades, 4), 0.2);
            border: none;
            position: relative;
            cursor: pointer;
            transition: background-color 0.3s;

            &:nth-child(1) {
                background-color: $success;
            }

            &:nth-child(2) {
                background-color: $danger;
            }

            &:hover {
                background-color: rgba(nth($grey-shades, 4), 0.5);
            }

            svg {
                fill: white;
                width: 40%;
                height: 40%;
            }
        }
    }

    .confirmationBoxWithText {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            width: 65px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-left: 7px;
            border-radius: 15px;
            background-color: rgba(nth($grey-shades, 4), 0.2);
            border: none;
            position: relative;
            cursor: pointer;
            transition: background-color 0.3s;

            span {
                color: white;
                padding-right: 5px;
            }

            &:nth-child(1) {
                background-color: $success;
            }

            &:nth-child(2) {
                background-color: $danger;
            }

            &:hover {
                background-color: rgba(nth($grey-shades, 4), 0.5);
            }

            svg {
                fill: white;
                width: 40%;
                height: 40%;
            }
        }
    }

    .exitModal {
        display: block;
        margin-left: 15px;

        button {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            border-radius: 50%;
            background-color: var(--contrast-color);
            border: none;
            position: relative;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: rgba(nth($grey-shades, 4), 0.5);
            }

            svg {
                fill: white;
                width: 40%;
                height: 40%;
            }
        }
    }
}

.indeterminate {
    margin: 0 10px;
    margin-left: 20px;

    .dot {
        width: 5px;
        height: 5px;
        background-color: var(--contrast-color);
        border-radius: 50%;
        display: inline-block;
        margin-right: 3px;
        vertical-align: middle;

        &:nth-child(1) {
            animation: moveDots 0.8s infinite;
        }

        &:nth-child(2) {
            animation: moveDots 0.8s infinite;
            animation-delay: 0.5s;
        }

        &:nth-child(3) {
            animation: moveDots 0.8s infinite;
            animation-delay: 1s;
        }
    }
}