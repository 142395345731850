@import '../../colors';
@import '../../theme';

.inputSegment {
    margin: 10px 0;
}

.freeTextImage {
    border: 1px solid rgba(var(--primary-color-rgb), 0.3);
    border-radius: 30px;
    margin-top: 10px;
    padding: 10px 15px;
    cursor: not-allowed;

    label {
        font-size: 13px;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: rgba(var(--primary-color-rgb), 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: auto;
        max-width: 50%;
        filter: grayscale(70%);
    }

    img {
        display: block;
    }
}

.locationInput {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div {
        width: calc(100% - 60px);

        .inputSegment {
            margin: 0;

            &:first-child {
                margin-bottom: 10px;
            }
        }
    }
}

.inputFile {
    position: relative;
    border: none;
    border-radius: 30px;
    border: 1px solid rgba(var(--primary-color-rgb), 0.3);
    background-color: white;
    display: flex;
    align-items: center;
    height: 35px;
    transition: border-color 0.3s;
    padding: 0 1px 0px 5px;

    .placeHolderText {
        font-size: 13px;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: rgba(var(--primary-color-rgb), 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        width: auto;
        max-width: 50%;
        filter: grayscale(70%);
        top: -1px;
        position: relative;
    }

    input[type="file"] {
        display: none;
    }

    label {
        background-color: var(--primary-color);
        border-radius: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        position: relative;
        margin-left: auto;
        margin-right: 5px;
        cursor: pointer;



        svg {
            width: 12px;
            height: 12px;
            fill: white;
        }

        &:hover {
            &::before {
                content: "";
                position: absolute;
                top: -3px;
                width: 100%;
                height: 100%;
                border-radius: 30px;
                border: 1px solid var(--primary-color);
                padding: 2px;
            }
        }
    }

    .defaultText {
        padding-left: 10px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 1;
        margin: 0 0 0 10px;
        color: var(--primary-color);
        max-width: 10rem;
    }

    .uploadedFile {
        text-decoration: underline;
        padding: 0 10px;
        cursor: pointer;
    }

    .fileName {
        display: flex;
        align-items: center;
        color: var(--primary-color);
        height: 27px;
        justify-content: space-between;
        max-width: 10rem;
        position: relative;
        margin: 0 0 0 10px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--primary-color);
            filter: contrast(0.1) brightness(1.95) grayscale(0.5);
            border-radius: 30px;

        }

        button[class*="CommonButton"] {
            transition: transform 0.3s, opacity 0.3s;
            transform: scale(0.7);
            border: none;
            background-color: white;
            top: -1px;

            svg {
                width: 13px;
                height: 13px;
                fill: var(--primary-color);
            }

            &:focus {
                background-color: var(--primary-color);

                svg {
                    fill: white
                }
            }
        }
    }
}