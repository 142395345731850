@import './colors.scss';

// Common Chart styles

.ct-chart {
    position: relative;
}

.ct-bar {
    stroke-linecap: round;
    stroke-width: 10px;
}

// .ct-slice-donut {
//     stroke-linecap: round;
// }

.ct-tooltip {
    position: absolute;
    display: inline-block;
    min-width: 5em;
    padding: 8px 10px;
    background: #383838;
    color: #fff;
    text-align: center;
    pointer-events: none;
    z-index: 100;
    transition: opacity .2s linear;
}

.ct-tooltip:before {
    position: absolute;
    bottom: -14px;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(251, 249, 228, 0);
    border-top-color: #383838;
    border-width: 7px;
    margin-left: -8px;
}

.ct-tooltip.hide {
    display: block;
    opacity: 0;
    visibility: hidden;
}

.ct-axis-title {
    fill: rgba(0, 0, 0, 0.6);
    font-size: 14px;
}

.ct-labels {
    .ct-label {
        padding-right: 5px;
        color: nth($grey-shades, 3);
        font-size: 10px;
        font-weight: 600;
        line-height: 1.5;
    }
}

.ct-series {

    .normal-bar & {
        .ct-bar {
            stroke-linecap: round;
            stroke-width: 8px !important;
            stroke: var(--primary-color);
            opacity: 0.5;
            transition: opacity 0.3s;
    
            &:hover{
                opacity: 1;
            }
        }
    
        .ct-line {
            stroke-width: 4px;
            stroke: var(--primary-color);
            opacity: 0.5;
            transition: opacity 0.3s;
    
            &:hover{
                opacity: 1;
            }
        }
        
        .ct-point {
            stroke: var(--primary-color);
            stroke-width: 10px;
        }
    }

    .grouped-bar & {

        .ct-bar {
            stroke-linecap: round;
            stroke-width: 8px !important;
        }
    
        .ct-line {
            stroke-width: 4px;
        }
        
        .ct-point {
            stroke-width: 10px;
        }
    
        @for $index from 1 to 11 {
            &:nth-child(#{$index}) {
    
                .ct-bar {
                    stroke: nth($grouped-chart-colors, $index);
                }
            
                .ct-line {
                    stroke: nth($grouped-chart-colors, $index);
                }
                
                .ct-point {
                    stroke: nth($grouped-chart-colors, $index);
                }
            }
        }
    
        @for $index from 11 to 21 {
            &:nth-child(#{$index}) {
    
                .ct-bar {
                    stroke: nth($grouped-chart-colors, $index - 10);
                }
            
                .ct-line {
                    stroke: nth($grouped-chart-colors, $index - 10);
                }
                
                .ct-point {
                    stroke: nth($grouped-chart-colors, $index - 10);
                }
            }
        }
    }

}

.ct-chart-donut {
    .ct-series {
        @for $index from 1 to 22 {
            &:nth-child(#{$index}) .ct-slice-donut {
                stroke: nth($graph-colors, $index);
                opacity: 0.5;
                transition: opacity 0.3s;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}