@import '../../../theme';

.smartFilter {
    p {
        text-align: center;
        font-style: italic;
        font-size: 12px;
        color: var(--primary-color);
        letter-spacing: 0.5px;
        filter: grayscale(70%);
    }
}