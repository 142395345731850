@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;

    border-bottom: 30px solid #fff;
    border-radius: 40px;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

$grid-line-color: #ddd;

.buttonHolder {
    position: absolute;
    right: 18px;
    top: 8px;

    button {
        display: block;
        width: 150px;
        text-align: left;
        margin: 10px 0;
        height: 33px;

        svg {
            vertical-align: middle;
        }

        &:last-child {
            svg {
                stroke: white;
            }
        }
    }
}

.ActionButtons {
    margin: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 40px;
    height: 100%;
    position: relative;
}

.ActionButton {
    display: flex;
    border-radius: 40px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
    min-width: 100px;
    padding: 0 10px;
    flex-grow: 1;
    height: 30px;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    cursor: pointer;
    position: relative;
    margin-right: 15px;
    transition: background-color 0.3s, box-shadow 0.3s;
    border: 2px solid var(--primary-color);
    font-weight: 600;
}

.normalFlowchartHolder {
    position: relative;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: space-between;
}

.waitingForDragFlowchartHolder {
    position: relative;
    height: 100%;
    background: transparent;
    pointer-events: none;
    display: flex;
    justify-content: space-between;

    [class*="attachment-target"] {
        pointer-events: all;
    }
}

.flowchartWithPiceContainer {
    .flowchartContainer {
        width: calc(100% - 270px);
        border-radius: 0 30px 30px 0;
    }
}

.flowchartContainer {
    background-color: white;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 100px;
    padding-right: 0;
    background-image: linear-gradient(to right, $grid-line-color 1px, transparent 1px), linear-gradient(to bottom, $grid-line-color 1px, transparent 1px);
    background-size: 10px 10px;
    z-index: 4;
    border-radius: 30px;
}

.readOnlyChart {
    pointer-events: none;
}

.pieceOptionWrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.piecesCollection {
    position: relative;
    width: 270px;
    overflow: auto;
    background-color: white;
    border-radius: 30px 0px 0px 30px;
}

.rightSideFixedElements {
    position: fixed;
    right: 48px;
    top: 180px;
    z-index: 1;
}

.recentPieces {
    width: 240px;
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
    border-radius: 20px;

    .recentPiecesHeading {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        user-select: none;
        border-radius: 30px;
        height: 35px;
        align-items: center;
        padding: 0 15px;

        h5 {
            padding-right: 15px;
            font-size: 12px;
            color: black;
        }

        .toggle {
            text-decoration: underline;
            font-size: 12px;
            cursor: pointer;
            color: var(--contrast-color);
        }
    }
}

.shortCuts {
    user-select: none;
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
    border-radius: 20px;
    width: 240px;

    .shortCut {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        user-select: none;
        border-radius: 30px;
        height: 35px;
        align-items: center;
        padding: 0 15px;

        h5 {
            padding-right: 15px;
            font-size: 12px;
            color: black;
        }

        .name {
            color: rgb(170, 170, 170);
            font-size: 12px;
        }

        .value {
            color: var(--primary-color);
            font-size: 12px;
        }

        .toggle {
            text-decoration: underline;
            font-size: 12px;
            cursor: pointer;
            color: var(--contrast-color);
        }
    }
}

.validations {
    user-select: none;
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    width: 240px;

    .validationHeading {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        user-select: none;
        border-radius: 30px;
        height: 35px;
        align-items: center;
        padding: 0 15px;

        h5 {
            padding-right: 15px;
            font-size: 12px;
            color: black;
        }

        .toggle {
            text-decoration: underline;
            font-size: 12px;
            cursor: pointer;
            color: var(--contrast-color);
        }
    }
}

.recentPiecesHeading {
    display: flex;
    justify-content: space-between;

    padding: 10px 25px;
    color: grey;
    font-size: 14px;
    user-select: none;

    h5 {
        padding-right: 15px;
        font-size: 12px;
        color: black;
    }

    .toggle {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        color: var(--contrast-color);
    }
}

.recentPiece {
    padding: 15px 25px;
    color: grey;
    font-size: 14px;
    cursor: pointer;
}



.validation {
    padding: 15px 25px;
    color: grey;
    font-size: 14px;
    border-bottom: 1px solid silver;
    cursor: pointer;
    width: 240px;

    .name {
        color: rgb(170, 170, 170);
        margin-bottom: 5px;
    }

    .value {
        color: var(--primary-color);
        font-size: 12px;
    }
}

.modalHolder {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    background-color: rgba(0, 0, 0, 0.4);
}

.richTextModal {
    position: absolute;
    top: 50%;
    left: 25%;
    right: 25%;

    transform: translateY(-50%);

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    background-color: white;

    padding: 10px;

    h2 {
        margin-bottom: 10px;
    }

    .closeIcon {
        position: absolute;
        top: 13px;
        right: 13px;
        cursor: pointer;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .submitModal {
        display: block;
        width: 100%;
        text-align: center;
        padding: 15px;
        background-color: var(--primary-color);
        color: white;
        font-size: 17px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        cursor: pointer;
    }
}

.recentPiece {
    padding: 15px 25px;
    color: grey;
    font-size: 14px;
    border-bottom: 1px solid silver;
    cursor: pointer;
}

.searchSection {
    padding: 15px 20px;
    border-bottom: 1px solid silver;
}

.searchInput {
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border: none;
    font-size: 12px;
    color: $grey;
    padding: 10px;

    &:focus {

        box-shadow: inset 0 0 3px var(--primary-color);
    }
}

.nudgeActive {
    box-shadow: 0 0 10px 0 var(--contrast-color);
}

.collectionHeader {
    background-color: var(--primary-color);
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 20px;
    line-height: 1.5;
}

.pieceOptions {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 0;

    button {
        width: 100px;
    }
}

.cookbookEntry {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid silver;
    cursor: pointer;

    &:nth-child(3) {
        border-top: 1px solid silver;
    }
}

.entryName {
    color: grey;
    font-size: 14px;
}

.categoryHeading {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid silver;
    cursor: pointer;
}

.pieceEntry {
    padding: 15px 25px;
    color: grey;
    font-size: 14px;
    border-bottom: 1px solid silver;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: rgba(lightgray, 0.2);
    }

    .pieceEntryName {
        flex-grow: 1;
    }
}

.categoryIndicator {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.categoryName {
    color: grey;
    font-size: 14px;
    flex-grow: 1;
}

.categoryChevron {

    svg[class="chevron-arrow-down-icon"] {
        width: 10px;
        height: 10px;

        path {
            fill: grey;
        }
    }
}

.infoIcon {       
    float: right;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid grey;
    border-radius: 20px;
    width: 18px;
    height: 18px;

    svg {
        fill: grey;
        width: 10px;
        height: 10px; 
    }

    &:hover {
        border-color: var(--contrast-color);
        svg {
            fill: var(--contrast-color);
        }
    }
}

.expandedCategoryChevron {

    svg[class="chevron-arrow-down-icon"] {
        width: 10px;
        height: 10px;
        transform: rotate(180deg);

        path {
            fill: grey;
        }
    }
}

.pieceHolder {
    position: absolute;
    top: 0;
    left: 0;

    [class*="normalFlowchartHolder"] & {
        pointer-events: none;

        &>* {
            pointer-events: all;
        }
    }
}

.back {
    position: fixed;
    top: 70px;
    left: 200px;

    button {
        background-color: #fff;
    }
}

.flowchartHeading {
    font-size: 12px;
    color: var(--primary-color);
    height: 63px;
    position: sticky;
    top: 0px;
    margin-left: 20px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.flowchartHeadingExpanded {
    button:nth-child(even) {
        position: fixed;
        right: 305px;
    }

    composes: flowchartHeading;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    position: fixed;
    background-color: white;
    height: 63px;
    margin-left: 0;
    padding: 0 1vw;
}