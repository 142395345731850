@import '../../common/styles/colors.scss';


@keyframes undulate {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

.logoHolder {
    cursor: default;
}


.help {
    position: absolute;
    bottom: 0px;
    left: 0%;
    padding: 5px 10% 15px;
    background-color: white;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .version {
        color: var(--primary-color);
        text-align: center;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        margin-left: 10px;
    }
}

.Logo {
    width: 80%;
    margin: 0px auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 20px 0;

    img {
        cursor: pointer;
        display: block;
        max-width: 100%;
        height: auto;
        max-height: 60px;
        object-fit: contain;
    }
}

.syncPending {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $danger;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.updatePending {
    composes: syncPending;
    background-color: #ffce00;
}

.highlightedSyncPending {
    composes: syncPending;
    animation-name: undulate;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.highlightedUpdatePending {
    composes: updatePending;
    animation-name: undulate;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.SideBar {
    width: 180px;
    height: 100vh;
    background-color: white;
    overflow: auto;
    position: relative;
}

.SyncButton {
    position: sticky;
    top: 1px;
    display: flex;
    width: 80%;
    margin: 0 auto 10px;
    color: var(--primary-color);
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 39px;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid var(--primary-color);
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    font-weight: 600;

    svg {
        width: 24px;
        height: 24px;
        align-self: center;
        margin-right: 5px;
        fill: white;
        background-color: var(--primary-color);
        border-radius: 50%;
        padding: 4px;
    }

    span {
        display: block;
        font-size: 14px;
        width: calc(100% - 40px);
        margin-left: 15px;
    }

    &:hover {
        background-color: var(--primary-color);

        svg {
            filter: grayscale(50%);
            animation: rotate 1s forwards;
        }

        span {
            color: white;
        }
    }

    &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &.flashButton {
        animation: flash infinite 1s;
    }

    &.syncActive {
        animation: flash infinite 1s;

        svg {
            animation: rotate 1s infinite;
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(180deg);
        }
    }

    @keyframes flash {
        0% {
            border-color: var(--primary-color);
            background-color: transparent;
            color: var(--primary-color);
        }

        50% {
            border-color: $danger;
            background-color: $danger;
            color: white;
        }

        100% {
            border-color: var(--primary-color);
            background-color: transparent;
            color: var(--primary-color);
        }
    }
}

.lastSyncClass {
    color: var(--primary-color);
    opacity: 0.7;
    text-align: center;
    width: 80%;
    margin: 0 auto 10px;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.dataSavedSyncClass {
    color: var(--primary-color);
    text-align: center;
    width: 80%;
    margin: 0 auto 10px;
    font-size: 9.5px;
    display: flex;
    align-items: center;

    svg {
        width: 14px;
        height: 14px;
        background-color: $success;
        border-radius: 30px;
        fill: white;
        padding: 2.5px;
        margin-left: 6px;
    }
}

.NavLinksHolder {
    background-color: white;
    height: auto;
    overflow: auto;
    padding: 20px 0 0;
    position: relative;

    button {
        width: 80%;
    }
}

.NavigationLink {
    display: flex;
    width: calc(100% - 40px);
    margin: 0 auto 10px;
    justify-content: flex-start;
    align-items: center;
    height: 33px;
    padding-left: 15px;
    border-radius: 30px;
    background-color: transparent;
    transition: padding 0.5s, background-color 0.3s;

    svg {
        width: 15px;
        height: 15px;
        align-self: center;
        margin-right: 15px;
        fill: var(--primary-color);
        fill: var(--primary-color);
        transition: fill 0.3s, transform 0.3s;
    }

    span {
        display: block;
        font-size: 12px;
        color: var(--primary-color);
        transition: width 0.3s, color 0.3s;
        width: calc(100% - 30px);
        text-align: left;
        font-weight: 600;
    }

    &:hover,
    &.active {
        background-color: var(--primary-color);

        svg {
            fill: white;
            fill: white;
        }

        span {
            color: white;
        }
    }

    &.active {
        filter: grayscale(50%);
        pointer-events: none;
    }
}

.NavHeader {
    font-size: 12px;
    font-weight: 600;
    padding: 17px 0 17px 15px;
}

.previousDaysText {
    position: absolute;
    bottom: 130px;
    left: 0%;
    width: 100%;

    text-align: center;
    padding: 5px;
    font-size: 9px;
    color: var(--primary-color);
}

.fetchOlderDataButton {
    composes: SyncButton;
    height: 35px;

    position: absolute;
    top: unset;
    bottom: 80px;
    z-index: 1;
    left: 20px;

    span {
        font-size: 11px;
        margin-left: 10px;
        margin-right: 5px;
    }

    svg {
        width: 20px;
        height: 20px;
    }
}

.isDisabled {
    opacity: 0.4;
    pointer-events: none;
    filter: grayscale(1.1);

    span {
        font-size: 11px
    }
}

.syncDataSavedMessage {
    font-size: 14px !important;
    color: white !important;
}

.SyncCompleteButton {
    color: $success;
    border: 2px solid $success;
    background-color: $success;

    svg {
        background-color: white;
        fill: $success;
    }

    &:hover {
        background-color: $success;

        svg {
            animation: unset;
        }
    }

    composes: SyncButton;
}