@import '../../common/styles/colors.scss';
@import '../../theme';
@import '../../colors';

.workflowDropdown {
    position: absolute;
    top: calc(100% + 22px);
    right: -22px;
    background-color: white;
    box-shadow: 0px 0px 10px -4px var(--primary-color);
    border-radius: 30px;
    padding: 10px;
    min-width: 250px;
    flex-wrap: nowrap;
    opacity: 0;
    z-index: 1;
    animation: dropin 0.2s forwards;

    header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        position: relative;
        padding: 5px 10px 5px;

        svg {
            filter: grayscale(70%);
            width: 12px;
            height: 12px;
            fill: var(--primary-color);
        }

        h5 {
            font-size: 12px;
            font-weight: 600;
            color: var(--primary-color);
            letter-spacing: 0.5px;
            margin-left: 7px;
            filter: grayscale(70%);
        }
    }

    @keyframes dropin {
        0% {
            transform: translateY(-10px);
            opacity: 0;
        }

        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    [class*="optionsList"] {
        box-shadow: none;

        &:hover {
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }
        }
    }

    .triangle {
        position: absolute;
        right: 30px;
        top: -22px;
        z-index: 1;
        color: white;
        font-size: 24px;
        text-shadow: 0 -5px 5px rgba(var(--primary-color-rgb), 0.5);
        pointer-events: none;
    }

}

.listHolder {
    position: relative;

    button {
        margin: 0;

        .fireActionIcon {
            stroke: var(--primary-color);
        }
    }
}

.listItem {
    padding: 10px;
}

.fileLink {
    color: $lighter-blue;
    text-decoration: underline;
}