@import '../../colors';

.toggle {
    width: 40px;
    height: 18px;
    border-radius: 8px;
    transition: background-color 0.3s linear;
    cursor: pointer;
    position: relative;

    .slider {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
        transform: scale(1.1) translateX(20px);
        transition: transform 0.3s linear;
    }
}

.positive {
    composes: toggle;
    background-color: #8bc542;
}

.middle {
    composes: toggle;
    background-color: $grey;
    
    .slider {
        transform: scale(1.1) translateX(10px);
    }
}

.negative {
    composes: toggle;
    background-color: #bb4950;
    
    .slider {
        transform: scale(1.1) translateX(0px);
    }
}

.negativePiece {
    width: 18px;
    height: 18px;
    position: absolute;
    left: -6px;
    top: 0
}

.positivePiece {
    width: 18px;
    height: 18px;
    position: absolute;
    right: -6px;
    top: 0
}

.middlePiece {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 12px;
    top: 0;
}

