// Theme colors
:root {    
    --primary-color: #803f97;
    --contrast-color: #00ae8d;
    --primary-color-rgb: 128, 63, 151;
    --contrast-color-rgb: 0, 174, 141;
}

// Graph colors (22)
$graph-colors: #00549a, #007199, #00879a, #009996,
#009a84, #00996d, #009a53, #00993e,
#009927, #00990d, #019700, #01e990,
#369900, #4b9a01, #6a9801, #cacc00,
#949900, #998e01, #9ac700, #995600,
#994900, #983700;

$grouped-chart-colors: #42ace5, #b4f793, #9647e1,
#f5d47d, #ffc3ff, #626262,
#00533a, #a82ed8, #e26a45,
#8b002e;

// Font colors
$grey-shades: #f9fafc, #e7e7e7, #949599, #595959; // lighter, light, grey, dark-grey
$danger: #ea7a7a;
$success: #00ae8d;
$chart-blue: #aae0ed; 