@import '../../../colors';
@import '../pieces/pieces.scss';


.selectBoxPiece {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    padding: 8px 13px;
    border-radius: 2px;

    svg[class="chevron-arrow-down-icon"] {
        width: 7px;
        height: 7px;

        path {
            fill: white;
        }
    }
}

.roundedBox {
    border-radius: 400px;
}

.blockBox {
    display: flex;
}

.text {
    color: white;
    font-size: 12px;
    margin-right: 10px;
    white-space: nowrap;
    flex-grow: 1;
}

.variablePiece {
    margin-right: 10px;
}

.selectBoxHolder {
    position: relative;
    width: fit-content;
}

.blockSelectBoxHolder {
    position: relative;
}

.optionsHolder {
    position: absolute;
    top: 62px;
    left: 50%;
    z-index: 20;
    transform: translateX(-50%);
}

.orangeThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-orange;
    border: 1px solid $flowchart-orange-dark;
}

.darkOrangeThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-orange-dark;
    border: 1px solid $flowchart-orange-dark;
}

.indigoThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-indigo;
    border: 1px solid $flowchart-indigo-dark;
}

.darkIndigoThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-indigo-dark;
    border: 1px solid $flowchart-indigo-dark;
}

.aquaThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;
}

.darkAquaThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-aqua-dark;
    border: 1px solid $flowchart-aqua-dark;
}

.pinkThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-pink;
    border: 1px solid $flowchart-pink-dark;
}

.darkPinkThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-pink-dark;
    border: 1px solid $flowchart-pink-dark;
}

.camoThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-camo;
    border: 1px solid $flowchart-camo-dark;
}

.darkCamoThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-camo-dark;
    border: 1px solid $flowchart-camo-dark;
}

.redThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-red;
    border: 1px solid $flowchart-red;
}

.darkRedThemedSelectBox {
    composes: selectBoxPiece;
    background-color: $flowchart-red-dark;
    border: 1px solid $flowchart-red-dark;
}