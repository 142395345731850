@import '../../common/styles/colors.scss';

.toggle {
    width: 30px;
    height: 16px;
    border-radius: 8px;
    transition: background-color 0.3s, box-shadow 0.3s;
    transition-timing-function: linear;
    box-shadow: 0px 0px 5px nth($grey-shades, 3) inset;
    cursor: pointer;
    overflow: hidden;
    background-color: white;
    margin-left: auto;
    
    .slider {
        width: 16px;
        height: 16px;
        border-radius: 50%;                
        transform: scale(0.8) translateX(100%);
        transition: transform 0.3s, background-color 0.3s;
        transition-timing-function: linear;
    }
}

.on {
    composes: toggle;
    background-color: $success;
    box-shadow: 0px 0px 5px darken($success, 15%) inset;

    .slider {
        background-color: white;
    }
}

.off {
    composes: toggle;
    
    .slider {
        background-color: darken($danger, 5%);
        transform: scale(0.8) translateX(1px);
    }
}

.highlightedOn {
    composes: on;
    box-shadow: 0 0 10px 0 var(--contrast-color), 0px 0px 5px darken($success, 15%) inset;
}

.highlightedOff {
    composes: off;
    box-shadow: 0 0 10px 0 var(--contrast-color);
}