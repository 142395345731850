@import '../../common/styles/colors.scss';

.Tabs {
    margin: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 40px;
    height: 100%;
    position: relative;
    margin-right: 280px;
    transition: margin 0.3s;

    &.active {
        margin-right: 45px;
    }

    &.isDisabled {
        
    }

    .Tab {
        display: flex;
        border-radius: 40px;
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 600;
        min-width: 100px;
        padding: 0 10px;
        flex-grow: 1;
        height: 30px;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        cursor: pointer;
        position: relative;
        margin-right: 15px;
        transition: background-color 0.3s, box-shadow 0.3s;
        border: 2px solid var(--primary-color);
        font-weight: 600;

        &:hover {
            background-color: var(--primary-color);
            color: white;
        }

        &.active {
            background-color: var(--primary-color);
            color: white;
            pointer-events: none;
            position: relative;
            filter: grayscale(50%);
        }
    }
}