@import '../../../colors';
@import '../../../theme';

.showImage {
    display: block;
    max-width: 100%;
    max-height: 70vh;
    margin: 0 auto;
    object-fit: contain;
    width: inherit;
}

.showImageBorder {
    width: 100%;
    background: white;
    padding: 18px;
    border-radius: 20px;

    img {
        border-radius: 10px;
        object-fit: fill;
    }
}

.showVideo {
    display: block;
    max-width: 100%;
    max-height: 70vh;
    margin: 0 auto;
}

.downloadLink {
    text-decoration: underline;
    color: var(--contrast-color);
}

.showTableHolder {
    width: 550px;
    margin: 0 auto;
}

.exportButtonHolder {
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
}

.tableHeading {
    margin: 0 20px 20px;
    text-align: center;
    color: var(--primary-color);
}

.showHeader {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: flex-end;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    padding: 0px 10px 0px 20px;
    margin: 10px 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @extend %ThemeLightBackground;
    }

    .showHeading {
        flex-grow: 1;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: darken(nth($grey-shades, 4), 10%);
        margin-right: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: opacity 0.5s;

        &.hide {
            opacity: 0;
        }

        .heading {
            width: calc(100% - 30px);

            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                font-weight: 700;
            }

            span {
                display: block;
                font-size: 10px;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: var(--primary-color);
            margin-right: 10px;
        }
    }

    .actionButtonsContainer {
        position: relative;
        transition: opacity 0.5s;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        &.hide {
            opacity: 0;
        }

        button {
            margin-left: 15px;
        }
    }
}

.showDataContainerForTable {
    padding: 0 10px 10px;
    margin: 0;
    background-color: white;
    position: relative;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;

    @extend %CommonCard;

    .dataContainer {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
        z-index: 0;
    }

    .tableHolder {
        width: 100%;
        overflow: auto;
    }

    section[class*="table"] {
        min-width: 100%;
        margin: 0;
        flex-grow: 1;


        section[class*="tableBody"] {
            border: 1px solid lighten(nth($grey-shades, 2), 2%);
            border-radius: 20px;
            overflow: auto;
            width: 100%;
        }
    }
}

.normalTable {
    width: 100%;
    height: calc(100% - 90px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pageActions {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    position: sticky;
    bottom: 0;
    padding: 15px 25px;
    background-color: white;

    .pageSize {
        position: relative;
        margin-left: auto;

        div {
            display: inline-block;
            vertical-align: middle;
            color: var(--primary-color);
            font-size: 14px;
            font-weight: 600;
            opacity: 0.8;
            margin-right: 10px;
        }

        input {
            width: 30px;
            font-size: 14px;
            color: nth($grey-shades, 4);
            height: 30px;
            text-align: center;
            border: none;
            background-color: white;
            box-shadow: 0px 0px 5px inset var(--primary-color);
            font-weight: 600;
            border-radius: 5px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            border-radius: 30px;
        }

        button {
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &.decreaseSize {
                transform: rotate(90deg);
            }

            &.increaseSize {
                transform: rotate(-90deg);
                margin-left: 7px;
            }

            &.prevPage,
            &.nextPage {
                width: 25px;
                height: 25px;
                border: 1px solid var(--primary-color);
                border-radius: 50%;

                svg {
                    width: 50%;
                }
            }

            &.prevPage {
                transform: rotate(-180deg);
                margin-right: 7px;
            }

            &.nextPage {
                transform: rotate(0deg);
            }

            svg {
                stroke: var(--primary-color);
                width: 80%;
                height: 100%;
            }
        }
    }

}

.richtext {
    p {
        span {
            background: none !important;
        }
    }
}


.showDataContainer {
    font-size: 14px;
    color: var(--primary-color);
    line-height: 1.5;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin: 20px auto;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;

    .iconHolder {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        align-self: flex-start;
        justify-content: center;
        background-color: white;
        border-radius: 50%;

        svg {
            width: 16px;
            height: 16px;
            fill: var(--primary-color);
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @extend %ThemeLightBackground;
    }

    &>* {
        position: relative;
        font-weight: 600;
    }

    &>*:last-child {
        flex-grow: 1;
    }
}

.showPdfContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    border-radius: 20px;
    font-size: 11px;
    height: 56px;

    .pdfName {
        display: flex;
        align-items: center;
        width: 65%;
        height: 40px;

        .pdfIconHolder {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px
        }

        .fileName {
            padding-left: 10px;
            width: 100%;
            color: #5D4C63;
            font-weight: 600;
            border-left: 1px solid #D0C9D3;
            height: 100%;
            display: flex;
            align-items: center;
            text-align: left;
            @extend .line-clamp;
        }
    }

    @extend .fileDetails;

}

.fileDetails{
    display: flex;
    align-items: center;
    gap: 0 10px;
    color: nth($grey-shades, 4);
    font-size: 11px;

    span {
        font-weight: 500;
    }

    .dot {
        width: 4px;
        height: 4px;
        border: 1px solid nth($grey-shades, 4);
        background-color: nth($grey-shades, 4);
        border-radius: 20px;
    }

    .downloadButton {
        width: 111px;
        height: 30px;
        border: 1px solid var(--primary-color);
        border-radius: 12px;
        color: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        fill: var(--primary-color);
        cursor: pointer;
        
        div {
            font-weight: 600;
        }
    }
}

.line-clamp {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.showAudioContainer {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 20px 14px;

    audio {
        width: 100%;
    }
}

.showVideoContainer {
    @extend .showAudioContainer;
}