@import '../../../colors';
@import '../../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.permissionsHolder {
    display: flex;
    height: calc(100% - 10px);
    align-items: flex-start;
    padding-top: 5px;
}

.allRoles {
    position: sticky;
    left: 0;
    z-index: 2;
    margin-left: 20px;
    margin-top: 5px;
    display: block;
    min-width: 330px;
    height: 100%;
    background-color: white;
    border-radius: 30px;
    
    @extend %CommonCard;
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.newVerticalHolder {
    min-width: 290px;
}

.cardsTree {
    position: relative;
    @extend %CommonCardsTree;
    flex-grow: 1;
    margin-bottom: 0;
    padding-left: 10px;
    overflow: auto;
    height: 100%;
    padding-top: 5px;
    
    &::after {
        content: "";
        display: block;
        min-width: 10px;
        height: 10px;
    }
}