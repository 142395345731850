@import '../../../colors';
@import './pieces.scss';

.stopPiece {
    display: inline-flex;
    align-items: center;

    position: relative;
    min-width: 137px;
    width: fit-content;
    height: 56px;
    min-width: 120px;
    height: 49px;
    background: url(../../../assets/flowchart/stop.svg);
    background-size: 120px 49px;
    background-repeat: no-repeat;
    padding: 17px 10px;

    @include cursor-for-pieces();

    &::before {
        content: "";
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 60px;
        right: 0;
        z-index: -1;
        background-color: $flowchart-aqua;
        border: 1px solid $flowchart-aqua-dark;
        border-left-color: transparent;
        border-radius: 4px;
    }
}

.invalidStopPiece {
    composes: stopPiece;
    background: url(../../../assets/flowchart/stop-error.svg);
    background-size: 120px 49px;
    background-repeat: no-repeat;

    &::before {
        background-color: $flowchart-red;
        border: 1px solid $flowchart-red-dark;
    }
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;
}

.addedHighlight {
    position: relative;
    z-index: 1;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 1;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $dirty-yellow) drop-shadow(2px -2px 2px $dirty-yellow) drop-shadow(-2px -2px 2px $dirty-yellow) drop-shadow(-2px 2px 2px $dirty-yellow);
    position: relative;
    z-index: 1;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 1;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 1;
}