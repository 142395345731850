@import '../../common/styles/colors.scss';
@import '../../theme';

.warningHolder {
    text-align: center;
    font-size: 12px;
    color: nth($grey-shades, 3);
    font-size: 13px;
    margin: 5px;
}

.showDataContainer {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: white;
    position: relative;
    width: 97%;
    max-width: none;
    margin: 0px;
    height: 100%;
    
    @extend %CommonCard;
    border-radius: 25px;
    
    &:hover {
        .aggregationHeader {
            -webkit-line-clamp: unset;
            overflow-y: auto;
        }
    }

    .dataContainer {
        display: flex;
        align-items: center;
        border-top: 4px solid var(--primary-color);
        flex-grow: 1;
        position: relative;
        z-index: 0;
        border-top: 0px solid var(--primary-color);
        transition: filter 0.1s;
    }
}

.showHeader {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: flex-end;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    padding: 0px 10px 0px 20px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @extend %ThemeLightBackground;
    }
    
    .showHeading {
        flex-grow: 1;        
        overflow: hidden;
        text-overflow: ellipsis;
        color: darken(nth($grey-shades, 4), 10%);
        margin-right: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: opacity 0.5s;

        &.hide {
            opacity: 0;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: var(--primary-color);
            margin-right: 10px;
        }

        &>div {
            width: calc(100% - 35px);
            font-size: 10px;

            span {
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                font-weight: 700;
                text-align: left;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .moreOptionsHolder {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s, transform 0.5s;
        transform: translateX(100%);
        background-color: transparent;
        
        &.active {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0%);
        }
    }

    .moreOptions {
        position: relative;
        width: calc(100% - 10px);
        height: 100%;
        overflow: auto;
        list-style: none;          
        border-radius: 7px;
        display: flex;
        background-color: transparent;
        align-items: center;
        justify-content: flex-end;
        transition: transform 0.3s;

        button {
            margin-right: 5px;
        }
    }
}

.submit {
    display: block;
    width: 160px;
    margin: 30px auto;

    border-radius: 40px;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 11px 0;

    text-align: center;
    color: white;
    background-color: var(--contrast-color);
    cursor: pointer;
}

.downloadButtonsHolder {
    padding-top: 15px;
    text-align: right;
    margin-top: 20px;
    border-top: 2px solid nth($grey-shades, 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
        background-color: transparent;
        color: var(--contrast-color);
        border: 2px solid var(--contrast-color);
        font-size: 14px;
        padding: 0 7px;
        height: 40px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background-color: var(--contrast-color);
            color: white;

            svg {
                fill: white;
                stroke: white;
            }
        }

        &.share {
            background-color: nth(nth($grey-shades, 3)-shades, 1);
            border: 0px;
            color: var(--primary-color);
            padding: 0 15px;

            &:hover {
                background-color: var(--primary-color);
                color: white;

                svg {
                    fill: white;
                    stroke: white;
                }
            }

            svg {
                fill: var(--primary-color);
                stroke: var(--primary-color);
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: var(--contrast-color);
            stroke: var(--contrast-color);

            transition: stroke 0.3s, fill 0.3s;
        }

        span {
            margin-left: 7px;
        }
    }
}

.oneDownloadButtonHolder {
    composes: downloadButtonsHolder;
    justify-content: flex-end;
}

.showModifyButton {
    padding: 8px 15px;
    border-radius: 800px;
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    svg {
        margin-top: 6px;
        margin-right: 5px;
        width: auto;
        height: 12px;
        fill: white;
        stroke: white;
    }
}

.updateButton {
    composes: showModifyButton;
    background-color: var(--contrast-color);
    color: white;
}

.deleteButton {
    composes: showModifyButton;
    background-color: nth($grey-shades, 3);
    color: white;
}

.numberHolder {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    
    p {
        font-size: 24px;
        font-weight: bold;
        color: nth($grey-shades, 4);
    }
    
    .aggregationHeader {
        font-size: 13px;
        color: nth($grey-shades, 3);
        line-height: 1.5;
    }
}

.dataContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-top: 15px;
}

.noDataContainer {
    width: 100%;
    padding: 20px;
    text-align: center;
    color: silver;
    font-size: 22px;
    font-weight: bold;
}