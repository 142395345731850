@import '../../../colors';
@import '../../../theme';

.hiddenFile {
    display: none;
}

.cardsTree {
    @extend %CommonCardsTree;
    height: 100%;
    overflow: auto;
    padding-top: 10px;
}

.importExportButtonHolder {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;

    button,
    label {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.pageButtons {
    text-align: right;
    margin: 0px 20px;
    padding: 10px 0;

    button {
        margin: 0;
        margin-left: 15px;
    }

    .reloadButtonHolder {
        position: fixed;
        right: 40px;
        bottom: 45px;
        background-color: white;
        border-radius: 50%;
        z-index: 10;
        box-shadow: 0px 0px 5px nth($grey-shades, 4);
        transition: transform 0.3s;

        &.moveRight {
            transform: translateX(-240px);
        }

        button {
            margin: 0;
        }
    }
}