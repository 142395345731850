.fileDetailsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    padding-top: 15px;

    .fileName {
        width: 62%;
        color: #5D4C63;
        font-weight: 600;
        @extend .line-clamp;
    }

    @extend .fileDetails;
}

.fileDetails{
    display: flex;
    align-items: center;
    gap: 0 10px;
    color: #5D4C63;
    font-size: 11px;

    span {
        font-weight: 500;
    }

    .dot {
        width: 4px;
        height: 4px;
        border: 1px solid #5D4C63;
        background-color: #5D4C63;
        border-radius: 20px;
    }

    .downloadButton {
        width: 111px;
        height: 30px;
        border: 1px solid var(--primary-color);
        border-radius: 12px;
        color: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        fill: var(--primary-color);
        cursor: pointer;
        
        div {
            font-weight: 600;
        }
    }
}

.line-clamp {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}