@import '../../../colors';
@import './pieces';

.booleanIndicator {
    position: relative;
    width: 30px;
    height: 25px;
    margin: 0 10px;
    background-color: darken($flowchart-aqua-dark, 5%);

    &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0px;
        height: 0px;
        border-width: 12.5px 10px;
        border-color: transparent;
        border-style: solid;
    }

    &::before {
        left: -20px;
        border-right-color: darken($flowchart-aqua-dark, 5%);
    }

    &::after {
        right: -20px;
        border-left-color: darken($flowchart-aqua-dark, 5%);
    }
}

.booleanIndicatorHovering {
    composes: booleanIndicator;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

    &::before {
        border-right-color: rgba(0, 0, 0, 0.4);
    }

    &::after {
        border-left-color: rgba(0, 0, 0, 0.4);
    }
}