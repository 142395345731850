@import '../../colors';

@keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
}


.CommonButton {
    // Default button styles
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid grey;
    cursor: pointer;
    position: relative;    

    span {
        font-weight: 600;

        &.pushText {
            margin-left: 10px;
        }
    }
    
    &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        border-radius: inherit;
        border: inherit;
        opacity: 0;
    }

    &::after {
        display: none;
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        
        width: 100%;
        height: 100%;
        border-radius: inherit;
        border: inherit;

        animation: ripple 0.3s forwards;
        opacity: 0;
    }

    &:focus:not(:active)::after {
        display: block;
    }

    // If disabled
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    // When button is highlighted
    &.Highlighted {
        box-shadow: 0 0 10px 0 var(--contrast-color);
    }

    // When button is in danger
    &.Danger {
        border-color: $sunglo;
        color: $sunglo;
    }

    // When button is pressed or in active state
    &.Active {
        filter: grayscale(50%);
    }
}

// Size Matters ;)

// Takes the whole line
.BlockButton {
    display: flex;
    margin: 0 auto;
}

.dot {
    position: absolute;
    right: -2px;
    top: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
}

.Small {
    height: 30px;
    min-width: 30px;
    font-size: 12px;
    // margin-top: 7px;
    // margin-bottom: 7px;

    svg {
        width: 10px;
        height: 10px;
    }

    &.RoundButton {
        border-radius: 15px;

        &::before {
            border-radius: 20px;
        }
    }

    span {
        
        &.pushText {
            margin-left: 5px;
        }
    }
}

.Medium {
    height: 40px;
    min-width: 40px;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 12px;

    &.RoundButton {
        border-radius: 20px;

        &::before {
            border-radius: 26px;
        }
    }

    svg {
        width: 18px;
        height: 18px;
    }
}

.Large {
    height: 50px;
    min-width: 50px;
    font-size: 16px;
    margin-top: 17px;
    margin-bottom: 17px;

    &.RoundButton {
        border-radius: 25px;

        &::before {
            border-radius: 30px;
        }
    }

    svg {
        width: 22px;
        height: 22px;
    }
}


// Outline, Background & Color
.Primary {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);

    svg {
        fill: white;
    }

    &:hover {
        &::before {
            opacity: 0.6;
        }
    }
    
    &.ContrastColor {
        background-color: var(--contrast-color);
        border-color: var(--contrast-color);
    }

    &.DangerColor {
        border-color: $danger;
        background-color: $danger;
    }
    
    .dot {
        background-color: var(--contrast-color);
    }
}

.Secondary {
    background-color: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color);

    .dot {
        background-color: var(--primary-color);
    }

    svg {
        fill: var(--primary-color);
    }

    &:hover {
        &::before {
            opacity: 0.6;
        }
    }

    &.ContrastColor {
        border-color: var(--contrast-color);
        color: var(--contrast-color);

        svg {
            fill: var(--contrast-color);
        }
    }

    &.DangerColor {
        border-color: $danger;
        color: $danger;

        svg {
            fill: $danger;
        }
    }
}

.Tertiary {
    background-color: transparent;
    border-color: transparent;
    color: var(--primary-color);
    transition: text-shadow 0.3s;

    .dot {
        background-color: var(--primary-color);
    }

    svg {
        fill: var(--primary-color);
    }

    &:hover {
        text-shadow: 0px 1px 1px var(--primary-color);
    }

    &.ContrastColor {
        color: var(--contrast-color);

        svg {
            fill: var(--contrast-color);
        }
    }

    &.DangerColor {
        color: $danger;

        svg {
            fill: $danger;
        }
    }
}