@import '../../common/styles/colors.scss';
@import '../../theme';

.commonModalHolder {
    position: absolute;
    right: 0px;
    top: 75px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-end;

    &.fullScreenChartMode {
        .addOrModifyListCard {
            width: 0px;
            overflow: hidden;
        }

        .filterContainer {
            width: calc(100% - 20px);
            border-top-right-radius: 30px;
        }
    }

    .addOrModifyListCard {
        width: 350px;

        &.expandedModal {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            box-shadow: 5px 3px 10px -8px nth($grey-shades, 3);
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -5px;
                top: calc(100% - 10px);
                background-color: transparent;
                box-shadow: 10px 0px 0px 0px inset white;
                height: 30px;
                width: 25px;
                border-top-left-radius: 35px;
                transform: rotate(25deg);
                z-index: 1;
            }

            .container {
                max-height: calc(100vh - 300px);
            }
        }
    }
}



.chartContentConfiguration {
    display: block;
    margin-top: 20px;
}

.filterContainer {
    background: white;
    border-radius: 30px;
    border-top-right-radius: 0px;
    overflow: auto;
    width: calc(100% - 400px);
    height: calc(100vh - 120px);
    box-shadow: 0px 0px 10px -5px nth($grey-shades, 3);

    .widgetHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 10px 20px 10px;
        position: relative;
        height: 62px;

        h2 {
            font-size: 18px;
            font-weight: 700;
            color: var(--primary-color);
            letter-spacing: 0.5px;
            margin-left: 18px;
        }

        .actionButtons {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                margin: 0;
                margin-left: 10px;
            }
        }
    }

    .widgetHeaderExpanded{
        position: fixed;
        left: 0;
        top: 0;
        background-color: white;
    }

    .flowchartContainer {
        width: calc(100% - 15px);
        border-radius: 20px;
        overflow: hidden;
        height: calc(100% - 70px);
        
        &.richTextChart {
            height: calc(100% - 380px);
        }

        [class*="normalFlowchartHolder"] {
            height: 100%;
        }
    }

    .richTextModal {
        height: 300px;
        width: calc(100% - 40px);
        margin: 15px auto 0;
        box-shadow: 0px 0px 10px -5px inset nth($grey-shades, 4);
        overflow: hidden;
        border-radius: 20px;
        padding: 10px 10px 0px;

        [class*="editorHolder"] {
            border: none;
            min-height: auto;
            height: calc(100% - 50px);
        }

        .saveButton {
            text-align: right;

            button {
                svg {
                    stroke: var(--primary-color);
                }
            }
        }
    }
}

.showChartButton {
    background-image: url('../../common/images/flowchart.PNG');
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    padding: 20px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(var(--primary-color-rgb), 0.8);
        filter: grayscale(50%);
    }

    button {
        width: 100%;
        height: 35px;
        border-color: white;
        margin: 0;
        color: white;
    }
}

.visibleFilterContainer {
    composes: filterContainer;
}

.fullScreenFlowChartModal {
    height: 100vh;
    width: calc(100% + 16px);
    z-index: 1;
    position: relative;
    border-radius: 0;
}

.customFieldsContainer {
    display: block;
    width: 100%;
    margin: 20px 0;
    overflow: hidden;
}

.containerHeader {
    font-size: 12px;
    color: var(--primary-color);
    width: 100%;
    margin: 0 0 20px 10px;
    position: relative;
    overflow: hidden;
    font-weight: 500;
    filter: grayscale(70%);
}

.customFieldsHeader {
    margin-bottom: 10px;

    div[class*="booleanInputHolder"] {
        label {
            font-weight: 600;
        }
    }
}

.customFieldOption {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}