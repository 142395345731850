@import '../../../../common/styles/colors.scss';
@import '../../../../theme';

.syncSummaryDetails {
    border-radius: 10px;
    
    display: grid;
    grid-template-columns: 0.35fr 0.65fr;    
    border-radius: 10px;
    overflow: hidden;
    
    
    .label, .value {
        padding: 10px 20px;
        font-size: 12px;
        margin: 1px;
    }

    .label {
        color: var(--primary-color);
        text-align: center;
        background-color: rgba(var(--primary-color-rgb), 0.1);
    }

    .value {
        color: var(--primary-color);
        background-color: white;
        font-weight: 600;
    }

    .segment {
        display: inline-block;
        margin-right: 10px;

        &:not(:last-child)::after {
            content: ",";
        }
    }
}