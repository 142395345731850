@import '../../../theme.scss';

.commonModalHolder.csvModal {
    right: 160px;
}

.workflowsCount {
    padding: 10px;
    font-size: 13px;
    text-align: center;
}
