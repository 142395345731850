@import '../../../colors';

.list {
    width: 350px;
    border-radius: 3px;
    padding: 3px;

    &::before {
        content: "";
        display: block;

        position: absolute;
        top: -40px;
        left: calc(50% - 20px);
        border: solid transparent;
        border-width: 20px 20px;
    }
}

.headingHolder {
    display: flex;
    padding: 15px 5px;
    align-items: center;
}

.backButton {
    width: 50px;
    text-align: center;
    cursor: pointer;

    svg[class="chevron-arrow-down-icon"] {
        width: 10px;
        height: 10px;
        transform: rotate(90deg);

        path {
            fill: white;
        }
    }
}

.heading {
    width: calc(100% - 100px);
    text-align: center;
    color: white;
}

.orangeThemedList {
    composes: list;
    background-color: $flowchart-orange-dark;

    &::before {
        border-bottom-color: $flowchart-orange-dark;
    }
}

.redThemedList {
    composes: list;
    background-color: $flowchart-red-dark;

    &::before {
        border-bottom-color: $flowchart-red-dark;
    }
}

.indigoThemedList {
    composes: list;
    background-color: $flowchart-indigo-dark;

    &::before {
        border-bottom-color: $flowchart-indigo-dark;
    }
}

.aquaThemedList {
    composes: list;
    background-color: $flowchart-aqua-dark;

    &::before {
        border-bottom-color: $flowchart-aqua-dark;
    }
}

.pinkThemedList {
    composes: list;
    background-color: $flowchart-pink-dark;

    &::before {
        border-bottom-color: $flowchart-pink-dark;
    }
}

.camoThemedList {
    composes: list;
    background-color: $flowchart-camo-dark;

    &::before {
        border-bottom-color: $flowchart-camo-dark;
    }
}