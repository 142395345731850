@import '../../../colors';
@import './pieces';

.getEntitiesPieceWrapper {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    padding: 8px 13px;
    border-radius: 500px;

    background-color: $flowchart-aqua;
    border: 1px solid $flowchart-aqua-dark;

    @include cursor-for-pieces();
}

.invalidGetEntitiesPieceWrapper {
    composes: getEntitiesPieceWrapper;
    background-color: $flowchart-red;
    border: 1px solid $flowchart-red-dark;
}

.text {
    color: white;
    font-size: 14px;
    white-space: nowrap;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.nameInput {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 7px;
}

.noHighlight {
    position: relative;
    z-index: 1;
}

.addedHighlight {
    filter: drop-shadow(2px 2px 2px $pastel-green) drop-shadow(2px -2px 2px $pastel-green) drop-shadow(-2px -2px 2px $pastel-green) drop-shadow(-2px 2px 2px $pastel-green);
    position: relative;
    z-index: 1;
}

.updatedHighlight {
    filter: drop-shadow(2px 2px 2px $warning) drop-shadow(2px -2px 2px $warning) drop-shadow(-2px -2px 2px $warning) drop-shadow(-2px 2px 2px $warning);
    position: relative;
    z-index: 1;
}

.movedHighlight {
    filter: drop-shadow(2px 2px 2px $royal-purple) drop-shadow(2px -2px 2px $royal-purple) drop-shadow(-2px -2px 2px $royal-purple) drop-shadow(-2px 2px 2px $royal-purple);
    position: relative;
    z-index: 1;
}

.deletedHighlight {
    filter: drop-shadow(2px 2px 2px $sunglo) drop-shadow(2px -2px 2px $sunglo) drop-shadow(-2px -2px 2px $sunglo) drop-shadow(-2px 2px 2px $sunglo);
    position: relative;
    z-index: 1;
}