@import '../colors';

.pageEnter {
    opacity: 0.01;
}

.pageEnter.pageEnterActive {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.pageExit {
    opacity: 1;
}

.pageExit.pageExitActive {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.backgroundColorProvider {
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 15px;
}

.focusWindow {
    height: 100vh;
    flex-grow: 1;
    overflow: auto;
    transition: margin 0.3s;
    
    &.makeSpaceForTopBar {
        margin-right: 235px;
    }
}

.frozenApp {
    pointer-events: none;
    cursor: not-allowed;
    filter: brightness(80%);
    position: absolute;
    width: 100%;
    height: 100%;
}

.errorMessageHolder {
    position: fixed;
    bottom: 10px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    padding: 8px 10px;
    padding-right: 40px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    background-color: rgb(31, 31, 31);
    cursor: pointer;

    .text {
        color: white;
        line-height: 1.5;
    }

    .highlight {
        color: var(--contrast-color);
    }

    .dismissal {
        width: 30px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 13px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $chart-blue;

        svg[class*="cancel-icon"] {
            fill: $chart-blue;
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }
    }
}

.searchPhraseButton {
    position: fixed;
    right: 245px;
    top: 5px;
    z-index: 2;

    button {
        height: 30px;

        svg {
            transform: scale(0.7);
            width: 50px;
        }
    }
}


.quickFind {
    width: 40%;
    border-radius: 20px;
    position: fixed;
    left: 30%;
    top: 20vh;
    z-index: 8;
    transform: scale(1.4);
    height: 60vh;

    ul {
        width: calc(100% - 30px);
        margin: 0 auto;
        padding: 0px 20px;
        background-color: white;
        border-radius: 20px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        max-height: 55vh;
        overflow: auto;

        li {
            padding: 10px 0;
            border-bottom: 1px solid $light-grey;
            list-style: none;
            font-size: 11px;
            color: $dark-grey;
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            label {
                width: calc(100% - 20px);

                span {
                    margin-top: 5px;
                    display: block;
                    color: $grey;
                    font-size: 9px;
                }
            }

            &:last-child {
                margin-bottom: 0;
                border-bottom: 0;
            }

            svg {
                margin-top: 5px;
                fill: $blue;
                width: 15px;
                height: 15px;
                outline: none;
            }
        }
    }
}

.backDrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
    z-index: 7;
}