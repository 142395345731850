@import '../../../colors';
@import '../../../theme';

.tableArea {
    width: calc(100% - 350px);
    min-height: calc(100vh - 150px);
}

.expandedTableArea {
    composes: tableArea;
    width: 100%;
}

.noDataMessage {
    background-color: #f9fafc;
    text-align: left;
    width: 100%;
    text-align: center;
    padding-top: 30px;
    color: $grey;
    font-weight: 400;
    font-size: 20px;
    position: relative;

    span {
        position: relative;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }
}

.importExportButtonHolder {
    left: 150px;
}