@import '../../colors';
@import '../../theme';

.FocusSpace {
    @extend %CommonFocusSpace;
}

.ConstrainedFocusSpace {
    @extend %CommonConstrainedFocusSpace;
}

.innerFocus {
    padding: 20px;
    @extend %CommonInnerFocusSpace;
}

.languagesHolder {
    display: flex;
    height: 100%;
}

.allLanguages {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: white;
    display: block;
    min-width: 330px;
    height: 100%;
    padding: 0px 0px 10px;
    border-radius: 30px;

    @extend %CommonCard;
    
    section[class*="cardsList"] {
        box-shadow: none;
        border: none;
        margin: 0;
    }
}

.newVerticalHolder {
    min-width: 290px;
}

.translationsArea {
    position: relative;
    flex-grow: 1;
    overflow: auto;
}